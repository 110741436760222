function getFirstCharacter(name: string) {
  return name && name.charAt(0).toUpperCase();
}

// eslint-disable-next-line no-unused-vars
function getAvatarColor(name: string) {
  return 'primary';
}

export default function createAvatar(name: string) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  } as const;
}
