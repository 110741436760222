import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Box,
  Container,
  Card,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Chip,
  CircularProgress,
  Tabs,
  Tab,
  Divider,
  Typography,
} from '@mui/material';
import {
  HeaderBreadcrumbs,
  Scrollbar,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from 'src/components';
import Label, { LabelColor } from 'src/components/Label';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSettings } from 'src/hooks';
import useTabs from 'src/hooks/useTabs';
import useTable, { getComparator, emptyRows } from 'src/hooks/useTable';
import { useDispatch, useSelector } from 'src/redux/hooks';
import { uploadDealStip } from 'src/redux/slices/isoPortal';
import { getDealRequiredStipsSelector } from 'src/redux/selectors/isoPortal';
import { DealRequiredStip } from 'src/@types/mcafunds/deal-application';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'uploadName', label: 'File Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: '', align: 'right' },
];

const StipulationsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const dealRequiredStips = useSelector(getDealRequiredStipsSelector);
  const isLoading = useSelector((state) => state.isoPortal.isLoading);
  const inputFile = useRef<HTMLInputElement>(null);
  const [stipToUpload, setStipToUpload] = useState<DealRequiredStip>();

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('MISSING');

  const { dense, page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } =
    useTable({ defaultDense: true, defaultRowsPerPage: 10 });

  const { dealId, epk, et } = useMemo(
    () => ({
      dealId: localStorage.getItem('dealId') ?? '',
      epk: localStorage.getItem('epk') ?? '',
      et: localStorage.getItem('et') ?? '',
    }),
    []
  );

  const dataFiltered = useMemo(
    () =>
      applySortFilter({
        tableData: dealRequiredStips,
        comparator: getComparator(order, orderBy),
        filterStatus,
      }),
    [dealRequiredStips, order, orderBy, filterStatus]
  );

  const isNotFound = useMemo(() => !dataFiltered.length, [dataFiltered.length]);

  const getStatusColor = useCallback((status: string) => {
    if (status === 'MISSING') return 'warning' as LabelColor;
    if (status === 'REJECTED') return 'error' as LabelColor;
    if (status === 'VALID') return 'success' as LabelColor;
    if (status === 'TBD') return 'info' as LabelColor;

    return;
  }, []);

  const getStatusLabel = useCallback((status: string) => {
    if (status === 'VALID') return 'APPROVED';
    if (status === 'TBD') return 'SUBMITTED';

    return status;
  }, []);

  const onUploadClick = useCallback(
    (stip: DealRequiredStip) => {
      if (isLoading) return;

      setStipToUpload(stip);
      inputFile?.current?.click();
    },
    [inputFile, isLoading]
  );

  const onUploadFile = useCallback(
    (event) => {
      if (!stipToUpload) return;

      const [file] = event.target.files;

      dispatch(
        uploadDealStip({
          file,
          epk,
          et,
          dealId,
          stipulationXDealApplicationId: stipToUpload.id,
          stipulationId: stipToUpload.stipulationId,
        })
      );
    },
    [stipToUpload, dealId, epk, et, dispatch]
  );

  const TABS = useMemo(() => {
    const getLengthByStatus = (status: string) =>
      dealRequiredStips.filter((stipulation: DealRequiredStip) => stipulation.status === status)
        .length;

    return [
      { value: 'all', label: 'All', color: 'info' as LabelColor, count: dealRequiredStips.length },
      {
        value: 'MISSING',
        label: getStatusLabel('MISSING'),
        color: getStatusColor('MISSING'),
        count: getLengthByStatus('MISSING'),
      },
      {
        value: 'TBD',
        label: getStatusLabel('TBD'),
        color: getStatusColor('TBD'),
        count: getLengthByStatus('TBD'),
      },
      {
        value: 'VALID',
        label: getStatusLabel('VALID'),
        color: getStatusColor('VALID'),
        count: getLengthByStatus('VALID'),
      },
      {
        value: 'REJECTED',
        label: getStatusLabel('REJECTED'),
        color: getStatusColor('REJECTED'),
        count: getLengthByStatus('REJECTED'),
      },
    ];
  }, [dealRequiredStips, getStatusColor, getStatusLabel]);

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs
        heading="Stipulations"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.partner.status },
          { name: 'Stipulations' },
        ]}
      />

      <Card>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onUploadFile}
        />
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onFilterStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              icon={<Label color={tab.color}> {tab.count} </Label>}
              label={tab.label}
            />
          ))}
        </Tabs>
        <Divider />
        <Scrollbar>
          <TableContainer
            sx={{ minWidth: 800, position: 'relative', paddingTop: theme.spacing(2) }}
          >
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={dealRequiredStips.length}
                onSort={onSort}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id} hover>
                      <TableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                          {row.name}
                        </Typography>
                        <Typography variant="caption" noWrap fontSize={'xx-small'}>
                          Id: {row.id}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{row.uploadName}</TableCell>
                      <TableCell align="center">
                        {row.status && (
                          <Chip
                            label={getStatusLabel(row.status)}
                            size="small"
                            color={getStatusColor(row.status)}
                            sx={{ borderRadius: '6px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {!row.uploadName?.length && (
                          <Button
                            disabled={isLoading}
                            onClick={() => onUploadClick(row)}
                            variant="contained"
                            size="small"
                            startIcon={<UploadIcon />}
                          >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Upload'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                <TableEmptyRows
                  height={dense ? 52 : 72}
                  emptyRows={emptyRows(page, rowsPerPage, dealRequiredStips.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {dataFiltered.length > 4 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        )}
      </Card>
    </Container>
  );
};

export default StipulationsPage;

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterStatus,
}: {
  tableData: DealRequiredStip[];
  // eslint-disable-next-line no-unused-vars
  comparator: (a: any, b: any) => number;
  filterStatus: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item: Record<string, any>) => item.status === filterStatus);
  }

  return tableData;
}
