import { ClientData } from 'src/@types/client';
import { Application } from 'src/@types/application-types';
import axios from '../merchantPortalService';

export const fetchClients = async () => {
  try {
    const response = await axios.get(`/api/v1/clients`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

export const fetchAllUsers = async () => {
  try {
    const response = await axios.get(`/api/v1/users`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const fetchAllApplications = async () => {
  try {
    const { data } = await axios.get(`/api/v1/support/applications`);

    return data;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const putApplication = async (application: Application) => {
  try {
    const response = await axios.put(
      `/api/v1/support/applications/${application.appId}`,
      application
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const putClient = async (clientData: ClientData) => {
  try {
    const response = await axios.put(`/api/v1/support/client/${clientData.clientId}`, clientData);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};
