import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'src/redux/hooks';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 0),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarClient({ isCollapse }: Props) {
  const { client } = useSelector((state) => state.admin);

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.account}>
      <RootStyle
        sx={{
          borderRadius: '8px',
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2">{client?.legalBusinessName}</Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
