import { Box, Link, Container, Typography } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import useAuth from 'src/hooks/useAuth';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import { RegisterISOForm } from 'src/portals/components/auth/register';
import {
  TERMS_OF_USE,
  CA_PRIVACY_NOTICE,
  PRIVACY_POLICY,
} from 'src/portals/components/compliance/config';
import { RootStyle, HeaderStyle, ContentStyle } from './Register';

export default function RegisterISO() {
  const { login } = useAuth();
  const smUp = useResponsive('up', 'sm');

  const onLogin = async () => {
    try {
      await login('', '', 'iso');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ mt: { md: -4 } }}>
            <Logo />
          </Box>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link variant="subtitle2" onClick={onLogin}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" align={'center'} gutterBottom>
                  Work with Libertas Funding
                </Typography>
              </Box>
            </Box>

            <RegisterISOForm role={'iso'} />

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              By registering, I agree to Libertas Funding&nbsp;
              <Link underline="always" href={TERMS_OF_USE} rel="noreferrer" target="_blank">
                Terms of Use
              </Link>
              {', '}
              <Link underline="always" href={PRIVACY_POLICY} rel="noreferrer" target="_blank">
                Privacy Policy
              </Link>
              , and{' '}
              <Link underline="always" href={CA_PRIVACY_NOTICE} rel="noreferrer" target="_blank">
                CA Privacy Notice
              </Link>
              .
            </Typography>

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" onClick={onLogin}>
                  Login
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
