import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import useAuth from 'src/hooks/useAuth';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import { RegisterForm } from 'src/portals/components/auth/register';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

export const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const { login } = useAuth();
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const showRegistration = featureFlags.isFeatureEnabled(featureFlags.featureFlagKeys.registration);

  const onLogin = async () => {
    try {
      await login('', '', 'customer');
    } catch (error) {
      console.error(error);
    }
  };

  if (!showRegistration) {
    navigate('/');
  }

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ mt: { md: -4 } }}>
            <Logo />
          </Box>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link variant="subtitle2" onClick={onLogin}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" align={'center'} gutterBottom>
                  Register to Apply for Revenue Based Funding
                </Typography>
              </Box>
              <Typography variant="body1" align={'center'} gutterBottom>
                Where relationships are an asset
              </Typography>
            </Box>

            <RegisterForm role={'customer'} showLegalAgreements={true} />

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" onClick={onLogin}>
                  Login
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
