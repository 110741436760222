import * as React from 'react';
import { useSelector } from 'src/redux/hooks';
import { Application } from 'src/@types/application-types';
import {
  GetDefaultNewApplication,
  isOpenApplication,
  isUnsubmittedApplication,
} from '../../../utils/applicationUtils';

export const useOpenApplications = () => {
  const { applications } = useSelector((state) => state.applications);
  const openApplications = applications.filter((application: Application) =>
    isOpenApplication(application)
  );

  return openApplications;
};

export const useUnsubmittedApplications = () => {
  const { applications } = useSelector((state) => state.applications);
  const openApplications = applications.filter((application: Application) =>
    isUnsubmittedApplication(application)
  );

  return openApplications;
};

export const useLastApplication = () => {
  const { applications } = useSelector((state) => state.applications);
  const { editApplication } = useSelector((state) => state.editApplication);

  const application = React.useMemo(() => {
    if (editApplication) return editApplication;
    if (!applications || applications.length === 0) return GetDefaultNewApplication();

    return applications[applications.length - 1];
  }, [applications, editApplication]);

  return application;
};
