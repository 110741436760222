import { createContext, ReactNode, useEffect, useReducer, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { batch } from 'react-redux';
import { useDispatch as useStoreDispatch } from 'src/redux/hooks';
import {
  ActionMap,
  AuthState,
  AuthUser,
  Auth0ContextType,
  UserRoleTypes,
  AuthOptions,
} from 'src/@types/auth';
import { registerClient } from 'src/services/APIs/adminAPI';
import { AUTH0_API } from 'src/config';
import { setSession } from 'src/utils/jwt';
import { getClientData } from 'src/redux/slices/admin';
import { getClientByUserEmail } from 'src/redux/slices/mainApp';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

// eslint-disable-next-line no-unused-vars
enum Types {
  // eslint-disable-next-line no-unused-vars
  init = 'INITIALIZE',
  // eslint-disable-next-line no-unused-vars
  login = 'LOGIN',
  // eslint-disable-next-line no-unused-vars
  logout = 'LOGOUT',
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    isLoading,
    user,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const storeDispatch = useStoreDispatch();

  const doLogOut = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
    localStorage.removeItem('loginContext');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('clientId');
    localStorage.removeItem('dealId');
    localStorage.removeItem('epk');
    localStorage.removeItem('et');
    dispatch({ type: Types.logout });
    setSession(null);
  }, [logout, dispatch]);

  useEffect(() => {
    const getUserMetadata = async () => {
      const userIdInfo = await getIdTokenClaims();
      // Note: We have rules in Auth0 to enforce
      // - emails are verified: https://manage.auth0.com/dashboard/us/<ENV>/rules

      if (!userIdInfo?.libertasClientId) {
        console.log('No Libertas Client ID Claim. Redirecting to logout...');
        throw new Error('No Libertas Client Id');
      }
      localStorage.setItem('clientId', userIdInfo?.libertasClientId);

      // Note: We will default the userRole to customer initially
      let userRoles: string[] =
        process.env.NODE_ENV === 'production' ? ['customer'] : ['customer', 'iso', 'support'];
      if (userIdInfo?.libertasUserRoles) {
        if (userIdInfo.libertasUserRoles.length > 0) {
          userRoles = Array.from(userIdInfo?.libertasUserRoles);
        }
      }
      localStorage.setItem('userRoles', JSON.stringify(userRoles));

      let loginContext = localStorage.getItem('loginContext') || 'customer';
      if (!userRoles.includes(loginContext)) {
        console.warn(`User roles does not include login context. Forcing logout.`);
        throw new Error('User roles does not include login context. Forcing logout.');
      }

      try {
        // Auth0 will not treat localhost as "First Party Application". As such,
        // consent will always fail. It will work for UAT and Production.
        // TODO: Move to Secrets. Improve this.
        if (process.env.NODE_ENV === 'production') {
          const accessToken = await getAccessTokenSilently({
            audience: `https://${AUTH0_API.domain}/api/v2/`,
            scope: 'read:current_user',
          });
          setSession(accessToken);
        } else {
          setSession(AUTH0_API.mgmtClientId as string);
        }

        if (!user?.email) {
          console.warn(`User not found. Forcing logout.`);
          throw new Error('User not found. Forcing logout.');
        }

        batch(() => {
          if (user?.email) {
            storeDispatch(getClientByUserEmail(user?.email));
          }
          storeDispatch(getClientData(userIdInfo?.libertasClientId));
        });
        return {
          id: user?.id,
          photoURL: user?.picture,
          email: user?.email,
          displayName: `${user?.given_name} ${user?.family_name}`,
          role: 'admin',
          roles: userRoles,
        };
      } catch (err) {
        console.log(err.message);
        throw new Error('Unable to get access token');
      }
    };

    if (isLoading) return;

    if (isAuthenticated) {
      // eslint-disable-next-line no-unused-vars
      getUserMetadata()
        .then((user) => {
          dispatch({
            type: Types.init,
            payload: {
              isAuthenticated,
              user,
            },
          });
        })
        .catch((err) => {
          console.error('Error Getting User MetaData', err);
          doLogOut();
        });
    }
  }, [
    isAuthenticated,
    isLoading,
    user,
    doLogOut,
    storeDispatch,
    getAccessTokenSilently,
    error,
    getIdTokenClaims,
  ]);

  const authValues = useMemo(() => {
    const doLogin = async (email: string, password: string, role: UserRoleTypes) => {
      localStorage.setItem('loginContext', role);
      loginWithRedirect().then(() => {
        console.log(`Logging in: ${role}`);
      });
    };

    const register = async (
      legalBusinessName: string,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      entityId: string,
      role: UserRoleTypes,
      options: AuthOptions
    ) => {
      await registerClient(
        legalBusinessName,
        email,
        password,
        firstName,
        lastName,
        entityId,
        role,
        options
      );
    };

    return {
      ...state,
      method: 'auth0',
      user: state.user,
      isAuthenticated,
      isInitialized: !isLoading && state.isInitialized,
      login: doLogin,
      logout: doLogOut,
      register,
    };
  }, [state, isAuthenticated, isLoading, loginWithRedirect, doLogOut]);

  return <AuthContext.Provider value={authValues as any}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
