import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FundedDeal } from 'src/@types/funded-deals';
import * as fundedDealsAPI from 'src/services/APIs/fundedDealsAPI';

// ----------------------------------------------------------------------

export type FundedDealsState = {
  isLoading: boolean;
  error: Error | string | null;
  fundedDeals: FundedDeal[];
};

export const initialState: FundedDealsState = {
  isLoading: false,
  error: null,
  fundedDeals: [] as FundedDeal[],
};

const slice = createSlice({
  name: 'fundedDeals',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Data
    getFundedDealsSuccess(state, action) {
      state.isLoading = false;
      state.fundedDeals = action.payload;
    },

    updateFundedDealsData(state, action) {
      state.fundedDeals = action.payload;
    },

    resetFundedDealsData(state) {
      state.fundedDeals = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetFundedDealsData, updateFundedDealsData } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const getFundedDeals = createAsyncThunk('getFundedDeals', async (_, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  try {
    const fundedDeals: FundedDeal[] = await fundedDealsAPI.fetchDeals();
    if (fundedDeals) {
      dispatch(slice.actions.getFundedDealsSuccess(fundedDeals));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
});

export const requestPayoffLetter = createAsyncThunk(
  'requestPayoffLetter',
  async (_, { dispatch, getState }) => {
    const {
      // @ts-ignore
      mainApp: { userData },
    } = getState();

    dispatch(slice.actions.startLoading());
    try {
      const clientId = localStorage.getItem('clientId') ?? '';
      await fundedDealsAPI.requestPayoffLetter(clientId, userData?.emailAddress ?? '');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const requestZeroBalanceLetter = createAsyncThunk(
  'requestZeroBalanceLetter',
  async (_, { dispatch, getState }) => {
    const {
      // @ts-ignore
      mainApp: { userData },
    } = getState();

    dispatch(slice.actions.startLoading());

    try {
      const clientId = localStorage.getItem('clientId') ?? '';
      await fundedDealsAPI.requestZeroBalanceLetter(clientId, userData?.emailAddress ?? '');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const requestRenewal = createAsyncThunk(
  'requestRenewal',
  async (_, { dispatch, getState }) => {
    const {
      // @ts-ignore
      mainApp: { userData },
    } = getState();

    dispatch(slice.actions.startLoading());

    try {
      const clientId = localStorage.getItem('clientId') ?? '';
      await fundedDealsAPI.requestRenewal(clientId, userData?.emailAddress ?? '');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

// Load from MCA Funds
export const getFundedDealByAppId = createAsyncThunk(
  'getFundedDealByAppId/getFundedDealByAppId',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    dispatch(slice.actions.startLoading());
    try {
      await fundedDealsAPI.getFundedDealFromMCAFunds(payload);
      const fundedDeals: FundedDeal[] = await fundedDealsAPI.fetchDeals();
      if (fundedDeals) {
        dispatch(slice.actions.getFundedDealsSuccess(fundedDeals));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

// Get from MCA Funds
export const getFundedDealsByCompanyId = createAsyncThunk(
  'getFundedDealsByCompanyId/getFundedDealsByCompanyId',
  // eslint-disable-next-line no-unused-vars
  async (_, { dispatch, getState }) => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        // @ts-ignore
        admin: { client },
      } = getState();
      if (!client) return;

      const fundedDeals: FundedDeal[] = await fundedDealsAPI.getFundedDealsByCompanyId(
        client.companyId
      );
      if (fundedDeals) {
        dispatch(slice.actions.getFundedDealsSuccess(fundedDeals));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
);
