import axios from '../merchantPortalService';

const getSignedLinkQueryParams = (et: string, epk: string) => (epk ? `?epk=${epk}&et=${et}` : '');

// TODO: remove mcafunds from the route name and move to a core MP route
export const getIsDealSignedLinkValid = async (dealId: string, epk: string, et: string) => {
  try {
    const response = await axios.get(`/api/v1/deal/${dealId}/isLinkValid?epk=${epk}&et=${et}`);
    const { status } = response;
    if (status === 200) {
      return true;
    }
    if (status === 401) {
      return false;
    }
  } catch (error: any) {
    console.error(error);
  }
  return false;
};

// TODO: remove mcafunds from the route name and move to a core MP route
export const getDealSignedLinkParameters = async (dealId: string) => {
  try {
    const response = await axios.get(`/api/v1/deal/${dealId}/links`);
    const { data, status } = response;
    if (status === 200 && data) {
      return data;
    }
  } catch (error: any) {
    console.error(error);
  }
  return null;
};

// TODO: Process the dealCalc data and convert to an MP type
export const getDealCalcByAppId = async (appId: string) => {
  try {
    const response = await axios.get(`/api/v1/deal-calc/${appId}`);
    const { data, status } = response;
    if (status === 200 && data) {
      return data;
    }
  } catch (error: any) {
    console.error(error);
  }
  return null;
};

// TODO: Process the dealCalc data and convert to an MP type
// eslint-disable-next-line no-unused-vars
export const getDealCalcByDealId = async (dealId: string, epk: string = '', et: string = '') => {
  try {
    const response = await axios.get(
      `/api/v1/deals/${dealId}/calculator${epk ? `?epk=${epk}` : ''}${et ? `&et=${et}` : ''}`
    );
    const { data, status } = response;
    if (status === 200 && data) {
      return { data, status };
    }
  } catch (error: any) {
    console.error(error);
  }
  return { data: null, status: 500 };
};

// TODO: Process the dealCalc data and convert to an MP type
// eslint-disable-next-line no-unused-vars
export const getDealApplicationByDealId = async (
  dealId: string,
  epk: string = '',
  et: string = ''
) => {
  try {
    const response = await axios.get(
      `/api/v1/deals/${dealId}/application${epk ? `?epk=${epk}` : ''}${et ? `&et=${et}` : ''}`
    );

    const { data, status } = response;
    if ((status === 200 || status === 204) && data) {
      return data;
    }
  } catch (error: any) {
    console.error(error);
  }
  return null;
};

export const acceptOffer = async ({ epk, et, ...data }: any, calculateApr: boolean) => {
  try {
    await axios.post(
      `/api/v1/deal/${data.deal_application_thread_id}/offer/accept?calculateApr=${calculateApr}${
        epk && et ? `&epk=${epk}&et=${et}` : ''
      }`,
      data
    );
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const postUnlockDealRequest = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.post(
      `/api/v1/deal/${dealId}/offer/unlock${getSignedLinkQueryParams(et, epk)}`
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
export const postUserAgreementAction = async (
  dealId: string,
  data: any,
  epk: string = '',
  et: string = ''
) => {
  try {
    await axios.post(
      `/api/v1/compliance/deal/${dealId}/log_agreement_action?epk=${epk}&et=${et}`,
      data
    );
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
export const getDealDocument = async (
  dealId: string,
  documentType: string,
  process: string,
  epk: string = '',
  et: string = ''
) => {
  try {
    const response = await axios.get(
      `/api/v1/deal/document/${dealId}/${documentType}?process=${process}${
        epk ? `&epk=${epk}&et=${et}` : ''
      }`
    );
    return response.data;
  } catch (e: any) {
    console.log(e);
    return null;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
// - rename dealId to be systemId
export const getDealContract = async (dealId: string, epk: string = '', et: string = '') => {
  try {
    const response = await axios.get(
      `/api/v1/deal/contract/${dealId}${epk ? `?epk=${epk}&et=${et}` : ''}`
    );
    return response.data;
  } catch (e: any) {
    console.log(e);
    return null;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
// - rename dealId to be systemId
interface GetDealByDealIdProps {
  dealId: string;
  epk: string;
  et: string;
}
export const getDealByDealId = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const response = await axios.get(`/api/v1/deals/${dealId}${epk ? `?epk=${epk}&et=${et}` : ''}`);
    return response.data;
  } catch (error: any) {
    console.error(`Failed to getDealByDealId ${dealId}`, error?.message);
    throw error;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
// - rename dealId to be systemId
export const getDealRequiredStips = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.get(
      `/api/v1/deal/stips/${dealId}${epk ? `?epk=${epk}&et=${et}` : ''}`
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
// - rename dealId to be systemId
interface UploadStipDealProps {
  dealId: string;
  stipulationXDealApplicationId: string;
  stipulationId: string;
  epk: string;
  et: string;
  file: any;
}
export const uploadDealStip = async (props: UploadStipDealProps) => {
  const {
    epk = '',
    et = '',
    dealId: deal_id,
    stipulationXDealApplicationId: s_x_d_id,
    stipulationId: stip_id,
    file,
  } = props;
  try {
    const { data } = await axios.post(
      `/api/v1/deal/stips/${deal_id}${epk ? `?epk=${epk}&et=${et}` : ''}`,
      {
        deal_id,
        stip_id,
        s_x_d_id,
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - update the route to be an MP-route
// - enable env to be MCAFunds vs. FMS aware
// - rename dealId to be systemId
interface RequestContractProps {
  dealId: string;
  note?: string;
  epk: string;
  et: string;
}
export const postRequestContract = async (props: RequestContractProps) => {
  const { dealId = '', epk = '', et = '', ...payload } = props;
  try {
    const { data } = await axios.post(
      `/api/v1/processes/contract/request/deal/${dealId}${getSignedLinkQueryParams(et, epk)}`,
      payload
    );

    return data;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

type SendContractProps = GetDealByDealIdProps & {
  owners: string;
  cc: string;
  companyName: string;
  entities: string;
  pdfContract: string;
};

// TODO:
// - rename dealId to be systemId
export const postSendContract = async (props: SendContractProps) => {
  const { dealId = '', epk = '', et = '', ...payload } = props;
  try {
    const { data } = await axios.post(
      `/ds/sendContract/${dealId}${getSignedLinkQueryParams(et, epk)}`,
      payload
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - rename dealId to be systemId
export const postResendContract = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.post(
      `/ds/resendContract/${dealId}${getSignedLinkQueryParams(et, epk)}`
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - rename dealId to be systemId
export const getContractSigners = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const signedLink = getSignedLinkQueryParams(et, epk);

    if (signedLink.length > 0) {
      delete axios.defaults.headers.common.Authorization;
    }

    const { data } = await axios.get(`/ds/contractSigners/${dealId}${signedLink}`);

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - rename dealId to be systemId
export const getVerifyContractSigning = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.get(
      `/api/v1/processes/contract/signing/deal/${dealId}${getSignedLinkQueryParams(et, epk)}`
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - rename dealId to be systemId
export const postRunFunding = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.post(
      `/api/v1/processes/fund/deal/${dealId}${getSignedLinkQueryParams(et, epk)}`
    );
    return data;
  } catch (e: any) {
    console.error(e);
    return {
      result: false,
      messages: ['Error while requesting funding. Please try again!'],
    };
  }
};

type PreContractDataProps = GetDealByDealIdProps & {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankRountingNumber: string;
};
// TODO:
// - rename dealId to be systemId
export const postGeneratePreContractData = async (props: PreContractDataProps) => {
  const { dealId, epk, et, ...payload } = props;

  try {
    const { data } = await axios.post(
      `/api/v1/deal/contract/${dealId}/generate_pre_contract_data${getSignedLinkQueryParams(
        et,
        epk
      )}`,
      payload
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// TODO:
// - rename dealId to be systemId
export const postSendCodatLinkRequest = async (props: GetDealByDealIdProps) => {
  const { dealId = '', epk = '', et = '' } = props;
  try {
    const { data } = await axios.post(
      `/api/v1/deal/${dealId}/sendLinkRequest${getSignedLinkQueryParams(et, epk)}`
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};
