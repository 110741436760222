import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useOffSetTop from 'src/hooks/useOffSetTop';
import cssStyles from 'src/utils/cssStyles';
import { HEADER } from 'src/config';
import Logo from 'src/components/Logo';
import useResponsive from 'src/hooks/useResponsive';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import { ToolbarStyle, HeaderRootStyle } from 'src/layouts/components/Layout';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function ISOHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        {isDesktop && verticalLayout && <Logo isWide={true} sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
            aria-label="Menu"
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {/*<Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>*/}
        {/* Put a right floating control here*/}
        {/*</Stack>*/}
      </ToolbarStyle>
    </HeaderRootStyle>
  );
}
