import ListIcon from '@mui/icons-material/FactCheck';
import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { FeatureFlags } from 'src/@types/feature-flags';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { useSelector } from 'src/redux/hooks';
import {
  getDealRequiredStipsSelector,
  getDocumentsCountSelector,
} from 'src/redux/selectors/isoPortal';
import { StipulationsLabel } from 'src/portals/iso-portal/components/StipulationsLabel';
import Label from 'src/components/Label';

type NavConfigProps = {
  featureFlags: FeatureFlags;
  documents: number;
  stipulations: { count: number; uploadedCount: number };
};

const getNavConfig = ({ documents, stipulations }: NavConfigProps) => {
  const routes = [
    {
      subheader: 'Manage Deal Application',
      isEnabled: true,
      items: [
        {
          title: 'Deal Dashboard',
          path: PATH_DASHBOARD.partner.status,
          icon: <DashboardIcon />,
          isEnabled: true,
        },
        // {
        //   title: 'Application',
        //   path: PATH_DASHBOARD.partner.application,
        //   icon: <PaidIcon />,
        //   isEnabled: true,
        // },
        {
          title: 'Stipulations',
          path: PATH_DASHBOARD.partner.stipulations,
          icon: <ListIcon />,
          isEnabled: true,
          info: (
            <StipulationsLabel
              uploadedCount={stipulations.uploadedCount}
              count={stipulations.count}
            />
          ),
        },
        {
          title: 'Documents',
          path: PATH_DASHBOARD.partner.documents,
          icon: <ArticleIcon />,
          isEnabled: true,
          info: <Label color={documents > 0 ? 'success' : 'error'}>{documents}</Label>,
        },
      ],
    },
  ];

  return routes
    .filter((section) => section.isEnabled)
    .map((section) => ({
      ...section,
      items: section.items.filter((node: any) => node.isEnabled),
    }));
};

const useNavConfig = () => {
  const featureFlags = useFeatureFlags();
  const documents = useSelector(getDocumentsCountSelector);
  const dealRequiredStips = useSelector(getDealRequiredStipsSelector);

  return getNavConfig({
    featureFlags,
    documents,
    stipulations: {
      count: dealRequiredStips?.length,
      uploadedCount: dealRequiredStips?.filter((stip) => stip.uploadName?.length > 0)?.length,
    },
  });
};

export default useNavConfig;
