import { RemittanceScheduleTypes } from 'src/@types/remittance-schedule-types';

export const getEstimatedRemittances = (remittanceSchedule: string, term: number): number => {
  const numberOfDailyPayments = term * 21;

  switch (remittanceSchedule) {
    case RemittanceScheduleTypes.Daily:
      return numberOfDailyPayments;
    case RemittanceScheduleTypes.Weekly:
      return Math.ceil(numberOfDailyPayments / 5);
    case RemittanceScheduleTypes.BiWeekly:
      return Math.ceil(numberOfDailyPayments / 10);
    case RemittanceScheduleTypes.Monthly:
      return term;
    default:
      return 0;
  }
};

export const getRemittancesPayment = (
  remittanceSchedule: string,
  totalRemittanceAmount: number,
  term: number
): number => {
  const dailyPayment = Math.ceil((totalRemittanceAmount / term / 21) * 100) / 100;

  switch (remittanceSchedule) {
    case RemittanceScheduleTypes.Daily:
      return dailyPayment;
    case RemittanceScheduleTypes.Weekly:
      return dailyPayment * 5;
    case RemittanceScheduleTypes.BiWeekly:
      return dailyPayment * 10;
    case RemittanceScheduleTypes.Monthly:
      return dailyPayment * 21;
    default:
      return 0;
  }
};

export const generateRemittanceScheduleLabel = (remittanceSchedule: string): string => {
  switch (remittanceSchedule) {
    case RemittanceScheduleTypes.Daily:
      return 'Daily Remittance';
    case RemittanceScheduleTypes.Weekly:
      return 'Weekly Remittance';
    case RemittanceScheduleTypes.BiWeekly:
      return 'Bi-Weekly Remittance';
    case RemittanceScheduleTypes.Monthly:
      return 'Monthly Remittance';
    default:
      return '';
  }
};
