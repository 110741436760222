import React from 'react';
import { Input, InputAdornment, Slider, Typography, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fCurrency } from 'src/utils/formatNumber';
import { useDispatch } from 'src/redux/hooks';
import { OfferProperties } from 'src/@types/offer-properties';
import { editOfferProperties } from 'src/redux/slices/isoPortal';

interface RequestedAmountInputProps {
  offerProperties: OfferProperties;
}

export const RequestedAmountInput = (props: RequestedAmountInputProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      editOfferProperties({
        requestedAmount:
          event.target.value === ''
            ? 0
            : Math.min(Number(event.target.value), props.offerProperties.maximumFunding),
      })
    );
  };

  const handleBlur = () => {
    let { requestedAmount } = props.offerProperties;
    if (requestedAmount < 25_000) {
      props.offerProperties.requestedAmount = 25_000;
    } else if (requestedAmount > props.offerProperties.maximumFunding) {
      props.offerProperties.requestedAmount = props.offerProperties.maximumFunding;
    }
    dispatch(
      editOfferProperties({
        requestedAmount,
      })
    );
  };

  const onAmountSeekingChange = (event: React.SyntheticEvent | Event, value: number | number[]) => {
    dispatch(
      editOfferProperties({
        ...props.offerProperties,
        requestedAmount: value as number,
      })
    );
  };

  return (
    <div>
      {/*<Typography variant="caption" component={'div'} id={'funding-amount-input'}>*/}
      {/*  Funding Amount*/}
      {/*</Typography>*/}
      <InputLabel htmlFor="funding-amount-text-input">Funding Amount</InputLabel>
      <Input
        value={props.offerProperties.requestedAmount}
        id={'funding-amount-text-input'}
        size="small"
        onChange={handleInputChange}
        onBlur={handleBlur}
        onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
        sx={{ width: '125px', pr: '4px', pt: '4px' }}
        inputProps={{
          type: 'number',
          'aria-labelledby': 'funding-amount-text-input',
          style: {
            textAlign: 'left',
            backgroundColor: theme.palette.background.default,
            borderRadius: '4px',
            fontWeight: 'bold',
          },
        }}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        disabled={props.offerProperties.hasLockedOffer}
      />
      <Slider
        aria-label="principal-amount-slider"
        min={25_000}
        max={props.offerProperties.maximumFunding}
        value={props.offerProperties.requestedAmount}
        onChangeCommitted={onAmountSeekingChange}
        sx={{ pb: 0, mb: 0 }}
        disabled={props.offerProperties.hasLockedOffer}
      />
      <div style={{ display: 'flex', paddingTop: 0, marginTop: 0 }}>
        <Typography variant="caption" display="inline" align={'left'}>
          {fCurrency(25_000)}
        </Typography>
        <div style={{ display: 'flex', flexGrow: 1 }} />
        <Typography variant="caption" display="inline" align={'right'}>
          {fCurrency(props.offerProperties.maximumFunding)}
        </Typography>
      </div>
    </div>
  );
};
