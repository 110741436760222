import { Checkbox, TableRow, TableCell } from '@mui/material';
import { Prepay } from 'src/@types/prepays';
import { fCurrencyShort } from 'src/utils/formatNumber';

type Props = {
  row: Prepay;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function PrepaysTableRow({ row, selected, onSelectRow }: Props) {
  const { term, buyRate, commission, points, sellRate, totalRepayment } = row;

  return (
    <TableRow hover selected={selected}>
      {false && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}
      <TableCell align="left">{term}</TableCell>
      <TableCell align="left">{buyRate}</TableCell>
      <TableCell align="left">{fCurrencyShort(commission)}</TableCell>
      <TableCell align="left">{points}</TableCell>
      <TableCell align="left">{sellRate}</TableCell>
      <TableCell align="left">{fCurrencyShort(totalRepayment)}</TableCell>
    </TableRow>
  );
}
