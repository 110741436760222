import createAvatar from 'src/utils/createAvatar';
import Avatar, { Props as AvatarProps } from './Avatar';
import useCurrentUser from 'src/hooks/useCurrentUser';

export default function MyAvatar({ ...other }: AvatarProps) {
  const user = useCurrentUser();

  return (
    <Avatar
      // src={user?.photoURL}
      alt={user?.firstName}
      color={'primary'}
      {...other}
    >
      {createAvatar(user?.firstName).name}
    </Avatar>
  );
}
