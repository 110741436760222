import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  script?: string;
  script2?: string;
  noscript?: string;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', script, script2, noscript, meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{title}</title>
        {script && <script>{script}</script>}
        {script2 && <script>{script2}</script>}
        {noscript && <noscript>{noscript}</noscript>}
        {meta}
        <link rel="canonical" href="https://www.tacobell.com/" />
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
);

export default Page;
