import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: VoidFunction;
  onReject: VoidFunction;
};

export default function ConfirmActionDialog({
  isOpen = false,
  title,
  message,
  onConfirm,
  onReject,
}: Props) {
  const theme = useTheme();

  const handleYes = () => {
    onConfirm();
  };

  const handleNo = () => {
    onReject();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ pt: theme.spacing(2) }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} variant={'contained'}>
            Yes
          </Button>
          <Button onClick={handleNo} variant={'outlined'}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
