import PaidIcon from '@mui/icons-material/Paid';
import ListIcon from '@mui/icons-material/FactCheck';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ClientIcon from '@mui/icons-material/Business';
import UsersIcon from '@mui/icons-material/People';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Label from 'src/components/Label';
import { useSelector } from 'src/redux/hooks';
import { FeatureFlags } from 'src/@types/feature-flags';
import { FundedDeal } from 'src/@types/funded-deals';
import { Application } from 'src/@types/application-types';
import { ClientData } from 'src/@types/client';
import { UserData } from 'src/@types/user';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

interface NavConfigProps {
  fundedDeals: FundedDeal[];
  applications: Application[];
  supportClients?: ClientData[];
  supportUsers?: UserData[];
  supportApplications?: Application[];
  supportFundedClients?: ClientData[];
  roles?: string[];
  featureFlags: FeatureFlags;
}

const getNavConfig = (props: NavConfigProps) => {
  const { featureFlags } = props;
  let routes: any[] = [];
  const isoRoutes = [
    {
      subheader: 'ISO Portal',
      isEnabled: true,
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.isoPortal.dashboard,
          icon: <DashboardIcon />,
          isEnabled: true,
        },
        {
          title: 'Applications',
          path: PATH_DASHBOARD.isoPortal.applications,
          info:
            props.applications.length > 0 ? (
              <Label color="success">{props.applications.length}</Label>
            ) : (
              <Label color="info">Apply Today!</Label>
            ),
          icon: <ListIcon />,
          isEnabled: true,
        },
        // {
        //   title: 'Funded Deals',
        //   path: PATH_DASHBOARD.merchantPortal.fundedDeals,
        //   info: <Label color="success">{props.deals.length}</Label>,
        //   icon: <PaidIcon />,
        // },
      ],
    },
  ];

  const customerRoutes = [
    {
      subheader: 'Customer Portal',
      isEnabled: true,
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.merchantPortal.dashboard,
          icon: <DashboardIcon />,
          isEnabled: featureFlags.isFeatureEnabled(featureFlags.featureFlagKeys.customerDashboard),
        },
        {
          title: 'Applications',
          path: PATH_DASHBOARD.merchantPortal.applications,
          info:
            props.applications.length > 0 ? (
              <Label color="success">{props.applications.length}</Label>
            ) : (
              <Label color="info">Apply Today!</Label>
            ),
          icon: <ListIcon />,
          isEnabled: featureFlags.isFeatureEnabled(
            featureFlags.featureFlagKeys.customerApplications
          ),
        },
        {
          title: 'Funded Deals',
          path: PATH_DASHBOARD.merchantPortal.fundedDeals,
          info: <Label color="success">{props.fundedDeals.length}</Label>,
          icon: <PaidIcon />,
          isEnabled: true,
        },
      ],
    },
  ];

  const supportRoutes = [
    {
      subheader: 'Libertas Support',
      isEnabled: true,
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.customerService.dashboard,
          icon: <DashboardIcon />,
          isEnabled: true,
        },
        {
          title: 'Applications',
          path: PATH_DASHBOARD.customerService.applications,
          info: props.supportApplications?.length ? (
            <Label color="success">{props.supportApplications?.length}</Label>
          ) : null,
          icon: <ListIcon />,
          isEnabled: true,
        },
        {
          title: 'Clients',
          path: PATH_DASHBOARD.customerService.clients,
          info: props.supportClients?.length ? (
            <Label color="success">{props.supportClients?.length}</Label>
          ) : null,
          icon: <ClientIcon />,
          isEnabled: true,
        },
        // Dormant: We may further build this out but not now
        // {
        //   title: 'Funded Clients',
        //   path: PATH_DASHBOARD.customerService.fundedClients,
        //   info: props.supportFundedClients?.length ? (
        //     <Label color="success">{props.supportFundedClients?.length}</Label>
        //   ) : null,
        //   icon: <ClientIcon />,
        //   isEnabled: true,
        // },
        {
          title: 'Users',
          path: PATH_DASHBOARD.customerService.users,
          info: props.supportUsers?.length ? (
            <Label color="success">{props.supportUsers?.length}</Label>
          ) : null,
          icon: <UsersIcon />,
          isEnabled: true,
        },
      ],
    },
  ];

  if (props.roles) {
    if (props.roles.join(',').includes('customer')) {
      routes = routes.concat(customerRoutes);
    }

    if (props.roles.join(',').includes('iso')) {
      routes = routes.concat(isoRoutes);
    }

    if (props.roles.join(',').includes('support')) {
      routes = routes.concat(supportRoutes);
    }
  } else {
    routes = routes.concat(customerRoutes);
  }

  let filterRoutes = routes.filter((section) => section.isEnabled);
  filterRoutes = filterRoutes.map((section) => ({
    ...section,
    items: section.items.filter((node: any) => node.isEnabled),
  }));

  return filterRoutes;
};

const useNavConfig = () => {
  const { fundedDeals } = useSelector((state) => state.fundedDeals);
  const { applications } = useSelector((state) => state.applications);
  const supportClients = useSelector((state) => state.support.clients);
  const supportUsers = useSelector((state) => state.support.users);
  const supportApplications = useSelector((state) => state.support.applications);
  const user = useCurrentUser();
  const featureFlags = useFeatureFlags();

  return getNavConfig({
    fundedDeals: fundedDeals,
    applications,
    roles: user.roles,
    featureFlags,
    supportClients,
    supportUsers,
    supportApplications,
  });
};

export default useNavConfig;
