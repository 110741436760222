import Label from 'src/components/Label';
import { Stack, Typography } from '@mui/material';

interface StipulationsLabelProps {
  count: number;
  uploadedCount: number;
  verbose?: boolean;
}
export const StipulationsLabel = ({
  count,
  uploadedCount,
  verbose = false,
}: StipulationsLabelProps) => {
  if (!count) return <Label color="warning">Unverified</Label>;

  const missingCount = count - uploadedCount;
  if (missingCount > 0) {
    return <Label color="error">{missingCount} Missing</Label>;
  }

  const message = !verbose ? `${uploadedCount}/${count}` : `${uploadedCount} of ${count} missing`;
  if (count === uploadedCount) {
    return <Label color="success">{message}</Label>;
  }
  return <Label color="warning">{message}</Label>;
};

export const StipulationsStatus = ({ count, uploadedCount }: StipulationsLabelProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="body1">Stipulations</Typography>
    <StipulationsLabel uploadedCount={uploadedCount} count={count} />
  </Stack>
);
