import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Application } from 'src/@types/application-types';
import * as applicationsAPI from 'src/services/APIs/applicationsAPI';
import { addApplication } from './applications';

export type EditApplicationState = {
  isLoading: boolean;
  error: Error | string | null;
  editApplication: Application | null;
};

export const initialState: EditApplicationState = {
  isLoading: false,
  error: null,
  editApplication: null,
};

const slice = createSlice({
  name: 'editApplication',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Data
    getEditApplicationData(state) {
      return state;
    },

    editApplicationData(state, action) {
      state.editApplication = {
        ...action.payload,
      };
      return state;
    },

    resetEditApplicationData(state) {
      state = initialState;
      return state;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetEditApplicationData, editApplicationData } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const createNewApplication = createAsyncThunk(
  'editApplication/createNewApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(editApplicationData(payload.application));
      dispatch(addApplication(payload.application));
    } catch (error) {}
  }
);

export const setEditApplication = createAsyncThunk(
  'editApplication/setEditApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(editApplicationData(payload));
    } catch (error) {}
  }
);

export const editApplication = createAsyncThunk(
  'editApplication/editApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const application = { ...payload.application };
      // Stipulations are updated individually due to their nature
      if (payload.application?.stips) {
        delete application?.stips;
      }

      dispatch(editApplicationData(application));
      await applicationsAPI.putApplicationApplicant(payload);
    } catch (error) {}
  }
);

export const editApplicationOwner = createAsyncThunk(
  'editApplication/editApplicationOwner',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(editApplicationData(payload.application));
      await applicationsAPI.postOwner(payload);
    } catch (error) {}
  }
);

export const editApplicationBankStatements = createAsyncThunk(
  'editApplication/editApplicationStips',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const response = await applicationsAPI.submitBankStatementStips({
        stips: payload.stips,
        appId: payload.application.appId,
      });
      const application = JSON.parse(JSON.stringify(payload.application));
      const stips = getStipsFromResult(response.data.stips);
      application.stips = stips;
      dispatch(editApplicationData(application));
    } catch (error) {
      console.log(error);
    }
  }
);

export const editApplicationStips = createAsyncThunk(
  'editApplication/editApplicationStips',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const response = await applicationsAPI.submitStips({
        stips: payload.stips,
        appId: payload.application.appId,
        eventName: payload.eventName,
      });
      const application = JSON.parse(JSON.stringify(payload.application));
      application.stips = {
        ...payload.application.stips,
        [payload.eventName]: {
          ...response.data.stips[0],
          name: response.data.stips[0].name,
          size: payload.stips[0].size,
        },
      };
      dispatch(editApplicationData(application));
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteApplicationStips = createAsyncThunk(
  'editApplication/deleteApplicationStips',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      await applicationsAPI.deleteStip({
        stipId: payload.stipId,
      });
      dispatch(editApplicationData(payload.application));
    } catch (error) {}
  }
);

export function getStipsFromResult(stips: any) {
  if (!stips || stips.length === 0) return;
  const finalStips: any = {
    bankStatements: [],
  };
  for (const stip of stips) {
    switch (stip.stipTypeId) {
      case 1:
        finalStips.bankStatements.push(stip);
        break;
      case 2:
        finalStips.voidedCheck = stip;
        break;
      case 3:
        finalStips.driverLicence = stip;
        break;
      default:
        return;
    }
  }
  return finalStips;
}

export const getApplicationStips = createAsyncThunk(
  'editApplication/getApplicationStips',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const result: any = await applicationsAPI.getStips({
        appId: payload.application.appId,
      });
      const stips = getStipsFromResult(result.data);
      payload.application = {
        ...payload.application,
        stips,
      };
      dispatch(editApplicationData(payload.application));
    } catch (error) {}
  }
);
