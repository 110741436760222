import cuid from 'cuid';
import { AuthUserData } from 'src/@types/user';
import { ClientData } from 'src/@types/client';
import { AuthOptions, UserRoleTypes } from 'src/@types/auth';
import axios from '../merchantPortalService';

export const registerClient = async (
  legalBusinessName: string,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  entityId: string,
  role: UserRoleTypes,
  options: AuthOptions
) => {
  const clientId = cuid();
  const response = await axios.post(`/api/client/register`, {
    email,
    password,
    firstName,
    lastName,
    userId: cuid(),
    legalBusinessName,
    clientId,
    clientType: role,
    companyId: role === 'customer' ? entityId : '',
    isoId: role === 'iso' ? entityId : '',
    ...options,
  });
  localStorage.setItem('clientId', clientId);
  return response.data;
};

export const fetchUsers = async () => {
  try {
    const clientId = localStorage.getItem('clientId');
    const response = await axios.get(`/api/v1/client/${clientId}/users`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const fetchClient = async (clientId: string) => {
  try {
    const response = await axios.get(`/api/v1/client/${clientId}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const putClient = async (clientData: ClientData) => {
  try {
    const response = await axios.put(`/api/v1/client/${clientData.clientId}`, clientData);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const fetchUserByEmail = async (email: string) => {
  try {
    const response = await axios.get(`/api/v1/users/${email}`);
    if (response.data) {
      localStorage.setItem('clientId', response.data.clientId);
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const createUser = async (authUser: AuthUserData) => {
  const clientId = localStorage.getItem('clientId');
  const response = await axios.post(`/api/v1/client/${clientId}/users`, authUser);
  if (response.status === 201) throw new Error('User already exists');
  if (response.data) {
    return response.data;
  }
  return null;
};

export const deleteUser = async (id: string) => {
  try {
    const clientId = localStorage.getItem('clientId');
    await axios.delete(`/api/v1/client/${clientId}/users/${id}`);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
