import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function FormLabel(theme: Theme) {
  return {
    MuiFormLabel: {
      defaultProps: {
        shrink: 'true',
      },
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
  };
}
