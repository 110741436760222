import React from 'react';
import { Slider, Typography } from '@mui/material';
import { fPercent } from 'src/utils/formatNumber';
import { useDispatch, useSelector } from 'src/redux/hooks';
import { OfferProperties } from 'src/@types/offer-properties';
import { editApprovals, editOfferProperties } from 'src/redux/slices/isoPortal';
import { Approval } from 'src/@types/approval';
import { getApprovalsSelector } from 'src/redux/selectors/isoPortal';

interface CommissionSelectProps {
  offerProperties: OfferProperties;
}

export const CommissionSelect = (props: CommissionSelectProps) => {
  const dispatch = useDispatch();
  const approvals = useSelector(getApprovalsSelector);

  const handleCommissionChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    const commission = value as number;
    const changedApprovals = approvals.map((approval: Approval) => ({
      ...approval,
      commission,
      factorRate: Number(approval.buyRate) + commission,
    }));

    dispatch(editApprovals(changedApprovals));
    dispatch(
      editOfferProperties({
        commission,
      })
    );
  };

  return (
    <div>
      <Typography variant="caption">Commission</Typography>
      <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
        {fPercent((props.offerProperties.commission as number) * 100)}
      </Typography>
      <Slider
        aria-label="commission-amount-slider"
        min={0}
        max={props.offerProperties.maxCommission as number}
        value={props.offerProperties.commission as number}
        step={0.005}
        onChangeCommitted={handleCommissionChange}
        sx={{ pb: 0, mb: 0 }}
        disabled={props.offerProperties.hasLockedOffer}
      />
      <div style={{ display: 'flex', paddingTop: 0, marginTop: 0 }}>
        <Typography variant="caption" display="inline" align={'left'}>
          {fPercent(0)}
        </Typography>
        <div style={{ display: 'flex', flexGrow: 1 }} />
        <Typography variant="caption" display="inline" align={'right'}>
          {fPercent(props.offerProperties.maxCommission * 100)}
        </Typography>
      </div>
    </div>
  );
};
