import React from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormHelperText,
} from '@mui/material';
import { RenderFormikTextField } from 'src/utils/formikFormUtils';
import { useSignedLinkAuth } from 'src/hooks';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { CompanyOwner } from 'src/@types/mcafunds/deal-application';
import { useSelector } from 'react-redux';
import { getContractSelector } from 'src/redux/selectors/isoPortal';

type SendContractDialogProps = {
  data: { owners: CompanyOwner[]; cc: string; isSent: boolean };
  onClose: () => void;
};

const SendContractDialog: React.FC<SendContractDialogProps> = ({ data, onClose }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { dealId, epk, et } = useSignedLinkAuth();

  const contract = useSelector(getContractSelector);
  const validationSchema = Yup.object().shape({
    owners: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required('Required'),
    cc: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required('Required'),
  });

  const onSubmit = async (values: any) => {
    try {
      if (data.isSent) {
        await dealsApi.postResendContract({
          dealId,
          epk,
          et,
        });
      } else {
        await dealsApi.postSendContract({
          dealId,
          epk,
          et,
          owners: JSON.stringify(data.owners),
          cc: values.cc,
          companyName: contract?.company_data?.name_legal ?? '',
          entities: JSON.stringify(contract?.entities),
          pdfContract: contract?.fms.contract,
        });
      }

      enqueueSnackbar(`ESIGN ${data.isSent ? 'resend' : 'sent'}`);
      onClose();
    } catch (error) {
      enqueueSnackbar('Error sending the contract for ESIGN: Please try again!', {
        variant: 'error',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...data,
      cc: data.cc ? data.cc : contract.fms.applicationItem.partner.primaryContactEmail,
      owners: data?.owners?.map((owner: CompanyOwner) => owner.emailAddress)?.join(', '),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open
        onClose={onClose}
        fullWidth
        aria-labelledby="send-to-customer-title"
        aria-describedby="send-to-customer-description"
        data-testid={'zero-balance-letter-dialog'}
      >
        <DialogTitle id="send-to-customer-title">
          {data.isSent ? 'Resend' : 'Send'} for ESIGN
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="send-to-customer-description">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {RenderFormikTextField({
                  renderError:
                    Boolean(formik?.errors?.owners) && formik?.touched?.owners ? true : false,
                  helpers: { ...formik.getFieldProps('owners') },
                  label: 'Merchant Email(s)',
                  required: true,
                  formik,
                  disabled: true,
                })}
              </Grid>
              <Grid item xs={12}>
                {RenderFormikTextField({
                  renderError: Boolean(formik?.errors?.cc) && formik?.touched?.cc ? true : false,
                  helpers: { ...formik.getFieldProps('cc') },
                  label: 'CC Email(s)',
                  required: true,
                  formik,
                  disabled: data.isSent,
                })}
                <FormHelperText
                  error={false}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Use comma separation for multiple emails
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            type={'submit'}
            disabled={!formik.isValid || formik.isSubmitting}
            sx={{ borderRadius: Number(theme.shape.borderRadius) * 2 }}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {data.isSent ? 'Resend' : 'Send'}
          </Button>
          <Button
            variant={'outlined'}
            onClick={onClose}
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderRadius: Number(theme.shape.borderRadius) * 2,
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};

export default SendContractDialog;
