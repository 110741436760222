import axios from '../merchantPortalService';
import { Application } from 'src/@types/application-types';
import { PaymentHistory } from 'src/@types/payment-history';

export const fetchDeals = async () => {
  try {
    const response = await axios.get('/api/v1/deals');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const requestPayoffLetter = async (clientId: string, emailAddress: string) => {
  try {
    const response = await axios.post(`/api/v1/deals/${clientId}/payoffLetter`, { emailAddress });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const requestZeroBalanceLetter = async (clientId: string, emailAddress: string) => {
  try {
    const response = await axios.post(`/api/v1/deals/${clientId}/zeroBalanceLetter`, {
      emailAddress,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return {
    message: 'Unable to request Zero Balance Letter',
  };
};

export const requestRenewal = async (clientId: string, emailAddress: string) => {
  try {
    const response = await axios.post(`/api/v1/deals/${clientId}/renewal`, {
      emailAddress,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return {
    message: 'Unable to request renewal',
  };
};

export const requestPaymentHistory = async (dealId: string) => {
  try {
    const { data } = await axios.get<PaymentHistory[]>(`/api/v1/deals/${dealId}/paymentHistory`);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Unable to load remittance history');
  }
};

export const getFundedDealsByCompanyId = async (companyId: string) => {
  try {
    const response = await axios.get(`/api/v1/fundedDeals/${companyId}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

export const getFundedDealFromMCAFunds = async (application: Application) => {
  try {
    const response = await axios.get(`/api/v1/fundedDeal/${application.appId}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};
