import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Button, Link } from '@mui/material';
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import cssStyles from 'src/utils/cssStyles';
import { HEADER } from 'src/config';
import Logo from 'src/components/Logo';
import { PATH_AUTH } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import MenuMobile from './MenuMobile';
import menuConfig from './MenuConfig';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();
  const { login } = useAuth();
  const { pathname } = useLocation();
  const featureFlags = useFeatureFlags();
  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  const showRegistration = featureFlags.isFeatureEnabled(featureFlags.featureFlagKeys.registration);

  const onLogin = async () => {
    try {
      await login('', '', 'customer');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          {showRegistration && (
            <>
              <Button variant="contained" onClick={onLogin}>
                Log in
              </Button>

              <Link
                variant="subtitle2"
                sx={
                  isOffset
                    ? { pl: theme.spacing(1), color: theme.palette.primary.darker }
                    : { pl: theme.spacing(1), color: '#34E9AC' }
                }
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                <b>or Register Now!</b>
              </Link>
              {!isDesktop && (
                <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={menuConfig} />
              )}
            </>
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
