import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Application } from 'src/@types/application-types';
import * as applicationsAPI from 'src/services/APIs/applicationsAPI';
import { setEditApplication } from './editApplication';

export type ApplicationsState = {
  isLoading: boolean;
  error: Error | string | null;
  applications: Application[];
};

export const initialState: ApplicationsState = {
  isLoading: false,
  error: null,
  applications: [] as Application[],
};

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Data
    getApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload;
    },

    getApplicationSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload;
    },

    addApplicationData(state, action) {
      state.applications.push(action.payload);
      return state;
    },

    editApplicationData(state, action) {
      const applicationIndex = state.applications.findIndex(
        (application: Application) => application.appId === action.payload.appId
      );
      if (applicationIndex > -1) {
        state.applications[applicationIndex] = action.payload;
      } else {
        state.applications.push(action.payload);
      }
      return state;
    },

    deleteApplicationData(state, action) {
      const updatedState = { ...state };
      updatedState.applications = state.applications.filter(
        (application: Application) => application.appId !== action.payload.appId
      );
      return updatedState;
    },

    resetApplicationData(state) {
      state.applications = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetApplicationData,
  addApplicationData,
  editApplicationData,
  deleteApplicationData,
} = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const getApplications = createAsyncThunk(
  'getApplications',
  async (_, { dispatch, getState }) => {
    dispatch(slice.actions.startLoading());
    try {
      const applications: Application[] = await applicationsAPI.fetchApplications();
      if (applications?.length > 0) {
        dispatch(slice.actions.getApplicationsSuccess(applications));

        // @ts-ignore
        const { editApplication } = getState().editApplication;
        dispatch(setEditApplication(editApplication));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const addApplication = createAsyncThunk(
  'addApplication/addApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const application: Application = payload;
      dispatch(addApplicationData(application));
      await applicationsAPI.postApplication(application);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const deleteApplication = createAsyncThunk(
  'deleteApplication/deleteApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(deleteApplicationData(payload));
      await applicationsAPI.deleteApplication(payload);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

// Get an Application and load it into the application area and the
// currently edited application is applicable.
export const getApplication = createAsyncThunk(
  'getApplications',
  async (payload: any, { dispatch, getState }) => {
    dispatch(slice.actions.startLoading());
    try {
      const applications: Application[] = await applicationsAPI.fetchApplication(payload);
      if (applications.length === 1) {
        const loadedApplication = applications[0];
        dispatch(editApplicationData(loadedApplication));

        // @ts-ignore
        const { editApplication } = getState().editApplication;
        if (editApplication && editApplication.appId === loadedApplication.appId) {
          console.log(`Reloading EditApplication: ${editApplication.appId}`);
          dispatch(setEditApplication(loadedApplication));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.endLoading());
    }
  }
);

export const editApplication = createAsyncThunk(
  'editApplication/editApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(editApplicationData(payload.application));
      await applicationsAPI.putApplication(payload.application);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);
