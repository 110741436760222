import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Button, Grid, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'src/redux/hooks';
import { useAuth } from 'src/hooks';
import { fCurrency, fCurrencyShort } from 'src/utils/formatNumber';
import { getDealCalc } from 'src/redux/slices/isoPortal';
import { getEstimatedRemittances } from 'src/utils/paymentUtils';
import { SendToCustomerDialog } from 'src/portals/components/dialogs';
import { ReviewData } from 'src/@types/application-types';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { Approval } from 'src/@types/approval';
import { OfferProperties } from 'src/@types/offer-properties';
import { isRegulatedOffer } from 'src/utils/stateRegulations';
import { createUpdatedDealCalcData } from 'src/portals/components/offers/deal-calc/utils';

interface AcceptOfferControlsPanelProps {
  selectedOffer: Approval;
  offerProperties: OfferProperties;
  reviewData?: ReviewData;
  dealCalcData?: any;
}

export const SelectedOfferControl = ({
  selectedOffer,
  offerProperties,
}: AcceptOfferControlsPanelProps) => {
  const theme = useTheme();
  return (
    <div>
      <Typography variant="caption">Selected Offer:</Typography>
      <Typography variant="h3" sx={{ color: `${theme.palette.primary.main}` }}>
        {fCurrencyShort(offerProperties.requestedAmount)}
      </Typography>
      <Typography variant="caption">
        Estimated Term: <b>{selectedOffer?.term} months</b>
      </Typography>
    </div>
  );
};

export const SelectedOfferRemittanceSummaryControl = ({
  selectedOffer,
  offerProperties,
}: AcceptOfferControlsPanelProps) => (
  <div>
    <Typography variant="caption">Estimated number of remittances</Typography>
    <Typography variant="h4">{offerProperties.selectedOfferTotalRemittances}</Typography>
    <Typography variant="caption">
      Estimated {selectedOffer?.remittanceSchedule?.replace('_', '-')} remittance
    </Typography>
    <Typography variant="h4">{fCurrency(offerProperties.selectedOfferRemittanceAmount)}</Typography>
    <Typography variant="caption">Estimated total remittance</Typography>
    <Typography variant="h4">
      {fCurrency(offerProperties.selectedOfferTotalRemittanceAmount)}
    </Typography>
  </div>
);

export const AcceptOfferControlsPanel: React.FC<AcceptOfferControlsPanelProps> = (props) => {
  const { search, pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [sendCustomerVisible, setSendCustomerVisible] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { dealCalcData } = props;

  const totalRemittances = useMemo(
    () =>
      getEstimatedRemittances(props.selectedOffer.remittanceSchedule, +props.selectedOffer.term),
    [props.selectedOffer.remittanceSchedule, props.selectedOffer.term]
  );

  const estimatedTotalRemittance = useMemo(
    () => props.selectedOffer.factorRate * props.offerProperties.requestedAmount,
    [props.selectedOffer.factorRate, props.offerProperties.requestedAmount]
  );

  const handleSendToCustomer = () => setSendCustomerVisible(true);

  const handleCloseDialog = () => setSendCustomerVisible(false);

  const handleAcceptOffer = async (sendCustomerData?: ReviewData) => {
    setIsAccepting(true);

    try {
      const queryParams = queryString.parse(search) as { epk?: string; et?: string };
      const currentApproval =
        dealCalcData.saved_deal_data.selected_approvals.rows[
          props.offerProperties.selectedOfferIndex
        ];

      const calculateApr =
        props.offerProperties.requestedAmount !== currentApproval.funded_amount_raw ||
        props.selectedOffer.remittanceSchedule !== currentApproval.payment_type;

      const updatedDealCalcData = createUpdatedDealCalcData({
        dealCalcData,
        selectedOfferIndex: props.offerProperties.selectedOfferIndex,
        amountSeeking: props.offerProperties.requestedAmount,
        selectedOffer: props.selectedOffer,
        totalRemittances,
        estimatedTotalRemittance,
        sendCustomerData,
      });

      const shouldSendSignedLink =
        !isAuthenticated || pathname === '/offers/iso' || pathname === '/partner/status';

      const epk = queryParams.epk ?? localStorage.getItem('epk') ?? '';
      const et = queryParams.et ?? localStorage.getItem('et') ?? '';

      const finalData = {
        ...updatedDealCalcData,
        ...(shouldSendSignedLink && epk && { epk }),
        ...(shouldSendSignedLink && et && { et }),
      };

      await dealsApi.acceptOffer(finalData, calculateApr);
      dispatch(getDealCalc({ dealId: props.offerProperties.dealId, epk, et }));

      enqueueSnackbar('Offer has been locked.');
      return true;
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error while trying to accept the offer, please retry or contact support', {
        variant: 'error',
      });
      return false;
    } finally {
      setIsAccepting(false);
    }
  };

  const handlePreviewCustomerEmail = () => {
    // TODO: Load the contents of the email we will send and display it
    // TODO: Enabled view of the recipients and ability to add recipients
    // - send to customer, ISO, and ISO Manager
  };

  const offerRequiresCustomerReview = useMemo(
    () =>
      isRegulatedOffer(props?.dealCalcData?.location_state, props.offerProperties.requestedAmount),
    [props.dealCalcData?.location_state, props.offerProperties.requestedAmount]
  );

  return (
    <Box
      sx={{
        display: 'grid',
        justifyContent: 'center',
        marginTop: '2rem',
      }}
    >
      <Grid container columnSpacing={8}>
        <Grid item sx={{ alignSelf: 'center' }}>
          <SelectedOfferControl
            offerProperties={props.offerProperties}
            selectedOffer={props.selectedOffer}
          />
        </Grid>
        <Grid item>
          <SelectedOfferRemittanceSummaryControl
            offerProperties={props.offerProperties}
            selectedOffer={props.selectedOffer}
          />
        </Grid>
        <Grid item sx={{ alignSelf: 'center' }}>
          <Stack direction="column" spacing={1}>
            {sendCustomerVisible &&
              props.offerProperties.selectedOfferIndex >= 0 &&
              props.reviewData && (
                <SendToCustomerDialog
                  data={props.reviewData}
                  onClose={handleCloseDialog}
                  handleAcceptOffer={handleAcceptOffer}
                />
              )}
            <LoadingButton
              variant="contained"
              disabled={!props.reviewData}
              loading={isAccepting}
              onClick={() => handleAcceptOffer()}
            >
              {isAccepting ? 'Accepting Offer' : 'Accept Offer'}
            </LoadingButton>
            {offerRequiresCustomerReview && (
              <>
                <Button
                  variant="contained"
                  onClick={handleSendToCustomer}
                  disabled={!props.reviewData || isAccepting}
                >
                  Accept & Send to Customer
                </Button>
                <Typography variant={'caption'}>
                  This offer requires regulatory disclosure
                </Typography>
              </>
            )}
            {!props.reviewData && (
              <Typography variant={'caption'}>
                Please contact Libertas. Missing contact information.
              </Typography>
            )}
            {false && (
              <Button endIcon={<SendIcon />} onClick={handlePreviewCustomerEmail}>
                Preview
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
