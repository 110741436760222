import { ReactNode } from 'react';
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UserData, defaultUserData } from 'src/@types/user';
import { fetchUserByEmail } from 'src/services/APIs/adminAPI';

export type GlobalDrawerState = {
  isOpen: boolean;
  title: string | null;
  content: ReactNode;
  subTitle: string | null;
  coversContent: boolean;
  isEnabled: boolean;
  anchor: 'right' | 'left' | 'bottom';
  showHeader: boolean;
};

export type MainAppState = {
  isLoading: boolean;
  error: Error | string | null;
  userData: UserData;
  globalDrawerProps: GlobalDrawerState;
};

const initialGlobalDrawerState: GlobalDrawerState = {
  isOpen: false,
  title: null,
  content: null,
  subTitle: null,
  coversContent: false,
  isEnabled: true,
  anchor: 'right',
  showHeader: true,
};

export const initialState: MainAppState = {
  isLoading: false,
  error: null,
  userData: defaultUserData,
  globalDrawerProps: { ...initialGlobalDrawerState },
};

const slice = createSlice({
  name: 'mainApp',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    updateGlobalDrawer(state, action) {
      state.globalDrawerProps = action.payload;
    },

    updateUserData(state, action) {
      state.userData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateGlobalDrawer, updateUserData } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const openGlobalDrawer = createAction(
  'globalDrawer/open',
  function prepare(drawerState: GlobalDrawerState) {
    return {
      payload: drawerState,
    };
  }
);

export const closeGlobalDrawer = createAction('globalDrawer/close', function prepare() {
  return {
    payload: { ...initialGlobalDrawerState },
  };
});

export const getClientByUserEmail = createAsyncThunk(
  'getClientByUserEmail',
  async (email: string, thunkApi) => {
    thunkApi.dispatch(slice.actions.startLoading());

    try {
      const userData: UserData = await fetchUserByEmail(email);
      if (userData) {
        thunkApi.dispatch(slice.actions.updateUserData(userData));
      }
    } catch (error) {
      thunkApi.dispatch(slice.actions.hasError(error));
    } finally {
      thunkApi.dispatch(slice.actions.endLoading());
    }
  }
);

export const onUserLogout = createAction('onUserLogout', function prepare() {
  return {
    payload: { ...initialState },
  };
});
