import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ESignAgreement from 'src/portals/components/compliance/ESignAgreement';

export type ESignAgreementDialogProps = {
  title?: string;
  onAccept?: () => void;
  onDecline?: () => void;
};

export default function ESignAgreementDialog(props: ESignAgreementDialogProps) {
  const { title, onAccept, onDecline } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const scroll = 'paper';

  React.useEffect(() => {
    setOpen(open);
  }, [setOpen, open]);

  const handleAccept = () => {
    setOpen(false);
    onAccept?.();
  };

  const handleDecline = () => {
    setOpen(false);
    onDecline?.();
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDecline}
        scroll={scroll}
        aria-labelledby="review-agreement-dialog-title"
        aria-describedby="review-agreement-dialog-description"
        data-testid={'review-agreement'}
        maxWidth={'md'}
      >
        <DialogTitle
          id="review-agreement-dialog-title"
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="review-agreement-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <ESignAgreement />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {onAccept && (
            <Button onClick={handleAccept} variant={'contained'}>
              I Accept
            </Button>
          )}
          {onDecline && (
            <Button onClick={handleDecline} variant={'contained'}>
              I Decline
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const EsignConsentAgreement = (props: ESignAgreementDialogProps) => {
  const { onAccept, onDecline } = props;
  const title = 'E-Sign Consent Agreement';
  return <ESignAgreementDialog title={title} onAccept={onAccept} onDecline={onDecline} />;
};
