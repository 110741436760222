import cuid from 'cuid';
import { ApplicantInfo, Application, OfferInfo, OwnerInfo } from '../@types/application-types';

export const GetDefaultNewOwner = (appId: string) => {
  const clientId = localStorage.getItem('clientId') || '';
  const owner: OwnerInfo = {
    clientId,
    id: `OWNER-${cuid().toUpperCase()}`,
    appId,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    dateOfBirth: new Date(),
    SSN: '',
    percentOwnership: 0,
    homeStreetAddress1: '',
    homeStreetAddress2: '',
    city: '',
    state: '',
    zip: '',
    userDate: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return owner;
};
export const GetDefaultNewApplication = (defaultClientId = 'LEAD') => {
  const clientId = localStorage.getItem('clientId') || defaultClientId;

  const appId: string = `APP-${cuid().toUpperCase()}`;
  const owner1: OwnerInfo = GetDefaultNewOwner(appId);

  const applicant: ApplicantInfo = {
    clientId,
    appId,
    legalBusinessName: '',
    industry: '',
    grossAnnualSales: 0,
    requestedAmount: 0,
    purposeOfCapital: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    password: '',
    dba: '',
    entityType: '',
    businessStreetAddress1: '',
    businessStreetAddress2: '',
    businessCity: '',
    businessState: '',
    businessZip: '',
    federalTaxID: '',
    businessStartDate: undefined,
    businessPhone: '',
    averageMonthlySales: 0,
    websiteURL: '',
    acceptsCreditCards: false,
    hasCurrentShortTermFinancing: false,
    owners: [owner1],
    userDate: '',
    businessInfoEdited: false,
    basicInfoEdited: false,
    ownerInfoEdited: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const newApplication: Application = {
    clientId,
    appId,
    status: 'NotSubmitted',
    createdAt: new Date(),
    updatedAt: new Date(),
    acceptedAgreements: false,
    acceptedEsignConcent: false,
    applicant,
    offers: [] as OfferInfo[],
    stips: {
      appId,
      bankStatements: [],
      driverLicence: undefined,
      voidedCheck: undefined,
    },
  };

  return newApplication;
};
export const isOpenApplication = (application: Application) =>
  !(
    application.status === 'OfferAccepted' ||
    application.status === 'Funded' ||
    application.status === 'Rejected'
  );
export const isUnsubmittedApplication = (application: Application) =>
  application.status === 'NotSubmitted';
