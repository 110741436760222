import { SettingsValueProps } from './components/settings/type';
import { PATH_DASHBOARD } from './routes/paths';

export const MERCHANT_PORTAL_SERVICE_API =
  process.env.REACT_APP_MERCHANT_PORTAL_URL || 'http://localhost:3000';

export const NODE_URL = process.env.REACT_APP_NODE_URL || 'http://localhost:8000';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.app.dashboard;

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'vertical',
  themeColorPresets: 'default',
  themeStretch: false,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  customDomain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
  mgmtClientId: process.env.REACT_APP_MP_AUTH0_MGMT_API_CLIENT_ID || '',
};

export const codatRedirectEnv = process.env.REACT_APP_CODAT_REDIRECT_ENV || 'localhost:8080';

export const codatBaseLinkURL =
  process.env.REACT_APP_CODAT_BASE_LINK_URL || 'https://link.codat.io/company';
