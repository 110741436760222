import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fCurrency, fCurrencyShort, fPercent, fShortenNumber } from 'src/utils/formatNumber';
import { OfferProperties } from 'src/@types/offer-properties';
import { DealApplication } from 'src/@types/mcafunds/deal-application';
import ValueWidget from 'src/portals/iso-portal/components/RateWidget';

interface OfferPropertiesSummaryProps {
  deal: DealApplication;
  offerProperties: OfferProperties;
  showDealSummary?: boolean;
}

const OfferPropertiesSummary = ({
  deal,
  offerProperties,
  showDealSummary = false,
}: OfferPropertiesSummaryProps) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1} mb={2}>
      {showDealSummary && (
        <Grid item xs={12} sm={5}>
          <Box pb={0} pt={0}>
            <Typography variant="h5" display="inline">
              Maximum Funding:{' '}
            </Typography>
            <Typography display="inline" variant="h5" color={theme.palette.primary.main}>
              {fCurrency(offerProperties.maximumFunding)}
            </Typography>
          </Box>
          <Box pb={0} pt={0}>
            <Typography variant="h5" display="inline">
              Maximum Estimated Term:{' '}
            </Typography>
            <Typography display="inline" variant="h5" color={theme.palette.primary.main}>
              {offerProperties.maximumTerm} months
            </Typography>
          </Box>
          <Box pb={0} pt={0}>
            <Typography variant="caption">{deal?.company?.nameLegal ?? ''}</Typography>
            {deal?.amountRequested && (
              <Typography variant="caption">
                {' requested '}
                {fCurrency(deal?.amountRequested)}
              </Typography>
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={3}>
        <ValueWidget
          title="Commission Amount"
          value={fCurrencyShort(offerProperties.selectedOfferCommissionAmount)}
          icon={'dashicons:money-alt'}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <ValueWidget
          title="Commission"
          value={fPercent(offerProperties.selectedOfferCommission * 100)}
          icon={'dashicons:money-alt'}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <ValueWidget
          title="Origination Fee"
          value={fCurrencyShort(offerProperties.selectedOfferFee)}
          icon={'dashicons:money'}
        />
      </Grid>
      {!showDealSummary && (
        <>
          <Grid item xs={12} sm={2}>
            <ValueWidget
              title="Buy Rate"
              value={fShortenNumber(offerProperties.selectedOfferBuyRate)}
              icon={'dashicons:plus-alt2'}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ValueWidget
              title="Factor Rate"
              value={fShortenNumber(offerProperties.selectedOfferFactor)}
              icon={'dashicons:plus-alt'}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OfferPropertiesSummary;
