import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'src/redux/hooks';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useSignedLinkAuth } from 'src/hooks';
import { DealApplication } from 'src/@types/mcafunds/deal-application';
import { OfferProperties } from 'src/@types/offer-properties';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { getContract, getDealCalc } from 'src/redux/slices/isoPortal';

interface UnlockDealButtonProps {
  deal: DealApplication;
  offerProperties: OfferProperties;
}

const allowedUnlockStatuses = [
  'underwriting',
  'preapproval',
  'contract_out',
  'Submitted',
  'Pre-Submission',
  'Processing',
  'Underwriting',
  'Contracts',
  'Approvals',
];

export const isUnlockAllowed = ({ deal, offerProperties }: UnlockDealButtonProps) =>
  offerProperties.hasLockedOffer && allowedUnlockStatuses.includes(deal.state);

export const UnlockDealButton = ({ deal, offerProperties }: UnlockDealButtonProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { epk, et } = useSignedLinkAuth();

  const isLoading = useSelector((state) => state.isoPortal.isLoading);

  const [isUnlocking, setIsUnlocking] = useState<boolean>(false);

  const canUnlock = useMemo(
    () => isUnlockAllowed({ offerProperties, deal }),
    [offerProperties, deal]
  );

  const handleUnlockDeal = useCallback(async () => {
    if (!canUnlock) return;

    setIsUnlocking(true);

    try {
      await dealsApi.postUnlockDealRequest({ dealId: offerProperties.dealId, epk, et });

      dispatch(getDealCalc({ dealId: offerProperties.dealId, epk, et }));
      dispatch(getContract({ dealId: offerProperties.dealId, epk, et }));
    } catch (error) {
      enqueueSnackbar('Error while unlocking deal. Please try again!', { variant: 'error' });
    } finally {
      setIsUnlocking(false);
    }
  }, [offerProperties.dealId, canUnlock, epk, et, dispatch, enqueueSnackbar]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      p={0}
      pl={1}
      pr={1}
    >
      <LoadingButton
        fullWidth
        variant="contained"
        onClick={handleUnlockDeal}
        disabled={!canUnlock}
        sx={{ minWidth: '200px', mb: 1 }}
        loading={isUnlocking || isLoading}
      >
        Unlock Deal
      </LoadingButton>
    </Stack>
  );
};
