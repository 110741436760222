import { ApplicationsState, initialState as initialApplicationsState } from './slices/applications';
import { FundedDealsState, initialState as initialDealsState } from './slices/fundedDeals';
import {
  ReferenceDataState,
  initialState as initialReferenceDataState,
} from './slices/referenceData';
import { AdminState, initialState as initialAdminState } from './slices/admin';
import { MainAppState, initialState as initialMainAppState } from './slices/mainApp';
import {
  EditApplicationState,
  initialState as initialEditApplicationState,
} from './slices/editApplication';
import { SupportState, initialState as initialSupportState } from './slices/support';
import { ISOPortalState, initialState as initialISOPortalState } from './slices/isoPortal';

export type MerchantPortalState = {
  applications: ApplicationsState;
  fundedDeals: FundedDealsState;
  referenceData: ReferenceDataState;
  admin: AdminState;
  mainApp: MainAppState;
  editApplication: EditApplicationState;
  support: SupportState;
  isoPortal: ISOPortalState;
};

export const merchantPortalInitialState: MerchantPortalState = {
  applications: initialApplicationsState,
  fundedDeals: initialDealsState,
  referenceData: initialReferenceDataState,
  admin: initialAdminState,
  mainApp: initialMainAppState,
  editApplication: initialEditApplicationState,
  support: initialSupportState,
  isoPortal: initialISOPortalState,
};
