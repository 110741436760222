import ReviewAgreement, { ReviewAgreementDialogProps } from './ReviewAgreement';

const CreditPolicyAgreement = (props: ReviewAgreementDialogProps) => {
  const { onClose } = props;
  const agreementFile = 'credit-policy-agreement.txt';
  const title = 'Credit Policy Agreement';
  return <ReviewAgreement agreementFile={agreementFile} title={title} onClose={onClose} />;
};

export default CreditPolicyAgreement;
