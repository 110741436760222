import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import NotesIcon from '@mui/icons-material/Notes';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface PreapprovalNoteProps {
  note: string;
}
export default function PreapprovalNote({ note }: PreapprovalNoteProps) {
  if (!note) return null;

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">Underwriting Notes:</Typography>
          {note}
        </React.Fragment>
      }
      placement={'bottom-end'}
    >
      <IconButton size={'small'} aria-label="Show Notes">
        <NotesIcon />
      </IconButton>
    </HtmlTooltip>
  );
}
