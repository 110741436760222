import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number | undefined) {
  if (undefined) return '';
  return numeral(number).format('$0,0.00');
}

export function fCurrencyShort(number: string | number | undefined) {
  if (undefined) return '';
  return numeral(number).format('$0,0');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number, removePrecision = true) {
  const result = numeral(number).format('0.00a');
  if (removePrecision) return result.replace('.00', '');
  return result;
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
