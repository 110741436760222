import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Grid, Divider, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { getDealSelector, getOfferPropertiesSelector } from 'src/redux/selectors/isoPortal';
import { fCurrencyShort } from 'src/utils/formatNumber';
import { isRegulatedOffer } from 'src/utils/stateRegulations';
import { RegulatedOfferLabel } from 'src/portals/iso-portal/components/RegulatedOfferLabel';
import { MaximumApprovalWidget } from 'src/portals/iso-portal/components/MaximumApprovalWidget';
import { DealApplication } from '../../@types/mcafunds/deal-application';

interface ApplicantDetailsProps {
  deal: DealApplication;
  offerHasRegulatoryRequirements: boolean;
}
export const ApplicantDetails = ({
  deal,
  offerHasRegulatoryRequirements,
}: ApplicantDetailsProps) => (
  <>
    <Typography variant="h6">Applicant Info:</Typography>
    <Typography variant="body1">{deal?.company?.nameLegal ?? ''}</Typography>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1">State:</Typography>
      {deal?.company?.locationState ? (
        <Typography variant="body1">
          {offerHasRegulatoryRequirements && <RegulatedOfferLabel />}
          {deal?.company?.locationState}
        </Typography>
      ) : (
        <Skeleton variant="rectangular" width={60} />
      )}
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1">Requested Amount:</Typography>
      {deal?.amountRequested ? (
        <Typography variant="body1">{fCurrencyShort(deal?.amountRequested)}</Typography>
      ) : (
        <Skeleton variant="rectangular" width={60} />
      )}
    </Stack>
    {deal?.useOfProceeds && (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Use of Proceeds:</Typography>
        <Typography variant="body1">{deal?.useOfProceeds}</Typography>
      </Stack>
    )}
  </>
);

export const ApplicantSummary = () => {
  const theme = useTheme();
  const deal = useSelector(getDealSelector);
  const offerProperties = useSelector(getOfferPropertiesSelector);

  const offerHasRegulatoryRequirements = useMemo(
    () => isRegulatedOffer(deal?.company?.locationState || '', offerProperties.requestedAmount),
    [deal?.company?.locationState, offerProperties.requestedAmount]
  );

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1}>
        <Grid
          item
          xs={12}
          p={1}
          sx={{
            borderRadius: '4px',
            backgroundColor: theme.palette.background.neutral,
            p: 1,
          }}
        >
          {deal && (
            <ApplicantDetails
              deal={deal}
              offerHasRegulatoryRequirements={offerHasRegulatoryRequirements}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: theme.palette.background.neutral,
          }}
        >
          <Divider sx={{ margin: theme.spacing(1), mt: 0, mb: 0 }} />
        </Grid>
        <Grid
          item
          xs={12}
          p={1}
          sx={{
            borderRadius: '4px',
            backgroundColor: theme.palette.background.neutral,
            p: 1,
          }}
        >
          <MaximumApprovalWidget />
        </Grid>
      </Grid>
    </Stack>
  );
};
