import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Table, TableBody, Container, TableContainer } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import useTable, { emptyRows } from 'src/hooks/useTable';
import { TableEmptyRows, TableHeadCustom } from 'src/components/table';
import PrepaysTableRow from './PrepaysTableRow';
import { Prepay } from 'src/@types/prepays';

const TABLE_HEAD = [
  { id: 'term', label: 'Term', align: 'left' },
  { id: 'buyRate', label: 'Buy Rate', align: 'left' },
  { id: 'commission', label: 'Commission', align: 'left' },
  { id: 'points', label: 'Points', align: 'left' },
  { id: 'sellRate', label: 'Sell Rate', align: 'left' },
  { id: 'totalRepayment', label: 'Total Repayment', align: 'left' },
];

// ----------------------------------------------------------------------

interface PrepaysTableProps {
  prepays: Prepay[];
}

export default function PrepaysTable({ prepays }: PrepaysTableProps) {
  const theme = useTheme();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    //
    onSort,
  } = useTable({ defaultDense: true, defaultRowsPerPage: 12 });

  const { themeStretch } = useSettings();
  const [tableData, setTableData] = useState(prepays);

  useEffect(() => {
    setTableData(prepays);
  }, [prepays, setTableData]);

  const denseHeight = dense ? 52 : 72;

  return (
    <Container maxWidth={themeStretch ? false : 'lg'} sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <TableContainer
        sx={{
          position: 'relative',
          padding: theme.spacing(1),
          borderRadius: '4px',
        }}
      >
        <Table size={'small'}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={tableData.length}
            numSelected={selected.length}
            onSort={onSort}
            sx={{
              backgroundColor: theme.palette.background.neutral,
              color: theme.palette.primary.darker,
            }}
          />

          <TableBody>
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <PrepaysTableRow
                key={`${row.term}-${row.buyRate}`}
                row={row}
                selected={selected.includes(`${row.term}`)}
                onSelectRow={() => onSelectRow(`${row.term}`)}
              />
            ))}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
