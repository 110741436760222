import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Application } from 'src/@types/application-types';
import { ClientData } from 'src/@types/client';
import { UserData } from 'src/@types/user';
import * as supportAPI from 'src/services/APIs/supportAPI';

export type SupportState = {
  isLoading: boolean;
  error: Error | string | null;
  users: UserData[];
  clients: ClientData[];
  applications: Application[];
};

export const initialState: SupportState = {
  isLoading: false,
  error: null,
  users: [],
  clients: [],
  applications: [],
};

const slice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAllApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload;
    },

    getAllUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },

    updateClientsData(state, action) {
      state.clients = action.payload;
    },

    editApplicationData(state, action) {
      const applicationIndex = state.applications.findIndex(
        (application: Application) => application.appId === action.payload.appId
      );
      if (applicationIndex > -1) {
        state.applications[applicationIndex] = action.payload;
      } else {
        state.applications.push(action.payload);
      }
      return state;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getClientsSuccess, editApplicationData } = slice.actions;

export const getAllApplications = createAsyncThunk(
  'getAllApplications',
  async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    try {
      const applications: Application[] = await supportAPI.fetchAllApplications();
      if (applications) {
        dispatch(slice.actions.getAllApplicationsSuccess(applications));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const editApplication = createAsyncThunk(
  'support/editApplication',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(editApplicationData(payload.application));
      await supportAPI.putApplication(payload.application);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getClientsData = createAsyncThunk('getClientsData', async (_, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  try {
    const clients: ClientData[] = await supportAPI.fetchClients();
    if (clients) {
      dispatch(slice.actions.getClientsSuccess(clients));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
});

export const updateClientsData = createAsyncThunk(
  'updateClientData',
  async (payload: any, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    try {
      const client: ClientData = await supportAPI.putClient(payload.clientData);
      if (client) {
        const clients: ClientData[] = await supportAPI.fetchClients();
        if (clients) {
          dispatch(slice.actions.getClientsSuccess(clients));
          dispatch(getAllApplications());
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getAllUsers = createAsyncThunk('getAllUsers', async (_, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  try {
    const users: UserData[] = await supportAPI.fetchAllUsers();
    if (users) {
      dispatch(slice.actions.getAllUsersSuccess(users));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
});
