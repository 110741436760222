import { styled, useTheme } from '@mui/material/styles';
import { Container, Paper, Stack } from '@mui/material';
import Page from 'src/components/Page';
import { useSelector } from 'src/redux/hooks';
import { getDealSelector, getOfferPropertiesSelector } from 'src/redux/selectors/isoPortal';
import OfferPropertiesSummary from 'src/portals/components/OfferPropertiesSummary';
import AccordionContainer from 'src/portals/iso-portal/components/AccordianContainer';
import { OffersReceivedSelectionPanel } from 'src/portals/iso-portal/components/status-panels/OffersReceivedSelectionPanel';
import ErrorLoadingDeal from './ErrorLoadingDeal';
import LoadingScreen from 'src/components/LoadingScreen';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: theme.spacing(0),
}));

const ISOPortalMain = () => {
  const theme = useTheme();

  const deal = useSelector(getDealSelector);
  const offerProperties = useSelector(getOfferPropertiesSelector);
  const isLoading = useSelector((state) => state.isoPortal.loadingDeal);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!deal) {
    return <ErrorLoadingDeal />;
  }

  return (
    <Page title="Libertas - Deal Portal">
      <RootStyle>
        <Container
          sx={{ position: 'relative', height: '100%', pt: 0 }}
          data-testid={'offer-container'}
        >
          <ContentStyle>
            <Paper
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
                padding: '4px',
                marginBottom: theme.spacing(2),
              }}
            >
              <OfferPropertiesSummary deal={deal} offerProperties={offerProperties} />

              {deal && <OffersReceivedSelectionPanel deal={deal} />}

              {deal?.state === 'contract' && offerProperties.hasLockedOffer && (
                <Stack>
                  <AccordionContainer title={'Bank Verification'}>
                    <div>Bank Verification</div>
                  </AccordionContainer>
                </Stack>
              )}
            </Paper>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default ISOPortalMain;
