import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { DealApplication } from 'src/@types/mcafunds/deal-application';
import { OfferProperties } from 'src/@types/offer-properties';
import { useSelector } from 'src/redux/hooks';
import { getDealSelector, getOfferPropertiesSelector } from 'src/redux/selectors/isoPortal';
import { MerchantSelection } from '../../partner-portal/DealManagement';
import { Typography, Skeleton } from '@mui/material';
import { ContractsControlsPanel } from '../../partner-portal/ContractsControlsPanel';
import { ClosingControlsPanel } from '../../partner-portal/ClosingControlsPanel';
import { fDate } from 'src/utils/formatTime';
import { AcceptedOfferControlsPanel } from '../../partner-portal/AcceptedOfferControlsPanel';
import { UnlockDealButton } from './UnlockDealButton';
import { FundingControlsPanel } from '../../partner-portal/FundingControlsPanel';

const steps = [
  'Pre-approval',
  'Offer Accepted',
  'Contracts',
  'In Closing',
  'Clear to Close',
  'Funded',
];

interface DealApplicationProgressProps {
  deal: DealApplication;
  offerProperties: OfferProperties;
}

const getStepFromDealApplicationStatus = (props: DealApplicationProgressProps) => {
  const { deal, offerProperties } = props;
  switch (deal.state) {
    case 'rejected':
      return -1;
    case 'preapproval':
      if (offerProperties.hasLockedOffer) return 1;
      return 0;
    case 'contract_out':
      return 2;
    case 'closing':
      return 3;
    case 'clear_to_close':
      return 4;
    case 'funded':
      return 5;
    case 'underwriting':
    case 'pending':
    case 'processing':
    default:
      return 0;
  }
};

export const DealApplicationProgress = (props: DealApplicationProgressProps) => {
  const activeStep = getStepFromDealApplicationStatus(props);
  return (
    <Stack sx={{ width: '100%' }} spacing={4} pt={2}>
      {activeStep >= 0 && (
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ marginTop: 0 }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </Stack>
  );
};

export const DealApplicationProgressVertical = () => {
  const deal = useSelector(getDealSelector);
  const offerProperties = useSelector(getOfferPropertiesSelector);

  if (!deal) {
    return null;
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4} pt={0}>
      <Stack direction="row" justifyContent="space-between" pl={2} pr={4}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }}>Deal Progress</Typography>
        {deal && (
          <div>
            <Typography variant="caption" component={'div'} pt={1} align={'right'}>
              Deal ID: {deal?.identifier ?? deal.dealId}
            </Typography>
            <Typography variant="caption" component={'div'} pt={0} align={'right'}>
              Submitted: {fDate(deal.createdTs)}
            </Typography>
          </div>
        )}
      </Stack>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
            mt: 1,
          },
          [`&.MuiTimeline-root`]: {
            mt: 1,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} mb={1}>
              Pre-approval
            </Typography>
            {!deal && <Skeleton width={'100%'} height={'44px'} />}
            {deal && !offerProperties.hasLockedOffer && (
              <MerchantSelection deal={deal} offerProperties={offerProperties} />
            )}
            {deal && offerProperties.hasLockedOffer && (
              <UnlockDealButton deal={deal} offerProperties={offerProperties} />
            )}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              variant={!offerProperties.hasLockedOffer ? 'outlined' : 'filled'}
              color="primary"
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} mb={1}>
              Offer Accepted
            </Typography>
            {deal && offerProperties.hasLockedOffer && (
              <MerchantSelection deal={deal} offerProperties={offerProperties} />
            )}
            <AcceptedOfferControlsPanel />
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} mb={1}>
              Contracts
            </Typography>
            <ContractsControlsPanel />
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} mb={1}>
              Closing
            </Typography>
            <ClosingControlsPanel />
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} mb={1}>
              Funding
            </Typography>
            <FundingControlsPanel />
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Stack>
  );
};
