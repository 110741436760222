import { useTheme, styled } from '@mui/material/styles';
import { Card, Typography, Box, CardProps } from '@mui/material';
import { ColorSchema } from 'src/theme/palette';
import Iconify from 'src/components/Iconify';

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.darker,
}));

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 120,
  height: 120,
  opacity: 0.12,
  position: 'absolute',
  right: theme.spacing(-3),
  color: theme.palette.primary.darker,
}));

interface Props extends CardProps {
  icon?: string;
  title: string;
  value: string;
  color?: ColorSchema;
}

export default function RateWidget({ title, value, icon, ...other }: Props) {
  const theme = useTheme();

  return (
    <RootStyle
      sx={{
        mb: 2,
        bgcolor: theme.palette.background.neutral,
        borderRadius: '6px',
      }}
      {...other}
    >
      <Box>
        <Typography variant="h4">{value}</Typography>
        <Typography variant="body2" sx={{ opacity: 0.72 }} fontWeight={700}>
          {title}
        </Typography>
      </Box>
      {icon && <IconStyle icon={icon} />}
    </RootStyle>
  );
}
