import React, { useCallback, useMemo } from 'react';
import { maxBy, minBy } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, FormHelperText } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { useDispatch, useSelector } from 'src/redux/hooks';
import {
  getApprovalsSelector,
  getDealCalcSelector,
  getOfferPropertiesSelector,
} from 'src/redux/selectors/isoPortal';
import { editOfferProperties } from 'src/redux/slices/isoPortal';
import { DealApplication } from 'src/@types/mcafunds/deal-application';
import {
  AcceptOfferControlsPanel,
  SelectedOfferControl,
  SelectedOfferRemittanceSummaryControl,
} from 'src/portals/partner-portal/AcceptOfferControlsPanel';
import { createReviewData } from 'src/portals/components/offers/deal-calc/utils';
import { PrepaysPanel } from 'src/portals/components/prepays/PrepaysPanel';
import PreapprovalNote from 'src/portals/components/PreapprovalNote';
import PreapprovalRowItem from '../PreapprovalRowItem';
import { RequestedAmountInput } from '../RequestedAmountInput';
import { RemittanceScheduleSelect } from '../RemittanceScheduleSelect';
import { CommissionSelect } from '../CommissionSelect';
import { ACHControl } from './components';
import { Prepay } from 'src/@types/prepays';
import { fCurrencyShort, fShortenNumber } from 'src/utils/formatNumber';
import { isUnlockAllowed } from '../UnlockDealButton';

interface OffersReceivedSelectionPanelProps {
  deal: DealApplication;
  showPartnerControls?: boolean;
}

interface PrepaysControlProps {
  dealCalc: any;
}

export const PrepaysControl = ({ dealCalc }: PrepaysControlProps) => {
  const theme = useTheme();

  const prepays: Prepay[] = useMemo(() => {
    const prepays = dealCalc?.saved_locked_data?.locked_deal?.prepay_rates ?? [];

    return prepays.map((row: any) => ({
      dealId: dealCalc.deal_application_thread_id,
      term: row.prepay_term,
      buyRate: row.buy_rate,
      commission: row.commission,
      points: row.commission_points,
      sellRate: row.sell_rate,
      totalRepayment: row.payback_amount,
    }));
  }, [
    dealCalc?.deal_application_thread_id,
    dealCalc?.saved_locked_data?.locked_deal?.prepay_rates,
  ]);

  const summary: string = useMemo(() => {
    const minBuyRate = minBy(prepays, 'buyRate') as { buyRate?: number };
    const maxBuyRate = maxBy(prepays, 'buyRate') as { buyRate?: number };
    const minTotalRepayment = minBy(prepays, 'totalRepayment') as { totalRepayment?: number };
    const maxTotalRepayment = maxBy(prepays, 'totalRepayment') as { totalRepayment?: number };

    if (prepays.length === 0) return '';

    return prepays.length > 0
      ? `Buy Rates: ${fShortenNumber(minBuyRate?.buyRate ?? 0)} to ${fShortenNumber(
          maxBuyRate.buyRate ?? 0
        )}, Total Repayments: ${fCurrencyShort(
          minTotalRepayment.totalRepayment ?? 0
        )} to ${fCurrencyShort(maxTotalRepayment.totalRepayment ?? 0)}`
      : '';
  }, [prepays]);

  if (prepays.length === 0) return null;

  return (
    <Stack spacing={1.5}>
      <Accordion TransitionProps={{ unmountOnExit: true }} id="prepays-panel-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="prepays-panel"
          id="prepays-panel-header"
          sx={{ backgroundColor: theme.palette.background.neutral }}
        >
          <Typography sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>Prepays</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{summary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PrepaysPanel prepays={prepays} />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export const OffersReceivedSelectionPanel = React.memo(
  (props: OffersReceivedSelectionPanelProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const offerProperties = useSelector(getOfferPropertiesSelector);
    const approvals = useSelector(getApprovalsSelector);
    const dealCalc = useSelector(getDealCalcSelector);
    const { deal, showPartnerControls = true } = props;

    const reviewData = React.useMemo(() => {
      if (!deal) return undefined;
      if (!dealCalc) return undefined;

      return createReviewData(deal, dealCalc);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dealCalc?.funder_id,
      dealCalc?.saved_deal_data?.control?.deal_id,
      dealCalc?.saved_deal_data?.control?.name_legal,
      dealCalc?.saved_deal_data?.control?.name_dba,
      dealCalc?.iso_name_id?.primary_email,
      deal,
    ]);

    const onOfferClick = useCallback(
      (index: number) => {
        dispatch(editOfferProperties({ selectedOfferIndex: index }));
      },
      [dispatch]
    );

    if (approvals?.length === 0) {
      return null;
    }

    return (
      <>
        <Box data-testid={'offers-received-selection'}>
          <Grid container pb={2}>
            <Grid item xs={12} sm={3} pr={2}>
              {!offerProperties.hasLockedOffer ? (
                <Stack
                  spacing={4}
                  direction="column"
                  sx={{
                    backgroundColor: theme.palette.background.neutral,
                    padding: 1,
                    borderRadius: '4px',
                  }}
                >
                  <RequestedAmountInput offerProperties={offerProperties} />
                  <CommissionSelect offerProperties={offerProperties} />
                  <RemittanceScheduleSelect offerProperties={offerProperties} />
                </Stack>
              ) : (
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{
                    backgroundColor: theme.palette.background.neutral,
                    padding: 1,
                    borderRadius: '4px',
                  }}
                >
                  <SelectedOfferControl
                    offerProperties={offerProperties}
                    selectedOffer={approvals[offerProperties.selectedOfferIndex]}
                  />
                  <SelectedOfferRemittanceSummaryControl
                    offerProperties={offerProperties}
                    selectedOffer={approvals[offerProperties.selectedOfferIndex]}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Stack direction={'row'} justifyContent={'space-between'} sx={{ display: 'flex' }}>
                {!offerProperties.hasLockedOffer ? (
                  <>
                    <FormHelperText sx={{ mt: 0, mb: 1 }}>
                      Click on a row to select an offer
                    </FormHelperText>
                    <PreapprovalNote note={dealCalc?.saved_deal_data?.preapproval_notes} />
                  </>
                ) : isUnlockAllowed({ deal, offerProperties }) && showPartnerControls ? (
                  <FormHelperText sx={{ mt: 0, mb: 1 }}>
                    Use the "Unlock Deal" button to re-enable selection of pre-approvals
                  </FormHelperText>
                ) : null}
              </Stack>
              {approvals?.map((approval, i) => (
                <PreapprovalRowItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${approval.term}-${approval.factorRate}-${i}`}
                  index={i}
                  approval={approval}
                  offerProperties={offerProperties}
                  onOfferClick={onOfferClick}
                />
              ))}
            </Grid>

            <Grid item xs={12} sm={3} pr={2}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={9}>
              {!offerProperties.hasLockedOffer ? (
                <AcceptOfferControlsPanel
                  selectedOffer={approvals[offerProperties.selectedOfferIndex]}
                  offerProperties={offerProperties}
                  reviewData={reviewData}
                  dealCalcData={dealCalc}
                />
              ) : (
                showPartnerControls && (
                  <Stack spacing={1.5}>
                    <PrepaysControl dealCalc={dealCalc} />
                    <ACHControl />
                  </Stack>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
);
