const featureFlagKeys = {
  customerDashboard: 'customerDashboard',
  customerApplications: 'customerApplications',
  customerUploadBankStatements: 'customerUploadBankStatements',
  registration: 'registration',
  isoRegistration: 'isoRegistration',
  underMaintenance: 'underMaintenance',
  useCodatLinking: 'useCodatLinking',
  useEmbeddedESIGN: 'useEmbeddedESIGN',
  useSubmitAndRegister: 'useSubmitAndRegister',
  useAutomatedCheckout: 'useAutomatedCheckout',
};

const ALLOW_REGISTRATION = process.env.REACT_APP_ALLOW_REGISTRATION || 'true';
const UNDER_MAINTENANCE = process.env.REACT_APP_UNDER_MAINTENANCE || 'false';

const featureFlags = new Map();
featureFlags.set('customerDashboard', true);
featureFlags.set('customerApplications', true);
featureFlags.set('customerUploadBankStatements', true);
featureFlags.set('useCodatLinking', true);
featureFlags.set('registration', true);
featureFlags.set('isoRegistration', ALLOW_REGISTRATION === 'true');
featureFlags.set('underMaintenance', UNDER_MAINTENANCE === 'true');
featureFlags.set('useEmbeddedESIGN', false);
featureFlags.set('useSubmitAndRegister', false);
featureFlags.set('useAutomatedCheckout', false);

const isFeatureEnabled = (flagName: string) => {
  // Note: This logic is to give us a way to locally override our feature
  // flag so that we can enable specific browsers to do testing.
  if (flagName === 'registration') {
    // Check for our easterEgg key and create it if it doesn't exist but
    // give it no value so that unless we explicitly set it, it does nothing.
    // This logic will bootstrap adding the key so that we can manually edit it.
    const keyExists = localStorage.getItem('registration');
    if (!keyExists) {
      localStorage.setItem('registration', '');
    }

    const allowRegistration = localStorage.getItem('registration');
    if (allowRegistration && allowRegistration === 'true') {
      return true;
    }
  }

  if (flagName === 'isoRegistration') {
    // Check for our easterEgg key and create it if it doesn't exist but
    // give it no value so that unless we explicitly set it, it does nothing.
    // This logic will bootstrap adding the key so that we can manually edit it.
    const keyExists = localStorage.getItem('isoRegistration');
    if (!keyExists) {
      localStorage.setItem('isoRegistration', '');
    }

    const allowISORegistration = localStorage.getItem('isoRegistration');
    if (allowISORegistration && allowISORegistration === 'true') {
      return true;
    }
  }

  if (flagName === 'useAutomatedCheckout') {
    // Check for our easterEgg key and create it if it doesn't exist but
    // give it no value so that unless we explicitly set it, it does nothing.
    // This logic will bootstrap adding the key so that we can manually edit it.
    const keyExists = localStorage.getItem('useAutomatedCheckout');
    if (!keyExists) {
      localStorage.setItem('useAutomatedCheckout', '');
    }

    const useAutomatedCheckout = localStorage.getItem('useAutomatedCheckout');
    if (useAutomatedCheckout && useAutomatedCheckout === 'true') {
      return true;
    }
  }

  if (!featureFlags.has(flagName)) return true;
  return featureFlags.get(flagName);
};

const updateFeatureFlag = (flagName: string, value: boolean) => {
  if (!featureFlags.has(flagName)) {
    featureFlags.set(flagName, value);
  }
};

const useFeatureFlags = () => ({ isFeatureEnabled, featureFlagKeys, updateFeatureFlag });

export default useFeatureFlags;
