import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  padding: theme.spacing(12, 2),
  paddingTop: theme.spacing(2),
}));

const ReviewAgreements = () => (
  <Grid container>
    <Typography
      variant="body2"
      gutterBottom
      sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
      component={'div'}
    >
      Certifications of Applicant and/or Guarantor
    </Typography>
    <Typography variant="body2" gutterBottom>
      In connection with this application for Revenue-Based Funding (“RBF”), the
      Owner(s)/Principal(s) stated in the above application have completed this application on
      behalf of the above listed business (“Business”) and themselves (collectively, the
      “Applicants”) and provided information regarding, among other things, the purpose of the RBF,
      income, assets and liabilities. Applicants represent, acknowledge, agree and certify that:
    </Typography>
    <Typography variant="body2" marginLeft={1} component={'div'}>
      <Typography variant="body2">
        (1) All information and documents provided in and in connection with this application is
        true, accurate and complete;
      </Typography>
      <Typography variant="body2">
        (2) Applicants shall immediately notify Libertas Funding, LLC (“Libertas”) of any change in
        financial condition or information provided in connection with this application;
      </Typography>
      <Typography variant="body2">(3) No material information has been omitted;</Typography>
      <Typography variant="body2">
        (4) Applicants authorize Libertas to obtain consumer credit reports, investigative reports,
        tax returns, financial statements and trade references in connection with this application
        and to verify all information contained in this application or obtained or provided in
        connection with this application;
      </Typography>
      <Typography variant="body2">
        (5) Applicants authorize Libertas to disclose all information and documents that Libertas
        may obtain to other persons or entities (collectively “Recipients”) that may be involved
        with performing credit and financial related analysis on behalf of Libertas, and/or make or
        acquire purchases of future receivables (including RBFs) or commercial term loans
        (collectively “Transactions”) and that each Recipient is authorized to use such information
        and documents and share such information and documents with other Recipients in connection
        with potential Transactions;
      </Typography>
      <Typography variant="body2">
        (6) Applicants waive and release any claims against Recipients and any information-providers
        arising from any act or omission relating to the requesting, releasing or receiving of
        information;
      </Typography>
      <Typography variant="body2">
        (7) Applicants further authorize Libertas and Recipients to reverify all information
        contained in this application and obtained or provided in connection with this application,
        from time to time, for any legal purpose, to the extent permitted by applicable law,
        including but not limited to the administration, review, servicing or collection of the
        application and/or account; extensions or renewals of the account, and offering the Business
        enhanced or additional products and services. Such information includes, but is not limited
        to, income, licensing, bank and similar account balances and deposits, statements from
        creditors and/or financial institutions and obtaining consumer credit reports, trade
        references, credit history, credit scores, investigative reports, copies of income tax
        returns and financial statements; and
      </Typography>
      <Typography variant="body2">
        (8) The individual(s) completing this application certifies and represents that s/he is
        authorized to sign on behalf of the Business and themselves, make these representations, and
        provide these consents.
      </Typography>
    </Typography>
  </Grid>
);

export const ReviewAgreementsPage = () => (
  <RootStyle>
    <ContentStyle>
      <ReviewAgreements />
    </ContentStyle>
  </RootStyle>
);

export default ReviewAgreements;
