import * as React from 'react';
import { Stack, Grid, Skeleton } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { getDealRequiredStipsSelector, getDealSelector } from 'src/redux/selectors/isoPortal';
import { LinkAccountsRequestButton } from '../iso-portal/components/LinkAccountsRequestButton';
import { StipulationsStatus } from '../iso-portal/components/StipulationsLabel';
import { ACHVerificationStatus } from '../iso-portal/components/ACHVerificationStatus';

export const ClosingControlsPanel = () => {
  const deal = useSelector(getDealSelector);
  const dealRequiredStips = useSelector(getDealRequiredStipsSelector);

  const stipsSummary = React.useMemo(
    () => ({
      count: dealRequiredStips?.length,
      uploadedCount: dealRequiredStips?.filter((stip) => stip.uploadName?.length > 0)?.length,
    }),
    [dealRequiredStips]
  );

  if (!deal) {
    return <Skeleton width={'100%'} height={'44px'} />;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1} pb={0}>
        <Grid item xs={12}>
          <LinkAccountsRequestButton />
        </Grid>
        <Grid item xs={12} pt={1}>
          <StipulationsStatus
            count={stipsSummary.count}
            uploadedCount={stipsSummary.uploadedCount}
            verbose={true}
          />
        </Grid>
        <Grid item xs={12} pt={1}>
          <ACHVerificationStatus />
        </Grid>
      </Grid>
    </Stack>
  );
};
