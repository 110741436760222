import { createContext, ReactNode, useEffect, useReducer, useMemo, useCallback } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { useDispatch as useStoreDispatch } from 'src/redux/hooks';
import { ActionMap, AuthState, AuthUser, AuthSignedLinkContextType } from 'src/@types/auth';
import { setSession } from '../utils/jwt';
import { getIsDealSignedLinkValid } from 'src/services/APIs/dealsApi';
import {
  getDeal,
  getDealCalc,
  getRequiredStips,
  getDocuments,
  getContract,
} from 'src/redux/slices/isoPortal';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

// eslint-disable-next-line no-unused-vars
enum Types {
  // eslint-disable-next-line no-unused-vars
  init = 'INITIALIZE',
  // eslint-disable-next-line no-unused-vars
  login = 'LOGIN',
  // eslint-disable-next-line no-unused-vars
  logout = 'LOGOUT',
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AuthSignedLinkContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

type SignedLinkParamsProps = {
  context?: string;
  dealId?: string;
  epk?: string;
  et?: string;
};

const displayMode = 'iso'; //partner

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search } = useLocation();
  const storeDispatch = useStoreDispatch();

  const doLogOut = useCallback(() => {
    localStorage.removeItem('loginContext');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('clientId');
    localStorage.removeItem('dealId');
    localStorage.removeItem('epk');
    localStorage.removeItem('et');
    dispatch({ type: Types.logout });
    setSession(null);
  }, [dispatch]);

  const { context, dealId, epk, et } = useMemo(() => {
    const queryParams = queryString.parse(search, {
      decode: false,
    }) as SignedLinkParamsProps;

    return {
      context: queryParams?.context ?? displayMode,
      dealId: queryParams?.dealId ?? localStorage.getItem('dealId') ?? '',
      epk: queryParams.epk ?? localStorage.getItem('epk') ?? '',
      et: queryParams.et ?? localStorage.getItem('et') ?? '',
    };
  }, [search]);

  useEffect(() => {
    if (!epk) return;

    // Use the epk signed by the node process as the bearer token
    setSession(epk);
  }, [epk]);

  const authValues = useMemo(() => {
    const doLogin = async () => {
      localStorage.setItem('loginContext', '');
      localStorage.setItem('userRoles', '');
      localStorage.setItem('clientId', '');

      if (!dealId) {
        dispatch({
          type: Types.init,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return;
      }

      localStorage.setItem('dealId', dealId);
      localStorage.setItem('epk', epk);
      localStorage.setItem('et', et);

      batch(async () => {
        // Note: Await the getDeal and getDealCalc because the subsequent calls may need to
        // access values from it via the store.
        await storeDispatch(getDeal({ dealId, epk, et }));
        await storeDispatch(getDealCalc({ dealId, epk, et }));
        storeDispatch(getRequiredStips({ dealId, epk, et }));
        storeDispatch(getContract({ dealId, epk, et }));
        storeDispatch(getDocuments({ dealId, epk, et }));
      });

      try {
        const linkIsValid = await getIsDealSignedLinkValid(dealId, epk, et);

        dispatch({
          type: Types.init,
          payload: {
            isAuthenticated: linkIsValid,
            user: null,
          },
        });
      } catch (error) {
        console.error('Validating Signed Link', error);
      }
    };

    return {
      ...state,
      method: 'signedLink',
      user: state.user,
      isAuthenticated: state.isAuthenticated,
      isInitialized: state.isInitialized,
      dealId,
      epk,
      et,
      context,
      login: doLogin,
      logout: doLogOut,
    };
  }, [state, dealId, epk, et, context, doLogOut, storeDispatch]);

  return <AuthContext.Provider value={authValues as any}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
