import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { HEADER, NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { ApplicantSummary } from 'src/portals/partner-portal/ApplicationSummary';
import { getDealSelector } from 'src/redux/selectors/isoPortal';
import MainFooter from '../customer-portal/MainFooter';
import RightControlsVertical from './RightControlsVertical';
import LeftControlsVertical from './LeftControlsVertical';
import ErrorLoadingDeal from 'src/portals/partner-portal/ErrorLoadingDeal';
import LoadingScreen from 'src/components/LoadingScreen';
import ISOHeader from './ISOHeader';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default function ISOLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const [open, setOpen] = useState(false);

  const deal = useSelector(getDealSelector);
  const isLoading = useSelector((state) => state.isoPortal.loadingDeal);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!deal) {
    return <ErrorLoadingDeal />;
  }

  return (
    <>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <ISOHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
        <LeftControlsVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}>
          <ApplicantSummary />
        </LeftControlsVertical>

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>

        <RightControlsVertical />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          marginLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px`,
        }}
      >
        <MainFooter />
      </Box>
    </>
  );
}
