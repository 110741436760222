import React from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormHelperText,
} from '@mui/material';
import { RequestContractProperties } from 'src/@types/request-contract-properties';
import { RenderFormikTextField } from 'src/utils/formikFormUtils';

type RequestContractDialogProps = {
  data: RequestContractProperties;
  onClose: () => void;
  handleRequestContract: (
    // eslint-disable-next-line no-unused-vars
    requestContractProperties?: RequestContractProperties
  ) => Promise<boolean>;
};

const RequestContractDialog: React.FC<RequestContractDialogProps> = ({
  data,
  onClose,
  handleRequestContract,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    note: Yup.string().optional(),
  });

  const onSubmit = async (values: RequestContractProperties) => {
    try {
      const requested = await handleRequestContract({ ...values });

      if (requested) {
        enqueueSnackbar('Contract request sent');
        onClose();
      }
    } catch (error) {
      enqueueSnackbar('Error requesting contract: Please try again!', { variant: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open
        onClose={onClose}
        fullWidth
        aria-labelledby="reqiest-contract-title"
        aria-describedby="reqiest-contract-description"
        data-testid={'zero-balance-letter-dialog'}
      >
        <DialogTitle id="reqiest-contract-title">Request Contract</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="reqiest-contract-description">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {RenderFormikTextField({
                  renderError: false,
                  helpers: { ...formik.getFieldProps('note') },
                  label: 'Note',
                  required: false,
                  autoComplete: 'off',
                  formik,
                })}
                <FormHelperText
                  error={false}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Optional note to be sent in the contract request
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            type={'submit'}
            disabled={!formik.isValid || formik.isSubmitting}
            sx={{ borderRadius: Number(theme.shape.borderRadius) * 2 }}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Send
          </Button>
          <Button
            variant={'outlined'}
            onClick={onClose}
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderRadius: Number(theme.shape.borderRadius) * 2,
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};

export default RequestContractDialog;
