import { AnyAction, CombinedState, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import mainApp, { MainAppState } from './slices/mainApp';
import admin, { AdminState } from './slices/admin';
import applications, { ApplicationsState } from './slices/applications';
import referenceData, { ReferenceDataState } from './slices/referenceData';
import fundedDeals, { FundedDealsState } from './slices/fundedDeals';
import editApplication, { EditApplicationState } from './slices/editApplication';
import support, { SupportState } from './slices/support';
import isoPortal, { ISOPortalState } from './slices/isoPortal';
import { merchantPortalInitialState } from './initialState';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const appReducer = combineReducers({
  mainApp: mainApp,
  admin: admin,
  applications: applications,
  referenceData: referenceData,
  fundedDeals: fundedDeals,
  editApplication: editApplication,
  support: support,
  isoPortal: isoPortal,
});

const rootReducer = (
  state:
    | CombinedState<{
        mainApp: MainAppState;
        admin: AdminState;
        applications: ApplicationsState;
        referenceData: ReferenceDataState;
        fundedDeals: FundedDealsState;
        editApplication: EditApplicationState;
        support: SupportState;
        isoPortal: ISOPortalState;
      }>
    | undefined,
  action: AnyAction
) => {
  // When the user logs out we need to clear out the redux persist
  // storage to eliminate any risk of data crossing tenants (unlikely
  // in a real world scenario).
  if (action.type === 'onUserLogout') {
    localStorage.setItem('clientId', '');
    // eslint-disable-next-line no-unused-vars
    storage.removeItem(rootPersistConfig.key).then((r) => {});
    return appReducer(
      {
        ...state,
        ...merchantPortalInitialState,
      },
      action
    );
  }

  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
