import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fCurrencyShort } from 'src/utils/formatNumber';
import {
  getEstimatedRemittances,
  getRemittancesPayment,
  generateRemittanceScheduleLabel,
} from 'src/utils/paymentUtils';
import { Approval } from 'src/@types/approval';

interface DetailedOfferItemProps {
  approval: Approval;
  amountSeeking: number | null;
  totalRepayment: string;
  totalCommission: string;
}

export const PartnerOfferRow = React.memo(
  ({ totalRepayment, ...props }: DetailedOfferItemProps) => {
    // ISO touched slider, recalculate values

    const theme = useTheme();

    const remittance = React.useMemo(
      () =>
        fCurrencyShort(
          getRemittancesPayment(
            props?.approval?.remittanceSchedule,
            props?.amountSeeking! * props?.approval?.factorRate,
            +props?.approval?.term
          )
        ),
      [
        props?.approval?.remittanceSchedule,
        props?.amountSeeking,
        props?.approval?.factorRate,
        props?.approval?.term,
      ]
    );

    const estimatedRemittances = React.useMemo(
      () =>
        props?.approval?.remittanceSchedule && props?.approval?.term
          ? getEstimatedRemittances(props?.approval?.remittanceSchedule, +props?.approval?.term)
          : 0,
      [props?.approval?.remittanceSchedule, props?.approval?.term]
    );

    const remittanceScheduleLabel = React.useMemo(
      () => generateRemittanceScheduleLabel(props?.approval?.remittanceSchedule),
      [props?.approval?.remittanceSchedule]
    );

    const factorRate = React.useMemo(
      () => Number(props?.approval?.factorRate).toFixed(2),
      [props?.approval?.factorRate]
    );

    const buyRate = React.useMemo(
      () => Number(props?.approval?.buyRate).toFixed(2),
      [props?.approval?.buyRate]
    );

    if (!props) {
      return <Skeleton width={'100%'} height={'60px'} />;
    }

    return (
      <>
        <Grid item xs={4} md={4} lg={4} xl={1}>
          <Typography variant="caption">Term</Typography>
          <Typography variant="h4">{props.approval.term}</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4} xl={2}>
          <Typography variant="caption">Buy Rate</Typography>
          <Typography variant="h4">{buyRate}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          xl={2}
          sx={{
            ml: -4,
            mr: 4,
            [theme.breakpoints.down('xl')]: {
              ml: 0,
              mr: 0,
            },
          }}
        >
          <Typography variant="caption">Factor Rate</Typography>
          <Typography variant="h4">{factorRate}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          xl={2}
          sx={{
            ml: -4,
            mr: 4,
            [theme.breakpoints.down('xl')]: {
              ml: 0,
              mr: 0,
            },
          }}
        >
          <Typography variant="caption" noWrap>
            {remittanceScheduleLabel}
          </Typography>
          <Typography variant="h4">{remittance}</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4} xl={2}>
          <Typography variant="caption">Est. Remittances</Typography>
          <Typography variant="h4">{estimatedRemittances}</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4} xl={3}>
          <Typography variant="caption">Total Remittance Amount</Typography>
          <Typography variant="h4">{totalRepayment}</Typography>
        </Grid>
      </>
    );
  }
);
