import React from 'react';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'src/redux/hooks';
import { editApprovals, editOfferProperties } from 'src/redux/slices/isoPortal';
import {
  RemittanceScheduleTypeDisplay,
  RemittanceScheduleTypes,
} from 'src/@types/remittance-schedule-types';
import { OfferProperties } from 'src/@types/offer-properties';
import { getApprovalsSelector } from 'src/redux/selectors/isoPortal';
import { Approval } from 'src/@types/approval';

interface RemittanceScheduleSelectProps {
  offerProperties: OfferProperties;
}

export const RemittanceScheduleSelect = (props: RemittanceScheduleSelectProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const approvals = useSelector(getApprovalsSelector);

  const onRemittanceScheduleChange = (event: SelectChangeEvent<string>) => {
    const changedApprovals = approvals.map((approval: Approval) => ({
      ...approval,
      remittanceSchedule: event.target.value,
    }));
    dispatch(editApprovals(changedApprovals));
    dispatch(editOfferProperties({ remittanceSchedule: event.target.value }));
  };

  return (
    <div>
      <Typography variant="caption" pt={1} mb={1}>
        Remittance Schedule
      </Typography>
      <Select
        color="primary"
        value={props.offerProperties.remittanceSchedule}
        onChange={onRemittanceScheduleChange}
        aria-label="Remittance schedule"
        fullWidth
        disabled={props.offerProperties.hasLockedOffer}
        sx={{
          '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        {props.offerProperties.allowedRemittanceSchedules.map((scheduleType) => (
          <MenuItem key={scheduleType} value={scheduleType}>
            {RemittanceScheduleTypeDisplay(scheduleType as RemittanceScheduleTypes)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
