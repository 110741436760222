import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { LogoWithName } from 'src/components/Logo';

export const LINKS = [
  {
    headline: 'Libertas',
    children: [{ name: 'About us', href: 'https://www.libertasfunding.com/about-us/' }],
  },
  {
    headline: 'Legal',
    children: [
      {
        name: 'Terms and Conditions',
        href: 'https://www.libertasfunding.com/wp-content/uploads/2022/03/AP14-Website-Terms-of-Use-Clarizen-Approved-3.2.22.pdf',
      },
      {
        name: 'Privacy Policy',
        href: 'https://www.libertasfunding.com/wp-content/uploads/2022/03/PV4-Online-Privacy-Policy-Clarizen-Approved-3.2.22.pdf',
      },
      {
        name: 'CA Privacy Notice',
        href: 'https://www.libertasfunding.com/wp-content/uploads/2022/03/PV5-California-Privacy-Notice-Clarizen-Approved-3.2.22.pdf',
      },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'Contact us', href: 'https://www.libertasfunding.com/contact-us/' }],
  },
];

export const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function MainFooter() {
  return (
    <footer>
      <RootStyle>
        <Divider />
        <Container sx={{ pt: 2 }}>
          <Grid
            container
            justifyContent={{ xs: 'center', md: 'space-between' }}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LogoWithName sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </Grid>
            <Grid item xs={8} md={3}>
              <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                Where Relationships Are An Asset
              </Typography>
            </Grid>

            <Grid item xs={12} md={7}>
              <Stack
                spacing={1}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
              >
                {LINKS.map((list) => (
                  <Stack key={list.headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {list.headline}
                    </Typography>
                    {list.children.map((link) => (
                      <Link
                        href={link.href}
                        key={link.name}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                        variant="body2"
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>

          <Typography
            component="p"
            variant="body2"
            sx={{
              mt: 2,
              pb: 5,
              fontSize: 13,
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            © 2023. All rights reserved
          </Typography>
        </Container>
      </RootStyle>
    </footer>
  );
}
