import { omitBy, isUndefined, isNull } from 'lodash';
import { Approval } from 'src/@types/approval';
import { OwnerInfo, ReviewData, Application } from 'src/@types/application-types';
import { LockedDealDetails } from 'src/@types/lock-deal-data';
import { DealApplication } from 'src/@types/mcafunds/deal-application';
import { RemittanceScheduleTypes } from 'src/@types/remittance-schedule-types';
import { getRemittancesPayment } from 'src/utils/paymentUtils';
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
import { isRegulatedOffer } from 'src/utils/stateRegulations';

const stripStringFormat = (str: string) => Number(str.replace(/[^0-9.-]+/g, ''));

const useMaxFunded = false;

// TODO: I think we want to move all of this to the MP-Svc and then have the MP-Svc
// return the types the MP-SPA currently knows about.
// That should enable us to make targeted refactoring so that the API methods that pull
// from the MP-Svc can return payload the MP-SPA can just use.

export const defaultRemittanceSchedules = [
  RemittanceScheduleTypes.Daily,
  RemittanceScheduleTypes.Weekly,
];

// TODO: Move to MP-Svc
export const createApprovalDataFromDealCalc = (saved_deal_data: any) => {
  if (!saved_deal_data) return [];

  const approvalData =
    saved_deal_data?.selected_approvals?.rows?.map((approval: any) => {
      const maxFunded = stripStringFormat(approval.max_funded);
      const maximumAdvance = useMaxFunded
        ? Math.max(maxFunded, approval.funded_amount_raw)
        : approval.funded_amount_raw;
      return {
        term: approval.term,
        factorRate: approval.sell_rate,
        paymentType: approval.payment_type,
        remittanceSchedule: approval.payment_type,
        maximumAdvance: maximumAdvance,
        maximumCommission: approval.commission,
        buyRate: approval.buy_rate,
        commission: approval?.commission,
        fee: approval.fee_raw,
        feePercent: approval.fee_percent,
        fundedAmountRaw: approval.funded_amount_raw,
        selectable: true,
      };
    }) ?? [];

  return approvalData as Approval[];
};

// TODO: Move to MP-Svc
export const createReviewDataFromDealCalc = (application: Application, dealCalculatorData: any) => {
  if (!dealCalculatorData) return undefined;

  return {
    funderId: +dealCalculatorData?.funder_id,
    dealId: +dealCalculatorData?.saved_deal_data?.control?.deal_id,
    paymentType: dealCalculatorData?.saved_deal_data?.control?.payment_type,
    businessNameLegal: dealCalculatorData?.saved_deal_data?.control?.name_legal,
    businessNameDba: dealCalculatorData?.saved_deal_data?.control?.name_dba,
    isos: dealCalculatorData?.iso_name_id?.primary_email,
    owners: application?.applicant?.owners
      ?.filter((owner: OwnerInfo) => !!owner.emailAddress)
      ?.map((owner: OwnerInfo) => owner?.emailAddress?.trim())
      ?.join(', '),
  } as ReviewData;
};

// TODO: Move to MP-Svc
export const createReviewData = (deal: DealApplication, dealCalculatorData: any) => {
  if (!dealCalculatorData) return undefined;

  return {
    funderId: +dealCalculatorData?.funder_id,
    dealId: +dealCalculatorData?.saved_deal_data?.control?.deal_id,
    paymentType: dealCalculatorData?.saved_deal_data?.control?.payment_type,
    businessNameLegal: dealCalculatorData?.saved_deal_data?.control?.name_legal,
    businessNameDba: dealCalculatorData?.saved_deal_data?.control?.name_dba,
    isos: dealCalculatorData?.iso_name_id?.primary_email,
    // owners: application?.applicant?.owners
    //   ?.filter((owner: OwnerInfo) => !!owner.emailAddress)
    //   ?.map((owner: OwnerInfo) => owner?.emailAddress?.trim())
    //   ?.join(', '),
  } as ReviewData;
};

// TODO: Move to MP-Svc
// - when the MP-SPA call the MP-Svc for MCAFunds we can do these translations there and
// then call into MCAFunds
interface UpdateDealCalcDataProps {
  dealCalcData: any;
  selectedOfferIndex: number;
  amountSeeking: number;
  selectedOffer: Approval;
  totalRemittances: number;
  estimatedTotalRemittance: number;
  sendCustomerData?: ReviewData;
}
export const createUpdatedDealCalcData = (props: UpdateDealCalcDataProps) => {
  const {
    dealCalcData,
    selectedOfferIndex,
    amountSeeking,
    selectedOffer,
    totalRemittances,
    estimatedTotalRemittance,
    sendCustomerData,
  } = props;

  let locked_deal_has_prepays = false;
  const selected_indexes: number[] = [];

  const edited_rows = dealCalcData.saved_deal_data.selected_approvals.rows.map(
    (row: any, index: number) => {
      if (index !== selectedOfferIndex) return row;

      if (row.max_prepay !== null && row.max_prepay > 0) {
        locked_deal_has_prepays = true;
        // auto-select the prepays by term (month) up to the max prepay term
        for (let i = 0; i < row.max_prepay; i++) {
          selected_indexes.push(i);
        }
      }

      const weekly = getRemittancesPayment(
        'weekly',
        amountSeeking * selectedOffer.factorRate,
        +selectedOffer.term
      );
      const daily = getRemittancesPayment(
        'daily',
        amountSeeking * selectedOffer.factorRate,
        +selectedOffer.term
      );
      const bi_weekly = getRemittancesPayment(
        'bi_weekly',
        amountSeeking * selectedOffer.factorRate,
        +selectedOffer.term
      );
      const monthly = getRemittancesPayment(
        'monthly',
        amountSeeking * selectedOffer.factorRate,
        +selectedOffer.term
      );

      return {
        ...row,
        amount_of_advance: amountSeeking,
        daily_cash: daily,
        commission: selectedOffer.commission,
        commission_points: fShortenNumber((selectedOffer.commission ?? 0) * 100, false),
        buy_rate_delta: row.buy_rate - selectedOffer.factorRate,
        payment_type: selectedOffer.remittanceSchedule,
        funded_amount: fCurrency(amountSeeking),
        funded_amount_raw: amountSeeking,
        daily,
        daily_payment: fCurrency(daily),
        weekly,
        weekly_payment: fCurrency(weekly),
        bi_weekly,
        bi_weekly_payment: fCurrency(bi_weekly),
        monthly,
        monthly_payment: fCurrency(monthly),
        fee:
          amountSeeking !== selectedOffer.fundedAmountRaw
            ? fCurrency((selectedOffer.feePercent ?? 0) * amountSeeking)
            : fCurrency(selectedOffer.fee),
        fee_raw:
          amountSeeking !== selectedOffer.fundedAmountRaw
            ? (selectedOffer.feePercent ?? 0) * amountSeeking
            : selectedOffer.fee,
        amount_to_merchant: fCurrency(
          selectedOffer?.fee && selectedOffer.fee > 0
            ? amountSeeking - selectedOffer.fee
            : amountSeeking
        ),
        sell_rate: selectedOffer.factorRate,
        repay_numbers: `${totalRemittances}`,
        payback_amount: fCurrency(estimatedTotalRemittance),
      };
    }
  );

  const lockedDeal = edited_rows[selectedOfferIndex];

  const updatedDealCalcData = {
    ...dealCalcData.saved_deal_data,
    version: dealCalcData.version,
    deal_application_thread_id: dealCalcData.deal_application_thread_id,
    funder_id: dealCalcData.funder_id,
    locked_deal_has_prepays,
    selected_prepays: {
      ...dealCalcData.saved_deal_data.selected_prepays,
      rows: lockedDeal.prepay_rates,
      selected_indexes,
    },
    selected_approvals: {
      ...dealCalcData.saved_deal_data.selected_approvals,
      rows: edited_rows,
      selected_indexes: [selectedOfferIndex],
    },
    locked_deal: {
      ...dealCalcData.saved_deal_data.locked_deal,
      ...lockedDeal,
    },
    sendCustomerData: sendCustomerData
      ? omitBy(sendCustomerData, (v) => isUndefined(v) || isNull(v) || v === '')
      : null,
  };

  return updatedDealCalcData;
};

export const getMaximumOverallApproval = (approvals: Approval[]) => {
  const fundingAmounts = approvals.map((a: any) => a.maximumAdvance);
  return Math.max(...fundingAmounts);
};

export const getAllowedRemittanceSchedules = (
  amountSeeking: number,
  locationState: string,
  remittanceScheduleFromDeal: RemittanceScheduleTypes,
  approvals: Approval[],
  // eslint-disable-next-line no-unused-vars
  isProd: boolean
) => {
  const remittanceSchedulesOnApprovals = approvals.map((approval) => approval.remittanceSchedule);

  if (
    remittanceScheduleFromDeal === RemittanceScheduleTypes.Monthly ||
    remittanceSchedulesOnApprovals.includes(RemittanceScheduleTypes.Monthly)
  ) {
    return defaultRemittanceSchedules.concat([
      RemittanceScheduleTypes.BiWeekly,
      RemittanceScheduleTypes.Monthly,
    ]);
  }

  if (
    remittanceScheduleFromDeal === RemittanceScheduleTypes.BiWeekly ||
    remittanceSchedulesOnApprovals.includes(RemittanceScheduleTypes.BiWeekly)
  ) {
    return defaultRemittanceSchedules.concat([RemittanceScheduleTypes.BiWeekly]);
  }

  return defaultRemittanceSchedules;
};

export const getRemittanceFromLockedDeal = (lockedDeal: LockedDealDetails) => {
  switch (lockedDeal.paymentType) {
    case 'daily':
      return lockedDeal.dailyPayment;
    case 'weekly':
      return lockedDeal.weeklyPayment;
    case 'bi_weekly':
      return lockedDeal.biWeeklyPayment;
    case 'monthly':
      return lockedDeal.monthlyPayment;
    default:
      return -1;
  }
};

// dealCalcData?.saved_locked_data?.locked_deal
export const getLockedDealDetails = (locked_deal_data: any, locationState: string) => {
  const lockedDeal: LockedDealDetails = {
    fundedAmount: locked_deal_data.funded_amount_raw,
    amountToMerchant: locked_deal_data.funded_amount_raw - locked_deal_data.fee_raw,
    paybackAmount: locked_deal_data.payback_amount,
    dailyPayment: locked_deal_data.daily,
    weeklyPayment: locked_deal_data.weekly,
    biWeeklyPayment: locked_deal_data.bi_weekly,
    monthlyPayment: locked_deal_data.monthly,
    paymentType: locked_deal_data.payment_type,
    term: locked_deal_data.term,
    fee: locked_deal_data.fee_raw,
    apr: locked_deal_data.apr,
    locationState,
    isRegulatedOffer: isRegulatedOffer(locationState, locked_deal_data.funded_amount_raw),
  };
  lockedDeal.remittance = getRemittanceFromLockedDeal(lockedDeal);

  return lockedDeal;
};

export const hasPrepays = (dealCalc: any) =>
  dealCalc?.saved_locked_data.locked_deal?.prepay_rates?.length > 0;
