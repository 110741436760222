import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, Grid, Skeleton, Button, FormHelperText } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import {
  getDealSelector,
  getOfferPropertiesSelector,
  getDealDocumentsSelector,
  getRequestContractPropertiesSelector,
} from 'src/redux/selectors/isoPortal';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { useSignedLinkAuth } from 'src/hooks';
import { RequestContractDialog } from 'src/portals/components/dialogs';
import { RequestContractProperties } from 'src/@types/request-contract-properties';

export const AcceptedOfferControlsPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { epk, et } = useSignedLinkAuth();
  const deal = useSelector(getDealSelector);
  const offerProperties = useSelector(getOfferPropertiesSelector);
  const dealDocuments = useSelector(getDealDocumentsSelector);
  const requestContractProperties = useSelector(getRequestContractPropertiesSelector);
  const [requestContractVisible, setRequestContractVisible] = useState<boolean>(false);

  if (!deal) {
    return <Skeleton width={'100%'} height={'44px'} />;
  }

  const handleOpenRequestContractDialog = () => setRequestContractVisible(true);

  const handleCloseDialog = () => setRequestContractVisible(false);

  const handleRequestContract = async (requestContractProperties?: RequestContractProperties) => {
    try {
      if (!deal?.dealId) return false;

      await dealsApi.postRequestContract({
        dealId: deal.dealId,
        note: requestContractProperties?.note,
        epk,
        et,
      });
      enqueueSnackbar('Contract request sent');
      return true;
    } catch (error) {
      enqueueSnackbar('Error while trying to Request Contract', { variant: 'error' });
      return false;
    } finally {
    }
  };

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1} pb={0}>
        <Grid item xs={12}>
          {requestContractVisible && (
            <RequestContractDialog
              data={requestContractProperties}
              onClose={handleCloseDialog}
              handleRequestContract={handleRequestContract}
            />
          )}
          <Button
            variant="contained"
            onClick={handleOpenRequestContractDialog}
            disabled={!offerProperties.hasLockedOffer}
            fullWidth={true}
          >
            {dealDocuments.contract?.fileUrl ? 'Request New Contract' : 'Request Contract'}
          </Button>
          {!offerProperties.hasLockedOffer && (
            <FormHelperText>
              *Contracts can't be initiated until an offer is accepted
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
