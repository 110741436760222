import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import MainLayout, { SupportLayout } from 'src/layouts/app';
import DashboardLayout from 'src/layouts/components/dashboard';
import LogoOnlyLayout from 'src/layouts/components/LogoOnlyLayout';
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import SignedLinkGuard from 'src/guards/SignedLinkGuard';
import { PATH_AFTER_LOGIN } from 'src/config';
import LoadingScreen from 'src/components/LoadingScreen';
import RegisterISO from 'src/pages/auth/RegisterISO';
import { ESignAgreementPage } from 'src/portals/components/compliance/ESignAgreement';
import OffersLayout from 'src/layouts/customer-portal';
import ISOLayout from 'src/layouts/iso-portal';
import {
  DocumentsPage,
  ApplicationPage,
  StatusPage,
  StipulationsPage,
} from 'src/portals/partner-portal';
import RegisterCustomer from 'src/pages/auth/RegisterCustomer';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/app') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'register-success', element: <RegistrationSuccess /> },
        { path: 'verify', element: <VerifyEmail /> },
        {
          path: 'register-iso',
          element: (
            <GuestGuard>
              <RegisterISO />
            </GuestGuard>
          ),
        },
        { path: 'register-iso-success', element: <RegistrationSuccessISO /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
        // Note: This dashboard route serves to centralize the PATH_AFTER_LOGIN landing
        // route that is role aware. After the login the specific dashboard pages are
        // utilized for primary navigation.
        // Impl Note: As we add more routes we need to add the component map to the
        // AppDashboard component.
        {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <AppDashboard />
            </AuthGuard>
          ),
        },
        {
          path: 'application',
          children: [
            {
              path: 'wizard',
              element: <ApplicationWizard />,
            },
            {
              path: 'form',
              element: <ApplicationForm />,
            },
          ],
        },
        {
          path: 'mpapp',
          children: [
            {
              path: 'dashboard',
              element: (
                <AuthGuard>
                  <AppDashboard />
                </AuthGuard>
              ),
            },
            {
              path: 'apply-mca',
              element: (
                <AuthGuard>
                  <MCAApplyNowPage />
                </AuthGuard>
              ),
            },
            {
              path: 'funded-deals',
              element: (
                <AuthGuard>
                  <FundedDeals />
                </AuthGuard>
              ),
            },
            {
              path: 'applications',
              element: (
                <AuthGuard>
                  <Applications />
                </AuthGuard>
              ),
            },
            {
              path: 'application',
              children: [
                {
                  path: 'wizard',
                  element: (
                    <AuthGuard>
                      <ApplicationWizard />
                    </AuthGuard>
                  ),
                },
                {
                  path: 'form',
                  element: (
                    <AuthGuard>
                      <ApplicationForm />
                    </AuthGuard>
                  ),
                },
                {
                  path: 'offer-calc',
                  element: (
                    <AuthGuard>
                      <OfferCalculator />
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'isoapp',
          children: [
            {
              path: 'dashboard',
              element: (
                <AuthGuard>
                  <ISODashboard />
                </AuthGuard>
              ),
            },
            // {
            //   path: 'funded-deals',
            //   element: (
            //     <AuthGuard>
            //       <FundedDeals />
            //     </AuthGuard>
            //   ),
            // },
            {
              path: 'applications',
              element: (
                <AuthGuard>
                  <ISOApplications />
                </AuthGuard>
              ),
            },
            {
              path: 'application',
              children: [
                {
                  path: 'form',
                  element: (
                    <AuthGuard>
                      <ISOApplicationForm />
                    </AuthGuard>
                  ),
                },
                {
                  path: 'offer-calc',
                  element: (
                    <AuthGuard>
                      <ISOOfferCalculator />
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'list', element: <UserList /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: 'submission-success', element: <SubmissionSuccess /> },

        //Libertas Support Roues
        {
          path: 'support',
          children: [
            {
              path: 'dashboard',
              element: (
                <AuthGuard>
                  <SupportDashboard />
                </AuthGuard>
              ),
            },
            {
              path: 'applications',
              element: (
                <AuthGuard>
                  <ApplicationsList />
                </AuthGuard>
              ),
            },
            {
              path: 'administration',
              element: (
                <AuthGuard>
                  <ClientAccounts />
                </AuthGuard>
              ),
            },
            {
              path: 'fundedClients',
              element: <div>Funded Clients Will Go Here</div>,
            },
            {
              path: 'Clients',
              element: (
                <AuthGuard>
                  <ClientsList />
                </AuthGuard>
              ),
            },
            {
              path: 'Users',
              element: (
                <AuthGuard>
                  <UsersList />
                </AuthGuard>
              ),
            },
            { path: 'user/list', element: <SupportUsersList /> },
            { path: 'user/new', element: <SupportUserCreate /> },
          ],
        },
      ],
    },
    {
      path: 'offers',
      element: (
        <SignedLinkGuard>
          <OffersLayout />
        </SignedLinkGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        { path: 'customer', element: <Offers displayMode={'customer'} />, index: true },
        { path: 'iso', element: <Offers displayMode={'iso'} />, index: true },
      ],
    },
    {
      path: 'partner',
      element: (
        <SignedLinkGuard>
          <ISOLayout />
        </SignedLinkGuard>
      ),
      children: [
        { element: <StatusPage />, index: true },
        { path: 'status', element: <StatusPage />, index: true },
        { path: 'application', element: <ApplicationPage />, index: true },
        { path: 'stipulations', element: <StipulationsPage />, index: true },
        { path: 'documents', element: <DocumentsPage />, index: true },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '403Offers', element: <Page403Offers /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'apply-mca', element: <MCAApplyNowPage />, index: true },
        { path: 'esign-agreement', element: <ESignAgreementPage />, index: true },
        { path: 'data-linking', element: <FinancialLinking />, index: true },
      ],
    },
    {
      path: '/customers',
      element: <MainLayout />,
      children: [{ element: <RegisterCustomer partnerMode={false} />, index: true }],
    },
    {
      path: '/partners',
      element: <MainLayout />,
      children: [{ element: <RegisterCustomer partnerMode={true} />, index: true }],
    },
    {
      path: '/support',
      element: <SupportLayout />,
      children: [{ element: <SupportHome />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Register = Loadable(lazy(() => import('src/pages/auth/RegisterCustomer')));
const RegistrationSuccess = Loadable(lazy(() => import('src/pages/auth/RegistrationSuccess')));
const RegistrationSuccessISO = Loadable(
  lazy(() => import('src/pages/auth/RegistrationSuccessISO'))
);
const VerifyEmail = Loadable(lazy(() => import('src/pages/auth/VerifyEmail')));

// DASHBOARD
const UserProfile = Loadable(lazy(() => import('src/portals/components/user/UserProfile')));
const UserList = Loadable(lazy(() => import('src/portals/components/user/UserList')));
const UserAccount = Loadable(lazy(() => import('src/portals/components/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('src/portals/components/user/UserCreate')));

// Customer "Merchant" Portal
const AppDashboard = Loadable(lazy(() => import('src/portals/PortalDashboard')));
const Applications = Loadable(
  lazy(() => import('src/portals/customer-portal/merchant-dashboard/Applications'))
);
const ApplicationWizard = Loadable(
  lazy(() => import('src/portals/components/application-flow/ApplicationWizard'))
);
const ApplicationForm = Loadable(
  lazy(() => import('src/portals/components/application-flow/ApplicationForm'))
);
const OfferCalculator = Loadable(
  lazy(() => import('src/portals/customer-portal/merchant-dashboard/OfferCalculator'))
);
const FundedDeals = Loadable(
  lazy(() => import('src/portals/customer-portal/funded-deals/FundedDealsDashboard'))
);

// ISO Portal
const ISODashboard = Loadable(lazy(() => import('src/portals/iso-portal/dashboard/ISODashboard')));
const ISOApplications = Loadable(
  lazy(() => import('src/portals/iso-portal/applications/ISOApplications'))
);
const ISOApplicationForm = Loadable(
  lazy(() => import('src/portals/iso-portal/applications/ISOApplicationForm'))
);
const ISOOfferCalculator = Loadable(
  lazy(() => import('src/portals/iso-portal/applications/ISOOfferCalculator'))
);

// Support Portal
const SupportDashboard = Loadable(
  lazy(() => import('src/portals/support-portal/dashboard/SupportDashboard'))
);
const ApplicationsList = Loadable(
  lazy(() => import('src/portals/support-portal/administration/applications/ApplicationsList'))
);
const ClientAccounts = Loadable(
  lazy(() => import('src/portals/support-portal/administration/Administration'))
);
const ClientsList = Loadable(
  lazy(() => import('src/portals/support-portal/administration/clients/ClientsList'))
);
const UsersList = Loadable(
  lazy(() => import('src/portals/support-portal/administration/users/UserList'))
);
const SupportUserCreate = Loadable(
  lazy(() => import('src/portals/support-portal/administration/users/UserCreate'))
);
const SupportUsersList = Loadable(
  lazy(() => import('src/portals/support-portal/administration/users/UserList'))
);

const Offers = Loadable(lazy(() => import('src/portals/components/offers/Offers')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('src/pages/dashboard/PermissionDenied')));

const FinancialLinking = Loadable(lazy(() => import('src/pages/DataLinkHome')));

// MAIN
const MCAApplyNowPage = Loadable(lazy(() => import('../pages/dashboard/merchantportal/ApplyNow')));
const HomePage = Loadable(lazy(() => import('src/pages/Home')));
const SupportHome = Loadable(lazy(() => import('src/pages/SupportHome')));
const Maintenance = Loadable(lazy(() => import('src/pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const Page403 = Loadable(lazy(() => import('src/pages/Page403')));
const Page403Offers = Loadable(lazy(() => import('src/pages/Page403Offers')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));

const SubmissionSuccess = Loadable(
  lazy(() => import('src/portals/components/application-flow/SubmissionSuccessPage'))
);
