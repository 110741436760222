import * as React from 'react';
import { useSnackbar } from 'notistack';
import { Stack, Grid, Skeleton, Button } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { getDealSelector } from 'src/redux/selectors/isoPortal';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { useSignedLinkAuth } from 'src/hooks';
import { useMemo } from 'react';

export const FundingControlsPanel = () => {
  const deal = useSelector(getDealSelector);
  const { dealId, epk, et } = useSignedLinkAuth();
  const { enqueueSnackbar } = useSnackbar();

  const canRequestFunding = useMemo(
    () =>
      (deal?.state === 'closing' && deal?.subState === 'clear_to_close') ||
      (deal?.state === 'clear_to_close' && deal?.subState === 'ready_to_fund') ||
      deal?.state === 'Approved',
    [deal?.state, deal?.subState]
  );

  const handleRequestFunding = async () => {
    try {
      if (!dealId) return false;

      const result = await dealsApi.postRunFunding({
        dealId,
        epk,
        et,
      });
      if (result?.result) {
        enqueueSnackbar('Funding request sent');
      } else {
        if (result?.messages?.length > 0) {
          enqueueSnackbar(result.messages[0], { variant: 'error' });
        } else {
          enqueueSnackbar('Error while requesting funding. Please try again!', {
            variant: 'error',
          });
        }
      }

      return true;
    } catch (error) {
      enqueueSnackbar('Error while trying to Request Funding', { variant: 'error' });
      return false;
    }
  };

  if (!deal) {
    return <Skeleton width={'100%'} height={'44px'} />;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1} pb={0}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleRequestFunding}
            fullWidth={true}
            disabled={!canRequestFunding}
          >
            Request Funding
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
