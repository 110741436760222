import { useMemo } from 'react';
import { Stack, Typography, Skeleton } from '@mui/material';
import Label from 'src/components/Label';
import { useSelector } from 'src/redux/hooks';

export const ACHVerificationStatusLabel = () => {
  const contract = useSelector((state) => state.isoPortal.contract);

  const wasProvided = useMemo(
    () =>
      contract?.bank?.name?.length > 0 ||
      contract?.bank?.account_name?.length > 0 ||
      contract?.bank?.account_number?.length > 0 ||
      contract?.bank?.routing_number?.length > 0,
    [
      contract?.bank?.name,
      contract?.bank?.account_name,
      contract?.bank?.account_number,
      contract?.bank?.routing_number,
    ]
  );

  const isVerified = useMemo(
    () => contract?.bank?.ach_saved_locked_info,
    [contract?.bank?.ach_saved_locked_info]
  );

  if (!contract) {
    if (!isVerified) return <Label color="warning">Unverified</Label>;
    return <Skeleton variant="rectangular" width={60} />;
  }

  if (!wasProvided) {
    return <Label color="error">Not Provided</Label>;
  }

  if (isVerified) {
    return <Label color="success">Verified</Label>;
  }

  return <Label color="warning">Unverified</Label>;
};

export const ACHVerificationStatus = () => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="body1">ACH Verification:</Typography>
    <ACHVerificationStatusLabel />
  </Stack>
);
