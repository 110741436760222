import React from 'react';
import { m } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Button } from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: theme.spacing(0),
}));

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 100,
  right: 0,
  bottom: 0,
  width: '100%',
  margin: 'auto',
  alignContent: 'center',
  height: '250px',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

export default function VerifyEmail() {
  const theme = useTheme();
  const { logout, login } = useAuth();

  const retryLogin = async () => {
    try {
      // Close the prior login session that is in error and restart the process
      logout();
      await login('', '', 'customer');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Libertas Customer Portal Email Verification">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ mt: { md: -4 } }}>
            <Logo />
          </Box>
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <HeroImgStyle alt="hero" src="/assets/Libertas.Site.Graphic.png" />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              alignContent="center"
              sx={{ textAlign: 'center', alignItems: 'center' }}
            >
              Your email has not be verified. Please check your inbox for an email with a link to
              verify your account.
            </Stack>
            <br />
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={retryLogin}
                sx={{
                  borderRadius: Number(theme.shape.borderRadius) * 2,
                }}
              >
                Log in
              </Button>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
