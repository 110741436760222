import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CapitalPurpose,
  Industry,
  LegalEntityType,
  State,
  sortStates,
  sortIndustries,
} from 'src/@types/reference-data';
import * as referenceDataAPI from 'src/services/APIs/referenceDataAPI';

export type ReferenceDataState = {
  isLoading: boolean;
  error: Error | string | null;
  capitalPurposes: CapitalPurpose[];
  industries: Industry[];
  legalEntityTypes: LegalEntityType[];
  states: State[];
};

export const initialState: ReferenceDataState = {
  isLoading: false,
  error: null,
  capitalPurposes: [],
  industries: [],
  legalEntityTypes: [],
  states: [],
};

const slice = createSlice({
  name: 'ReferenceData',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Data
    getReferenceDataSuccess(state, action) {
      state.isLoading = false;
      state.capitalPurposes = action.payload.capitalPurposes;
      state.industries = action.payload.industries;
      state.legalEntityTypes = action.payload.legalEntityTypes;
      state.states = action.payload.states;
    },

    editApplicationData(state, action) {
      state.capitalPurposes = action.payload.capitalPurposes;
      state.industries = action.payload.industries;
      state.legalEntityTypes = action.payload.legalEntityTypes;
      state.states = action.payload.states;
    },

    resetApplicationData(state) {
      state.capitalPurposes = [];
      state.industries = [];
      state.legalEntityTypes = [];
      state.states = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetApplicationData, editApplicationData } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const getReferenceData = createAsyncThunk(
  'getReferenceData',
  async (_, { dispatch, getState }) => {
    // @ts-ignore
    const { capitalPurposes, industries, legalEntityTypes, states } = getState().referenceData;

    // These lists will not change much. Use the cached lists.
    if (
      capitalPurposes.length > 0 &&
      industries.length > 0 &&
      legalEntityTypes.length > 0 &&
      states.length > 0
    )
      return;

    dispatch(slice.actions.startLoading());
    try {
      const capitalPurposeResponse: { data: { data: CapitalPurpose[] } } =
        await referenceDataAPI.fetchCapitalPurposes();
      const industries: { data: { data: Industry[] } } = await referenceDataAPI.fetchIndustries();
      const legalEntityTypesResponse: { data: { data: LegalEntityType[] } } =
        await referenceDataAPI.fetchLegalEntityTypes();
      const stateResponse: { data: { data: State[] } } = await referenceDataAPI.fetchStates();
      dispatch(
        slice.actions.getReferenceDataSuccess({
          capitalPurposes: capitalPurposeResponse.data.data,
          industries: sortIndustries(industries.data.data),
          legalEntityTypes: legalEntityTypesResponse.data.data,
          states: sortStates(stateResponse.data.data),
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);
