import { ReactNode } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import cssStyles from 'src/utils/cssStyles';
import { NAVBAR } from 'src/config';
import Scrollbar from 'src/components/Scrollbar';
import { DealApplicationProgressVertical } from 'src/portals/iso-portal/components/DealApplicationProgress';

const CONTROLS_WIDTH = 350;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  children?: ReactNode;
};

export default function RightControlsVertical({ children }: Props) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content-wrapper': { overflow: 'auto !important' },
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/*{isCollapse ? <Logo disabledLink /> : <LogoWithName2 isWide={true} disabledLink />}*/}
        </Stack>
      </Stack>
      {children}
      <DealApplicationProgressVertical />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : CONTROLS_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {isDesktop && (
        <Drawer
          open
          anchor={'right'}
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: CONTROLS_WIDTH,
              borderRightStyle: 'solid',
              bgcolor: theme.palette.background.neutral,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z1,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
