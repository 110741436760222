import numeral from 'numeral';
import { Stack, Grid, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Label from 'src/components/Label';
import { useSelector } from 'src/redux/hooks';
import { fCurrencyShort } from 'src/utils/formatNumber';
import { getDealSelector } from 'src/redux/selectors/isoPortal';
import {
  RemittanceScheduleTypeDisplay,
  RemittanceScheduleTypes,
} from 'src/@types/remittance-schedule-types';
import { OfferProperties } from 'src/@types/offer-properties';
import { DealApplication } from 'src/@types/mcafunds/deal-application';

interface LockedOfferLabelProps {
  offerProperties: OfferProperties;
}
const LockedOfferLabel = ({ offerProperties }: LockedOfferLabelProps) => {
  if (offerProperties.hasLockedOffer) return <Label color="success">Locked</Label>;

  return <Label color="info">Not Accepted</Label>;
};

interface DealDetailPanelProps {
  deal: DealApplication;
  offerProperties: OfferProperties;
}

export const DealMaximumApprovalDetails = ({ offerProperties }: DealDetailPanelProps) => (
  <>
    <Typography variant="h6" mt={2}>
      Maximum Approval Details:
    </Typography>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1">Max Funding:</Typography>
      <Typography variant="body1">{fCurrencyShort(offerProperties.maximumFunding || 0)}</Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1">Max Estimated Term:</Typography>
      <Typography variant="body1">{offerProperties.maximumTerm}</Typography>
    </Stack>
    {offerProperties.stackPosition && (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Stack Position:</Typography>
        <Typography variant="body1">{offerProperties.stackPosition || 'N/A'}</Typography>
      </Stack>
    )}
    {offerProperties.creditTier && (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Credit Tier:</Typography>
        <Typography variant="body1">{offerProperties.creditTier}</Typography>
      </Stack>
    )}
  </>
);

export const DealFinancials = ({ offerProperties }: DealDetailPanelProps) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h6" fontWeight={'bold'}>
        Deal Financials:
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Commission:</Typography>
        <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
          {numeral(offerProperties.selectedOfferCommission).format('0.0%')}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Commission Amount:</Typography>
        {offerProperties.selectedOfferIndex >= 0 ? (
          <Typography variant="body1">
            {fCurrencyShort(offerProperties.selectedOfferCommissionAmount || 0)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" pb={1}>
        <Typography variant="body1">Origination Fee:</Typography>
        <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
          {fCurrencyShort(offerProperties.selectedOfferFee || 0)}
        </Typography>
      </Stack>
      {/*<Stack direction="row" justifyContent="space-between">*/}
      {/*  <Typography variant="body1">Factor Rate:</Typography>*/}
      {/*  {offerProperties.selectedOfferIndex >= 0 ? (*/}
      {/*    <Typography variant="body1">*/}
      {/*      {fShortenNumber(offerProperties.selectedOfferFactor)}*/}
      {/*    </Typography>*/}
      {/*  ) : (*/}
      {/*    <Skeleton variant="rectangular" width={60} />*/}
      {/*  )}*/}
      {/*</Stack>*/}
      {/*<Stack direction="row" justifyContent="space-between" pb={1}>*/}
      {/*  <Typography variant="body1">Buy Rate:</Typography>*/}
      {/*  {offerProperties.selectedOfferIndex >= 0 ? (*/}
      {/*    <Typography variant="body1">*/}
      {/*      {fShortenNumber(offerProperties.selectedOfferBuyRate)}*/}
      {/*    </Typography>*/}
      {/*  ) : (*/}
      {/*    <Skeleton variant="rectangular" width={60} />*/}
      {/*  )}*/}
      {/*</Stack>*/}
    </>
  );
};

export const MerchantSelection = ({ offerProperties }: DealDetailPanelProps) => {
  const theme = useTheme();
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption" fontWeight={'bold'}>
          Merchant Selection:
        </Typography>
        <LockedOfferLabel offerProperties={offerProperties} />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Funding Amount:</Typography>
        <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
          {fCurrencyShort(offerProperties.requestedAmount || 0)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Estimated Term:</Typography>
        {offerProperties.selectedOfferIndex >= 0 ? (
          <Typography variant="body1">{offerProperties.selectedOfferTerm}</Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Remittance Schedule:</Typography>
        <Typography variant="body1">
          {RemittanceScheduleTypeDisplay(
            offerProperties.remittanceSchedule as RemittanceScheduleTypes
          )}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Remittances:</Typography>
        {offerProperties.selectedOfferIndex >= 0 ? (
          <Typography variant="body1">
            {offerProperties.selectedOfferTotalRemittances || 0}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Remittance Amount:</Typography>
        {offerProperties.selectedOfferIndex >= 0 ? (
          <Typography variant="body1">
            {fCurrencyShort(offerProperties.selectedOfferRemittanceAmount || 0)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Total Remittance:</Typography>
        {offerProperties.selectedOfferIndex >= 0 ? (
          <Typography variant="body1">
            {fCurrencyShort(offerProperties.selectedOfferTotalRemittanceAmount || 0)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
    </>
  );
};

export const DealManagement = () => {
  const deal = useSelector(getDealSelector);

  if (!deal) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1}>
        <Grid item xs={12} p={1}>
          <Typography variant="h6" mt={2}>
            Deal Progress:
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};
