import * as React from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Application } from 'src/@types/application-types';
import { useDispatch } from 'src/redux/hooks';
import { editApplication } from 'src/redux/slices/applications';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  application: Application;
};

export default function LinkDealToApplication({ isOpen = false, onClose, application }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dealId, setDealId] = React.useState(application.dealId);

  const handleYes = async () => {
    try {
      const updatedApplication = {
        ...application,
        dealId,
        status: application.status === 'InVerification' ? 'OffersReceived' : application.status,
      };
      await dispatch(
        editApplication({
          application: updatedApplication,
        })
      );
      enqueueSnackbar('Deal ID Updated on Application');
    } catch (error) {
      enqueueSnackbar('Error updating Deal ID on Application: Please try again!', {
        variant: 'error',
      });
      return false;
    }
    onClose();
  };

  const handleNo = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleNo}>
        <DialogTitle>Link Deal to Application: {application.appId}</DialogTitle>
        <DialogContent sx={{ pt: '16px' }}>
          <TextField
            margin="dense"
            id="name"
            label="Deal ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={dealId}
            onChange={(e) => setDealId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} variant={'contained'}>
            OK
          </Button>
          <Button onClick={handleNo} variant={'outlined'}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
