import React, { ChangeEventHandler, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { useTheme } from '@mui/material/styles';
import { MenuItem, TextField, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IMaskInput } from 'react-imask';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { ReferenceDataTuple } from 'src/@types/reference-data';
import { CustomProps } from 'src/components/mui-libertas';

export type HelpersProps = {
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

interface TextFieldOptions {
  renderError: boolean;
  helpers: HelpersProps;
  label: string;
  required: boolean;
  type?: string;
  placeholder?: string;
  useDatepicker?: boolean;
  dispatch?: any;
  application?: any;
  formik?: any;
  mask?: string;
  canHide?: boolean;
  helperText?: string;
  disabled?: boolean;
  autoComplete?: string;
}

export interface SelectFieldOptions extends TextFieldOptions {
  entityOptions: ReferenceDataTuple[];
}

export const FormikSelectField = (options: SelectFieldOptions) => {
  const { renderError, helpers, label, entityOptions, required } = options;
  return (
    <TextField
      error={renderError}
      required={required}
      fullWidth
      select
      id={helpers.name}
      name={helpers.name}
      value={helpers.value || ''}
      onChange={helpers.onChange}
      onBlur={helpers.onBlur}
      label={label}
      sx={{
        textAlign: 'left',
      }}
    >
      {(entityOptions || []).map((entity: ReferenceDataTuple) => (
        <MenuItem key={entity.name} value={entity.value}>
          {entity.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const RenderFormikTextField = (options: TextFieldOptions) => {
  const {
    renderError,
    helpers,
    label,
    required,
    type,
    placeholder,
    disabled,
    autoComplete,
    formik,
  } = options;
  return (
    <TextField
      error={renderError}
      required={required}
      fullWidth
      id={helpers.name}
      data-testid={helpers.name}
      name={helpers.name}
      value={helpers.value}
      onChange={helpers.onChange}
      onBlur={helpers.onBlur}
      label={label}
      disabled={disabled}
      autoComplete={autoComplete}
      // label={
      //   formik && renderError
      //     ? `${label}: ${
      //         formik.touched[helpers.name] && formik.errors[helpers.name]
      //       }`
      //     : label
      // }
      type={type ? type : 'text'}
      onWheel={
        type === 'number'
          ? (event) => event.currentTarget.querySelector('input')?.blur()
          : undefined
      }
      placeholder={placeholder ? placeholder : ''}
      helperText={
        formik && renderError && formik.touched[helpers.name] && formik.errors[helpers.name]
      }
    />
  );
};

export const RenderFormikDateField = (options: TextFieldOptions) => {
  const { renderError, helpers, label, required, type, placeholder, formik } = options;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={helpers.value}
        onChange={(value) => {
          formik.setFieldValue(helpers.name, new Date(value as unknown as string));
          formik.setFieldTouched(helpers.name, true, undefined);
        }}
        renderInput={(params) => (
          <TextField
            required={required}
            fullWidth
            id={helpers.name}
            name={helpers.name}
            value={helpers.value}
            label={label}
            type={type ? type : 'text'}
            onBlur={helpers.onBlur}
            placeholder={placeholder ? placeholder : ''}
            aria-label={helpers.name}
            {...params}
            error={renderError}
            helperText={
              formik ? formik.touched[helpers.name] && formik.errors[helpers.name] : undefined
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const FormikTextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask ?? '(#00) 000-0000'}
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export const FormikPhoneTextField = (options: TextFieldOptions) => {
  const theme = useTheme();
  const { renderError, helpers, label, required, mask, canHide, helperText } = options;

  const [showContent, setShowContent] = useState<boolean>(false);

  const handleClickShowContent = () => setShowContent(!showContent);

  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel
        htmlFor={helpers.name}
        required={required}
        shrink={true}
        aria-label={`${helpers.name}-label`}
      >
        {label}
      </InputLabel>
      <Input
        fullWidth={true}
        id={helpers.name}
        aria-labelledby={`${helpers.name}-label`}
        name={helpers.name}
        value={helpers.value}
        onChange={helpers.onChange}
        onBlur={helpers.onBlur}
        inputComponent={FormikTextMaskCustom as any}
        error={renderError}
        inputProps={{
          mask,
        }}
        type={!canHide || showContent ? 'text' : 'password'}
        endAdornment={
          canHide && (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowContent} edge="end" aria-label="Show/Hide">
                {showContent ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
        sx={{
          borderRadius: '0px',
          '& .MuiInputBase-input': {
            backgroundColor: theme.palette.background.neutral,
            paddingLeft: theme.spacing(1),
          },
        }}
      />
      {helperText && <FormHelperText error={renderError}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }
);

export const FormikCurrencyTextField = (options: TextFieldOptions) => {
  const theme = useTheme();
  const { renderError, helpers, label, required, helperText } = options;

  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel htmlFor={helpers.name} required={required} shrink={true}>
        {label}
      </InputLabel>
      <Input
        fullWidth={true}
        id={helpers.name}
        name={helpers.name}
        value={helpers.value}
        onChange={helpers.onChange}
        onBlur={helpers.onBlur}
        inputComponent={NumberFormatCustom as any}
        error={renderError}
        sx={{
          borderRadius: '0px',
          '& .MuiInputBase-input': {
            backgroundColor: theme.palette.background.neutral,
            paddingLeft: theme.spacing(1),
          },
        }}
      />
      {helperText && <FormHelperText error={renderError}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
