import axios from '../merchantPortalService';
import { states } from 'src/_mock/states';

export const fetchCapitalPurposes = async () => {
  const capitalPurposesData = await axios.get('/api/v1/reference-data/use-of-funds');
  const { data } = capitalPurposesData || [];
  const capitalPurposes = {
    data: {
      data,
    },
  };
  return capitalPurposes;
};

// private API:
// /api/industries
export const fetchIndustries = async () => {
  const industriesData = await axios.get('/api/v1/reference-data/industries');
  const { data } = industriesData || [];
  const industries = {
    data: {
      data: data.map((item: any) => ({
        ...item,
        value: item.NAICS,
      })),
    },
  };
  return industries;
};

export const fetchLegalEntityTypes = async () => {
  const entityTypesData = await axios.get('/api/v1/reference-data/entity-types');
  const { data } = entityTypesData;
  const entityTypes = {
    data: {
      data: data.entityTypes,
    },
  };
  return entityTypes;
};

// Available at '~/services/us_states.js' in MCAFunds but no API for it
// Note: We could decide to leave as an asset file as well.
export const fetchStates = async () => {
  const mockedData = {
    data: {
      data: states,
    },
  };
  return mockedData;
};
