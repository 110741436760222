import { useContext } from 'react';
import { AuthContext } from 'src/contexts/Auth0Context';
import { AuthContext as AuthSignedLinkContext } from 'src/contexts/AuthSignedLinkContext';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export const useSignedLinkAuth = () => {
  const context = useContext(AuthSignedLinkContext);

  if (!context) throw new Error('AuthSignedLinkContext context must be use inside AuthProvider');

  return context;
};

export default useAuth;
