import { useSnackbar } from 'notistack';
import {
  Box,
  Avatar,
  Divider,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ContractIcon from '@mui/icons-material/Gavel';
import { fCurrencyShort, fShortenNumber } from 'src/utils/formatNumber';
import { Iconify } from 'src/components';
import { formatDateShort } from 'src/utils/formatting';

const OverlayStyle = styled('div')(() => ({
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

interface ContractCardProps {
  documents: any;
  onClose: () => void;
  onDownload: any;
  setDocumentSelected: any; // Dispatch<SetStateAction<{ id: string; fileUrl: string } | null | undefined>>;
}
export const ContractCard = ({ documents, setDocumentSelected, onDownload }: ContractCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        textAlign: 'center',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.neutral,
        p: 1,
        minWidth: '250px',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Avatar
          alt={'contract'}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
            color: theme.palette.primary.main,
          }}
        >
          <ContractIcon />
        </Avatar>
        <OverlayStyle />
      </Box>

      <Stack direction="row" justifyContent="center" pt={1}>
        <Typography variant="h5" sx={{ mt: 3 }}>
          Contract
        </Typography>
      </Stack>

      <Box
        sx={{
          py: 3,
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          textAlign: 'center',
        }}
      >
        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Amount
          </Typography>
          <Typography variant="subtitle1">
            {fCurrencyShort(documents.contract.fundedAmount)}
          </Typography>
        </div>

        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Buy Rate
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(documents.contract.buyRate)}</Typography>
        </div>

        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Factor Rate
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(documents.contract.sellRate)}</Typography>
        </div>
      </Box>

      <Divider />

      <Stack direction="row" justifyContent="center" pt={1}>
        <Tooltip title="View Contract">
          <IconButton
            aria-label="Show"
            onClick={() => {
              if (!documents.contract?.fileUrl) {
                enqueueSnackbar('Contract not currently available to view', { variant: 'warning' });
                return;
              }

              setDocumentSelected({
                id: 'contract',
                fileUrl: documents.contract?.fileUrl ?? '',
              });
            }}
          >
            <Iconify icon={'eva:eye-fill'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download Contract">
          <IconButton
            onClick={() => {
              if (!documents.contract?.fileUrl) {
                enqueueSnackbar('Contract not currently available for download', {
                  variant: 'warning',
                });
                return;
              }

              onDownload(documents.contract?.fileUrl ?? '');
            }}
            aria-label="Download"
          >
            <Iconify icon={'eva:download-fill'} />
          </IconButton>
        </Tooltip>
      </Stack>
      {!documents.contract?.fileUrl && (
        <Stack direction="row" justifyContent="center" pb={1}>
          <FormHelperText>Contract not currently available</FormHelperText>
        </Stack>
      )}

      {documents.contract.createdAt && (
        <Stack direction="row" justifyContent="flex-end">
          <FormHelperText>
            Generated at: {formatDateShort(documents.contract.createdAt)}
          </FormHelperText>
        </Stack>
      )}
    </Stack>
  );
};
