import React from 'react';
import { Prepay } from 'src/@types/prepays';
import PrepaysTable from 'src/portals/components/prepays/PrepaysTable';

interface PrepaysPanelProps {
  prepays: Prepay[];
}

export const PrepaysPanel = ({ prepays }: PrepaysPanelProps) => {
  if (!prepays || prepays.length === 0) {
    return null;
  }

  return <PrepaysTable prepays={prepays} />;
};
