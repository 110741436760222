import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Typography, Container } from '@mui/material';
import Page from 'src/components/Page';
import { MotionContainer, varBounce } from 'src/components/animate';
import { LogoWithName2 } from '../../components/Logo';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
}));

export default function ErrorLoadingDeal() {
  return (
    <Page title="Error Loading Deal">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', marginTop: -15 }}>
          <LogoWithName2 isWide={true} disabledLink sx={{ width: '540', height: '100', pb: 2 }} />
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Error loading Deal
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              There was an error, please try again later.
            </Typography>
          </m.div>
        </ContentStyle>
      </Container>
    </Page>
  );
}
