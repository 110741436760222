import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ESIGN_VERSION = '1.0.0';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  padding: theme.spacing(12, 2),
  paddingTop: theme.spacing(2),
}));

const ESignAgreement = () => (
  <>
    <Grid item xs={12}>
      <Typography variant="h4" component={'div'}>
        Accept E-Sign
      </Typography>
    </Grid>
    <Grid container>
      <Typography
        variant="body2"
        gutterBottom
        sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
        component={'div'}
      >
        Consent to Use Electronic Records and Signatures (ESIGN Disclosure and Consent)
      </Typography>
      <Typography variant="body2" gutterBottom>
        By clicking below, you consent to receive any Communication, including any required
        disclosure, electronically and to the use of electronic signatures. Please review this ESIGN
        Disclosure and Consent carefully and keep a copy for your records.
      </Typography>
      <Typography variant="body2" gutterBottom>
        In this consent:
      </Typography>
      <Typography variant="body2" marginLeft={1} component={'div'}>
        <Typography variant="body2">
          □ "We," "us," and "our" mean Libertas Funding, LLC, including our service providers,
          agents, and affiliates.
        </Typography>
        <Typography variant="body2">
          □ “You” and “your” means the business applicant and/or guarantor giving this consent.
        </Typography>
        <Typography variant="body2">
          □ "Communication" means any disclosure, notice, agreement, document, statement, record, or
          other information that we provide to you, or that you submit to us.
        </Typography>
        <Typography variant="body2">
          □ "Electronic Service" means each and every product and service we offer that you apply
          for, use, administer or access using the Internet, a website, email, messaging services
          (including text messaging) and/or software applications (including applications for mobile
          or hand-held devices), either now or in the future.
        </Typography>
        <Typography variant="body2">
          □ "Product" means each and every account, product or service we offer that you apply for,
          own, use, administer or access, either now or in the future. Our Products include
          Electronic Services.
        </Typography>
        <Typography variant="body2">
          □ The words “include" and "including," when used at the beginning of a list of one or more
          items, indicates that the list contains examples – the list is not exclusive or
          exhaustive, and the items in the list are only illustrations. They are not the only
          possible items that could appear in the list.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }} component={'div'}>
        1. Your consent to use electronic records and signatures; Choosing to receive Communications
        electronically or in writing; Certain information may still be provided in writing
      </Typography>
      <Typography variant="body2" gutterBottom marginLeft={1} component={'div'}>
        <Typography variant="body2">
          You agree that we may provide any Communication to you electronically (“Electronic
          Communications”) and to the use of electronic signatures.
        </Typography>
        <Typography variant="body2">
          Electronic Communications may be delivered to you by email. You agree that we may contact
          you by e-mail, using any email address that you provide to us. In some cases, you will be
          able to choose whether to receive certain Communications electronically, or on paper, or
          both. We will provide you with instructions on how to make those choices when they are
          available.
        </Typography>
        <Typography variant="body2">
          We may at any time, in our sole discretion, provide you with any Communication via paper,
          even if you have chosen to receive it electronically.
        </Typography>
        <Typography variant="body2">
          Any notice you are required to provide to us by law, agreement or otherwise must still be
          provided to us on paper; unless we specifically tell you in another Communication how you
          may deliver that notice to us electronically.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        2. Your option to receive paper copies
      </Typography>
      <Typography variant="body2" gutterBottom marginLeft={1} component={'div'}>
        <Typography variant="body2">
          If we provide Electronic Records to you and you want a paper copy, you may contact us at
          (800) 704-8675 or customer.service@libertasfunding.com and request a paper version.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        3. Your consent covers all Products by or through Libertas
      </Typography>
      <Typography variant="body2" gutterBottom marginLeft={1} component={'div'}>
        <Typography variant="body2">
          Your consent covers all Communications relating to any Product offered by or through us.
          Your consent remains in effect until you give us notice that you are withdrawing it.
        </Typography>
        <Typography variant="body2">
          From time to time, you may seek to obtain a new Product. When you do, we may remind you
          that you have already given us your consent to use Electronic Records and signatures. If
          you decide not to use Electronic Records and signatures in connection with the new product
          or service, your decision does not mean you have withdrawn this consent for any other
          Product.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        4. You may withdraw your consent at any time; Consequences of withdrawing consent; How to
        give notice of withdrawal
      </Typography>
      <Typography variant="body2" gutterBottom marginLeft={1} component={'div'}>
        <Typography variant="body2">
          You have the right to withdraw your consent at any time. Please be aware, however, that
          withdrawal of consent may result in the termination of your access to our Electronic
          Services, including online account access, and your ability to use certain Products.
        </Typography>
        <Typography variant="body2">
          To withdraw your consent, you must contact us at (800) 704-8675 or email us at
          customer.service@libertasfunding.com. Your withdrawal of consent will become effective
          after we have had a reasonable opportunity to act upon it.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        5. You must keep your email or electronic address current with us.
      </Typography>
      <Typography variant="body2" gutterBottom marginLeft={1} component={'div'}>
        <Typography variant="body2">
          It is your responsibility to keep your primary email address current so that we can
          communicate with you electronically. You must promptly call us at (800) 704-8675 or email
          us at customer.service@libertasfunding.com to notify us of any change in your email or
          other electronic address. You understand and agree that if we send you a Communication but
          you do not receive it because your primary email address on file is incorrect, out of
          date, blocked by your service provider or you are otherwise unable to receive electronic
          Communications, we will be deemed to have provided the Communication to you. If you use a
          spam filter or similar software that blocks or re-routes emails from senders not listed in
          your email address book, we recommend that you add us to your email address book so that
          you can receive Communications by e-mail.
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
        6. Federal Law
      </Typography>
      <Typography variant="body2" marginLeft={1} component={'div'}>
        <Typography variant="body2">
          You acknowledge and agree that you affirmatively consent to do business electronically and
          that you and we both intend that the Electronic Signatures in Global and National Commerce
          Act (ESIGN Act) will apply to the fullest extent possible to validate our ability to
          conduct business with you by electronic means.
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          By providing your consent, you are also confirming that you are authorized to, and do,
          consent on behalf of the applicant.
        </Typography>
        <Typography variant="body2">
          Examples of electronic records covered by your consent include but are not limited to:
        </Typography>
        <Typography variant="body2" marginLeft={1} component={'div'}>
          <Typography variant="body2">
            ● This ESIGN Disclosure and Consent and any amendments;
          </Typography>
          <Typography variant="body2">
            ● Other service or user agreements for online access to our Electronic Services, and all
            amendments to any of these agreements;
          </Typography>
          <Typography variant="body2">
            ● All of the Communications related to any Product, except for those excluded by the
            terms of this ESIGN Disclosure and Consent;
          </Typography>
          <Typography variant="body2">
            ● Any and all periodic account and activity statements, disclosures and notices we
            provide to you concerning your Products;
          </Typography>
          <Typography variant="body2">
            ● Any notice or disclosure regarding fees or assessments of any kind, including late
            fees, overdraft fees, over limit fees, and returned item fees;
          </Typography>
          <Typography variant="body2">
            ● Notices of amendments to any of your agreements and accounts with us; and
          </Typography>
          <Typography variant="body2">
            ● Other disclosures and notices that we are legally required to provide to you or choose
            to provide to you in our discretion.
          </Typography>
        </Typography>
      </Typography>
    </Grid>
  </>
);

export const ESignAgreementPage = () => (
  <RootStyle>
    <ContentStyle>
      <ESignAgreement />
    </ContentStyle>
  </RootStyle>
);

export default ESignAgreement;
