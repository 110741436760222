import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import InfoIcon from '@mui/icons-material/Info';
import { Stack, Grid, Button, FormHelperText, Skeleton, Tooltip, Typography } from '@mui/material';
import SendContractDialog from './SendContractDialog';

import { useSelector } from 'src/redux/hooks';
import {
  getDealCalcSelector,
  getDealSelector,
  getOfferPropertiesSelector,
} from 'src/redux/selectors/isoPortal';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { useSignedLinkAuth } from 'src/hooks';
import { fDateTimeSuffix } from 'src/utils/formatTime';

export const ContractsControlsPanel = () => {
  const { dealId, epk, et } = useSignedLinkAuth();
  const { enqueueSnackbar } = useSnackbar();
  const deal = useSelector(getDealSelector);
  const dealCalc = useSelector(getDealCalcSelector);
  const offerProperties = useSelector(getOfferPropertiesSelector);
  const contract = useSelector((state) => state.isoPortal.contract);

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [signers, setSigners] = useState<any[]>([]);

  const owners = useMemo(() => {
    const data = [];

    for (let index = 1; index <= contract?.owner_count; index++) {
      const owner = contract[`owner${index}`];

      const [firstName, ...rest] = owner.name_legal?.trim()?.split(' ') ?? [];

      data.push({
        id: owner.number,
        firstName: firstName ?? '',
        lastName: rest?.join(' ') ?? '',
        emailAddress: owner.email,
      });
    }

    return data;
  }, [contract]);

  const hasOwnerWithoutEmail = useMemo(
    () => owners.some((owner) => !owner.emailAddress || owner.emailAddress.length === 0),
    [owners]
  );

  const sendDateFormatted = useMemo(() => {
    const [signer] = signers;

    return signer?.createdAt ? fDateTimeSuffix(signer?.createdAt) : '';
  }, [signers]);

  const sendData = useMemo(
    () => ({
      cc: (dealCalc?.iso_name_id?.primary_email ?? '') as string,
      isSent: signers?.length > 0,
      owners,
    }),
    [dealCalc, signers, owners]
  );

  const isContractAvailable = useMemo(
    () => offerProperties.hasLockedOffer && contract?.contract_history?.length > 0,
    [contract?.contract_history?.length, offerProperties.hasLockedOffer]
  );

  const onOpenDialog = () => setVisibleDialog(true);

  const onCloseDialog = () => setVisibleDialog(false);

  useEffect(() => {
    if (!dealId) return;

    async function get() {
      try {
        const data = await dealsApi.getContractSigners({
          dealId,
          epk,
          et,
        });

        setSigners(data);
      } catch (error) {
        enqueueSnackbar('Error getting the contract signers: Please try again!', {
          variant: 'error',
        });
      }
    }

    get();
  }, [dealId, epk, et, enqueueSnackbar]);

  if (!deal) {
    return <Skeleton width={'100%'} height={'44px'} />;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="space-between">
      <Grid container p={1} pb={0}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={onOpenDialog}
            disabled={!isContractAvailable || hasOwnerWithoutEmail}
          >
            {signers?.length > 0 ? 'Resend' : 'Send'} for ESIGN
          </Button>

          {!isContractAvailable && (
            <FormHelperText>*Contracts can't be sent until created</FormHelperText>
          )}
          {hasOwnerWithoutEmail && (
            <FormHelperText>*Disabled due to missing owner emails.</FormHelperText>
          )}

          {signers?.length > 0 && (
            <FormHelperText>
              <Grid container spacing={1} alignContent="center">
                <Grid item sx={{ alignSelf: 'center' }}>
                  Sent out at {sendDateFormatted}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <Grid container spacing={1}>
                        {signers.map((signer) => (
                          <Grid item md={12} key={signer.id}>
                            <Typography variant="caption" fontSize="xx-small">
                              {signer.fullName} {`<${signer.emailAddress}>`}
                              {signer.status === 'complete' && ' (signed)'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    }
                    arrow
                  >
                    <InfoIcon />
                  </Tooltip>
                </Grid>
              </Grid>
            </FormHelperText>
          )}

          {visibleDialog && <SendContractDialog data={sendData} onClose={onCloseDialog} />}
        </Grid>
      </Grid>
    </Stack>
  );
};
