import { ApplicantInfo, OwnerInfo } from 'src/@types/application-types';

const clientId: string = '123';

export const owner1: OwnerInfo = {
  clientId,
  id: 'OWNER-PP',
  appId: 'APP-PASSING',
  firstName: 'Gary',
  lastName: 'Katcher',
  phoneNumber: '4695551212',
  emailAddress: 'dev+uatpass@libertasfunding.com',
  dateOfBirth: new Date('1970-05-15 00:00:00'),
  SSN: '123121233',
  percentOwnership: 100,
  homeStreetAddress1: '411 West Putnam Avenue Suite 220',
  homeStreetAddress2: '',
  city: 'Greenwich',
  state: 'CT',
  zip: '06830',
  userDate: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const applicantPass: ApplicantInfo = {
  clientId,
  id: 'App-Passing-1',
  appId: 'APP-PASSING',
  legalBusinessName: 'Libertas Funding LLC',
  industry: '11250',
  grossAnnualSales: 125000,
  requestedAmount: 45000,
  purposeOfCapital: 'Taxes',
  firstName: 'John',
  lastName: 'Smith',
  emailAddress: 'dev+uatpass@libertasfunding.com',
  phoneNumber: '4695551212',
  password: 'GIVEMELIBERTY!2022',
  dba: 'Libertas',
  entityType: 'CORPORATION',
  businessStreetAddress1: '411 West Putnam Avenue Suite 220',
  businessStreetAddress2: '',
  businessCity: 'Greenwich',
  businessState: 'CT',
  businessZip: '06830',
  federalTaxID: '813053598',
  businessStartDate: new Date('2018-01-15 00:00:00'),
  businessPhone: '214551212',
  averageMonthlySales: 20000000,
  websiteURL: 'www.libertasfunding.com',
  acceptsCreditCards: false,
  hasCurrentShortTermFinancing: false,
  owners: [owner1],
  userDate: '',
  businessInfoEdited: false,
  basicInfoEdited: false,
  ownerInfoEdited: false,
  codatID: '8fbfa5ea-275c-456a-a2ef-94dad9dbaeb2',
  // codatID: 'fad47914-6659-4522-8645-0174f5a065ce',
  // codatID: '3a6215f5-7d18-458a-87ad-3f410a198f4e',
  createdAt: new Date(),
  updatedAt: new Date(),
};
