export type StipulationStatusInfo = {
  fileName: string;
  type: string;
  result?: string | ArrayBuffer;
  provided: Boolean;
  id: number;
  stipId: string;
};

export type DealStipulations = {
  [index: string]: string | StipulationStatusInfo[] | StipulationStatusInfo | undefined;
  appId: string;
  bankStatements: StipulationStatusInfo[];
  driverLicence?: StipulationStatusInfo;
  voidedCheck?: StipulationStatusInfo;
  application?: StipulationStatusInfo;
  proofOfOwnership?: StipulationStatusInfo;
};

export type DealCompany = {
  id: string; //companies.id
  companyId: string; //companies.company_id
  nameLegal: string; //companies.name_legal
  fein: string; //companies.fein
  locationState: string; //companies.location_state
  email: string; //companies.company_email
  codatCompanyId: string; //companies.codat_company_id
  codatLinked: boolean; //synthetic (Add to companies table)
};

export type CompanyOwner = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
};

export type DealApplication = {
  clientId?: string;
  appId?: string; //from Merchant Portal
  dealId?: string; //deal_application_thread_id
  identifier?: string;
  state: string; //deal_queue_item.state
  subState: string; //deal_queue_item.sub_state
  isNTW: boolean; //deal_queue_item.is_in_no_touch_workflow
  priority: number; //deal_queue_item.priority
  companyId: string; //deal_application.company_id
  company: DealCompany;
  amountRequested: number; //companies.amount_requested
  useOfProceeds: string; //deal_application.use_of_proceeds
  stipulations?: DealStipulations;
  submittedTs: Date; //deal_application.submitted_ts
  createdTs?: Date; //deal_application.create_ts
};

export const convertMCAFDealApplicationToDealApplication = (
  deal: any,
  dealId: string
): DealApplication =>
  ({
    dealId: dealId,
    identifier: deal?.fmsModel?.identifier ?? dealId,
    state: deal.deal_queue_item.state,
    subState: deal.deal_queue_item.sub_state,
    isNTW: deal.deal_queue_item.is_in_no_touch_workflow,
    priority: deal.deal_queue_item.priority,
    companyId: deal.company.id,
    company: {
      id: deal.company.id,
      companyId: deal.company.id,
      nameLegal: deal.company.name_legal,
      fein: deal.company.fein,
      locationState: deal.company.location_state,
      email: deal.company?.company_email || '',
      codatCompanyId: deal.company.codat_company_id || '',
      codatLinked: !!deal.company.codat_company_id,
    } as DealCompany,
    amountRequested: deal.company.amount_requested,
    useOfProceeds: deal.company.use_of_proceeds,
    // stipulations?: DealStipulations;
    createdTs: deal.create_ts,
  } as DealApplication);

export type DealRequiredStip = {
  id: string;
  stipulationId: string;
  name: string;
  status: string;
  uploadName: string;
};

export type DealContract = {
  fileUrl: string;
  fundedAmount: string;
  buyRate: number;
  sellRate: number;
  createdAt: Date;
};

export type DealStateDocument = {
  fileUrl: string;
  state: string;
};

export type DealDocument = {
  contract?: DealContract;
  disclosure?: DealStateDocument;
  itemization?: DealStateDocument;
};
