import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Button } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch } from 'src/redux/hooks';
import MyAvatar from 'src/components/MyAvatar';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
import { useOpenApplications } from 'src/portals/customer-portal/hooks/application-hooks';
import { createNewApplication, setEditApplication } from 'src/redux/slices/editApplication';
import { onUserLogout } from 'src/redux/slices/mainApp';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { getMaxCustomerApplications } from 'src/portals/components/application-flow/application-utils';
import { GetDefaultNewApplication } from 'src/utils/applicationUtils';

type MenuOption = {
  label: string;
  linkTo: string;
};

const MENU_OPTIONS = [
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Account',
    linkTo: PATH_DASHBOARD.user.account,
  },
] as MenuOption[];

export default function AccountPopover() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const user = useCurrentUser();
  const isMountedRef = useIsMountedRef();
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const openApplications = useOpenApplications();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const isISORoute = React.useMemo(() => pathname.includes('isoapp'), [pathname]);
  const isSupportRoute = React.useMemo(() => pathname.includes('support'), [pathname]);
  const maxApplications = getMaxCustomerApplications(user.isLibertasUser);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(onUserLogout());

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleApplyNow = () => {
    const application = GetDefaultNewApplication();

    dispatch(
      createNewApplication({
        application: {
          ...application,
          applicant: {
            ...application.applicant,
            basicInfoEdited: false,
          },
        },
      })
    );
  };

  const canEditApplication = React.useMemo(
    () => openApplications.some((application) => application.status === 'NotSubmitted'),
    [openApplications]
  );

  const handleEditApplication = () => {
    if (openApplications.length === 0) return;

    dispatch(setEditApplication(openApplications[0]));
    navigate(`${PATH_DASHBOARD.app.applicationWizard}?appId=${openApplications[0].appId}`);
  };

  return (
    <>
      {isAuthenticated && !isISORoute && !isSupportRoute && canEditApplication ? (
        <Button variant={'contained'} onClick={handleEditApplication}>
          Continue Application
        </Button>
      ) : !(isISORoute || isSupportRoute) ? (
        <Button
          variant={'contained'}
          to="./mpapp/application/wizard"
          onClick={handleApplyNow}
          component={RouterLink}
          disabled={openApplications.length >= maxApplications}
        >
          Apply Now
        </Button>
      ) : null}
      <Box sx={{ my: 1.5, px: 2.5 }}>
        <Typography
          variant="caption"
          align={'right'}
          noWrap
          sx={{ color: theme.palette.primary.main }}
        >
          Logged in as
        </Typography>
        <Typography variant="body2" align={'right'} sx={{ color: 'text.secondary' }} noWrap>
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
      </Box>

      <IconButtonAnimate
        aria-label="Open"
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/*<Box sx={{ my: 1.5, px: 2.5 }}>*/}
        {/*  <Typography variant="subtitle2" noWrap>*/}
        {/*    {user?.displayName}*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>*/}
        {/*    {user?.email}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}

        {/*<Divider sx={{ borderStyle: 'dashed' }} />*/}

        {MENU_OPTIONS.length > 0 && (
          <>
            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
            <Divider sx={{ borderStyle: 'solid' }} />
          </>
        )}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
