import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_API, PATH_AFTER_LOGIN } from 'src/config';
import { PATH_DASHBOARD } from 'src/routes/paths';

type Auth0ProviderWithHistoryProps = {
  children: ReactNode;
};

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  const { domain, customDomain, clientId } = AUTH0_API;

  const navigate = useNavigate();

  const authDomain = React.useMemo(() => {
    const isProd = process.env.REACT_APP_ENVIRONMENT === 'PROD' || false;
    return isProd ? customDomain : domain;
  }, [domain, customDomain]);

  const { redirectUri, redirectPath } = React.useMemo(() => {
    const role = localStorage.getItem('loginContext') || 'customer';

    let redirectPathToUse = '';
    switch (role) {
      case 'support':
        redirectPathToUse = PATH_DASHBOARD.customerService.dashboard;
        break;
      case 'iso':
        redirectPathToUse = PATH_DASHBOARD.isoPortal.dashboard;
        break;
      default:
        redirectPathToUse = PATH_AFTER_LOGIN;
        break;
    }
    const redirectUriToUse = `${window.location.origin}${redirectPathToUse}`;
    console.log({ redirectUriToUse });
    return { redirectUri: redirectUriToUse, redirectPath: redirectPathToUse };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, customDomain]);

  const onRedirectCallback = (appState: any) => {
    const navigateTo = appState?.returnTo ?? redirectPath;
    navigate(navigateTo);
  };

  return (
    <Auth0Provider
      domain={authDomain as string}
      clientId={clientId as string}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      scope="read:current_user update:current_user_metadata read:users_app_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

// @ts-ignore
export default Auth0ProviderWithHistory;
