import { ApplicantInfo, OwnerInfo } from 'src/@types/application-types';

const clientId: string = '123';

export const owner1: OwnerInfo = {
  clientId,
  id: 'OWNER-PP',
  appId: 'APP-MANUAL',
  firstName: 'John',
  lastName: 'Smith',
  phoneNumber: '4695551212',
  emailAddress: 'dev+uatmanual@libertasfunding.com',
  dateOfBirth: new Date('1970-05-15 00:00:00'),
  SSN: '123121233',
  percentOwnership: 33,
  homeStreetAddress1: '700 Central Expy S',
  homeStreetAddress2: '',
  city: 'Allen',
  state: 'TX',
  zip: '75013',
  userDate: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const applicantManual: ApplicantInfo = {
  clientId,
  id: 'App-Manual-1',
  appId: 'APP-MANUAL',
  legalBusinessName: 'Avengers Inc.',
  industry: '45311', // Florist
  grossAnnualSales: 125000,
  requestedAmount: 15000,
  purposeOfCapital: 'Payroll',
  firstName: 'John',
  lastName: 'Smith',
  emailAddress: 'dev+uatpass@libertasfunding.com',
  phoneNumber: '4695551212',
  password: 'GIVEMELIBERTY!2022',
  dba: 'Libertas',
  entityType: 'CORPORATION',
  businessStreetAddress1: '700 Central Expy S',
  businessStreetAddress2: '',
  businessCity: 'Allen',
  businessState: 'TX',
  businessZip: '75013',
  federalTaxID: '123456789',
  businessStartDate: new Date('2018-01-15 00:00:00'),
  businessPhone: '214551212',
  averageMonthlySales: 20000000,
  websiteURL: 'www.avengers.com',
  acceptsCreditCards: false,
  hasCurrentShortTermFinancing: false,
  owners: [owner1],
  userDate: '',
  businessInfoEdited: false,
  basicInfoEdited: false,
  ownerInfoEdited: false,
  // codatID: 'TBD',
  createdAt: new Date(),
  updatedAt: new Date(),
};
