import { useCallback, useState } from 'react';
import { startCase } from 'lodash';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  Stack,
} from '@mui/material';
import { HeaderBreadcrumbs, EmptyContent } from 'src/components';
import { useSelector } from 'src/redux/hooks';
import { useSettings } from 'src/hooks';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getDocumentsCountSelector } from 'src/redux/selectors/isoPortal';
import { ContractCard } from '../iso-portal/components/ContractCard';
import { DisclosureCard } from '../iso-portal/components/DisclosureCard';
import { ItemizationCard } from '../iso-portal/components/ItemizationCard';

const DocumentsPage = () => {
  const theme = useTheme();
  const { themeStretch } = useSettings();

  const documentsCount = useSelector(getDocumentsCountSelector);
  const { documents, isLoading } = useSelector((state) => state.isoPortal);

  const [documentSelected, setDocumentSelected] = useState<{
    id: string;
    fileUrl: string;
  } | null>();

  const onClose = useCallback(() => setDocumentSelected(null), []);

  const onDownload = useCallback((fileUrl: string) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[:.]/g, '-');
    const filename = `Libertas-contract_${formattedDate}.pdf`;
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = filename;
    link.href = fileUrl;
    link.click();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs
        heading="Documents"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.partner.status }, { name: 'Documents' }]}
      />

      <Stack direction="column" alignItems="center" justifyContent="space-between">
        {documentsCount === 0 && (
          <EmptyContent
            title="No Data"
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        )}

        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            },
          }}
        >
          {documents?.contract && (
            <ContractCard
              documents={documents}
              onClose={onClose}
              onDownload={onDownload}
              setDocumentSelected={setDocumentSelected}
            />
          )}

          {documents?.disclosure && documents?.disclosure?.fileUrl && (
            <DisclosureCard
              documents={documents}
              onClose={onClose}
              onDownload={onDownload}
              setDocumentSelected={setDocumentSelected}
            />
          )}

          {documents?.itemization && documents?.itemization?.fileUrl && (
            <ItemizationCard
              documents={documents}
              onClose={onClose}
              onDownload={onDownload}
              setDocumentSelected={setDocumentSelected}
            />
          )}
        </Box>
      </Stack>

      <Dialog
        open={!!documentSelected}
        fullScreen
        onClose={onClose}
        scroll="paper"
        aria-labelledby="review-agreement-dialog-title"
        aria-describedby="review-agreement-dialog-description"
        data-testid={'review-agreement'}
        maxWidth={'md'}
      >
        <DialogTitle
          id="review-agreement-dialog-title"
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
        >
          {startCase(documentSelected?.id)}
        </DialogTitle>
        <object data={documentSelected?.fileUrl} type="application/pdf" width="100%" height="750px">
          <p>
            This browser does not support PDFs. Please download the PDF to view it:{' '}
            <a href={documentSelected?.fileUrl}>Download PDF</a>.
          </p>
        </object>
        <DialogActions>
          <Button
            variant={'outlined'}
            onClick={onClose}
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderRadius: Number(theme.shape.borderRadius) * 2,
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DocumentsPage;
