import * as Yup from 'yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, Alert, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { PATH_AUTH } from 'src/routes/paths';
import { FormValuesProps, RegisterFormProps, parseMarketingQueryParams } from './RegisterForm';

export default function RegisterISOForm({ role }: RegisterFormProps) {
  const { register } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    legalBusinessName: Yup.string().required('ISO name is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    legalBusinessName: '',
    role,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const options = parseMarketingQueryParams(search);

      await register(
        data.legalBusinessName,
        data.email,
        data.password,
        data.firstName,
        data.lastName,
        '',
        role,
        options
      );
      enqueueSnackbar('ISO Successfully Registered. Please log in.');
      navigate(PATH_AUTH.registerSuccess);
    } catch (error) {
      if (isMountedRef.current) {
        let message = 'Registration Error: Please try again or contact support';
        let errorSummary = '';
        if (error.status === 400) {
          if (error.errorCauses.length > 0) {
            errorSummary = error.errorCauses[0];
          }
        }
        if (error.status === 889) {
          message = message + ' Code: 889';
        }
        console.error(errorSummary, error);
        setError('afterSubmit', { ...error, message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="legalBusinessName" label="ISO Name" required={true} />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" required={true} />
          <RHFTextField name="lastName" label="Last name" required={true} />
        </Stack>

        <RHFTextField name="email" label="Email address" required={true} />

        <RHFTextField
          name="password"
          label="Password"
          required={true}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Show/Hide"
                >
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText>
          Your password should be at least eight characters long and cannot contain spaces or match
          your email address.
        </FormHelperText>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register ISO
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
