export const formatDate = (input: Date | undefined) => {
  if (input === undefined) return '';
  const aDate = new Date(input as Date);
  return aDate ? aDate.toLocaleString('en-US') : '';
};

export const formatDateShort = (input: Date | undefined) => {
  if (input === undefined) return '';
  const aDate = new Date(input as Date);
  return aDate ? aDate.toLocaleDateString('en-US') : '';
};
