import axios from 'axios';
import { MERCHANT_PORTAL_SERVICE_API } from 'src/config';

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
};

const axiosInstance = axios.create({
  baseURL: MERCHANT_PORTAL_SERVICE_API,
  headers: defaultHeaders,
});
delete axiosInstance.defaults.headers.common.Authorization;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('rawError', error);
    throw error;
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    // Review: We may do this with the OKTA impl
    // config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;

    // Custom header to put the clientId (Tenant ID) into the header. This will
    // be parsed out by the server for authorization.
    // @ts-ignore
    config.headers['x-client-id'] = localStorage.getItem('clientId') || '';
    // @ts-ignore
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
