import React, { Component, ErrorInfo, ReactNode } from 'react';
import { IconButton, Typography } from '@mui/material';

interface Props {
  children: ReactNode;
  userSuggestion: string;
  showReload: boolean;
  size: string;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
  offerReload: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
    offerReload: false,
  };

  static defaultProps = {
    userSuggestion: '',
    showReload: false,
    size: 'md',
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    const needsReload = this.testForReloadOffer(error.toString());
    this.setState({
      error: error,
      errorInfo: errorInfo,
      offerReload: needsReload,
    });
    // You can also log error messages to an error reporting service here
    console.error(`Error caught: ${error.toString()}`, error);
  }

  testForReloadOffer = (errorString: string) => {
    let offer = false;
    try {
      offer = errorString.indexOf('Unexpected token <') > -1;
    } catch (error) {
      // No op
    }
    return offer;
  };

  handleReload = () => {
    window.location.reload();
  };

  renderMessage() {
    const { size } = this.props;
    let variant: string = 'h5';
    switch (size) {
      case 'xxs':
        variant = 'caption';
        break;
      case 'xs':
        variant = 'body2';
        break;
      case 'md':
        variant = 'body1';
        break;
      case 'lg':
      case 'xl':
      case 'xxl':
      default:
        variant = 'h5';
        break;
    }

    // @ts-ignore
    return <Typography variant={variant}>Something seems off</Typography>;
  }

  render() {
    const { userSuggestion, showReload } = this.props;
    const { offerReload } = this.state;

    const reloadButton =
      showReload || offerReload ? (
        <div>
          <Typography variant="h6">Please reload</Typography>
          <IconButton color="primary" aria-label="Reload" onClick={this.handleReload}>
            {/*<RefreshIcon name="PageRefresh" />*/}
          </IconButton>
        </div>
      ) : null;

    if (this.state.errorInfo) {
      return (
        <div style={{ marginLeft: '50px', marginTop: '50px' }}>
          {this.renderMessage()}
          {userSuggestion}
          {/*<RefreshIcon name="PageRefresh" />*/}
          {reloadButton}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
