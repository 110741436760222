import { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { useSettings, useSignedLinkAuth } from 'src/hooks';

const ApplicationPage = () => {
  const { themeStretch } = useSettings();
  const { isAuthenticated, login } = useSignedLinkAuth();

  useEffect(() => {
    if (isAuthenticated) return;

    login();
  }, [isAuthenticated, login]);

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Application Page
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApplicationPage;
