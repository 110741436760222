function compareStates(a: State, b: State) {
  if (a?.stateCode < b?.stateCode) {
    return -1;
  }
  if (a?.stateCode > b?.stateCode) {
    return 1;
  }
  return 0;
}

export const sortStates = (states: State[]) => states.sort(compareStates);

function compareNames(a: ReferenceDataTuple, b: ReferenceDataTuple) {
  if (a?.name < b?.name) {
    return -1;
  }
  if (a?.name > b?.name) {
    return 1;
  }
  return 0;
}

export const sortIndustries = (states: Industry[]) => states.sort(compareNames);

export interface ReferenceDataTuple {
  id?: string;
  name: string;
  value?: string;
  description?: string;
}

export interface Industry extends ReferenceDataTuple {
  NAICS: string;
  ntwEligible?: boolean;
}

export interface CapitalPurpose extends ReferenceDataTuple {
  minThreshold?: number;
  maxThreshold?: number;
}

export interface LegalEntityType extends ReferenceDataTuple {
  ntwEligible?: boolean;
}

export interface State extends ReferenceDataTuple {
  stateCode: string;
}
