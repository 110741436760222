import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormHelperText,
} from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { ReviewData } from 'src/@types/application-types';
import { RenderFormikTextField } from 'src/utils/formikFormUtils';

type SendToCustomerDialogProps = {
  data: ReviewData;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleAcceptOffer: (sendCustomerData?: ReviewData) => Promise<boolean>;
};

const SendToCustomerDialog: React.FC<SendToCustomerDialogProps> = ({
  data,
  onClose,
  handleAcceptOffer,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useSelector((state) => state.mainApp);

  const isProdEnv = useMemo(() => process.env.REACT_APP_ENVIRONMENT === 'PROD', []);

  const validationSchema = Yup.object().shape({
    owners: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required('Required'),
    isos: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required('Required'),
    ...(!isProdEnv && {
      testEmail: Yup.array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
        .required('Required'),
    }),
  });

  const onSubmit = async (values: ReviewData) => {
    try {
      const accepted = await handleAcceptOffer({ ...values });

      if (accepted) {
        enqueueSnackbar('Pre-approval sent to customer');
        onClose();
      }
    } catch (error) {
      enqueueSnackbar('Error to send customer data: Please try again!', { variant: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...data,
      ...(process.env.REACT_APP_ENVIRONMENT !== 'PROD' && {
        testEmail: userData?.emailAddress,
      }),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open
        onClose={onClose}
        fullWidth
        aria-labelledby="send-to-customer-title"
        aria-describedby="send-to-customer-description"
        data-testid={'zero-balance-letter-dialog'}
      >
        <DialogTitle id="send-to-customer-title">Review Recipients</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="send-to-customer-description">
            <Grid container spacing={2}>
              {!isProdEnv && (
                <Grid item xs={12}>
                  {RenderFormikTextField({
                    renderError:
                      Boolean(formik?.errors?.testEmail) && formik?.touched?.testEmail
                        ? true
                        : false,
                    helpers: { ...formik.getFieldProps('testEmail') },
                    label: 'Test Email',
                    required: true,
                    formik,
                  })}
                  <FormHelperText
                    error={false}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    Use comma separation for multiple emails
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                {RenderFormikTextField({
                  renderError:
                    Boolean(formik?.errors?.owners) && formik?.touched?.owners ? true : false,
                  helpers: { ...formik.getFieldProps('owners') },
                  label: 'Customer Email',
                  required: true,
                  formik,
                })}
                <FormHelperText
                  error={false}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Use comma separation for multiple emails
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                {RenderFormikTextField({
                  renderError:
                    Boolean(formik?.errors?.isos) && formik?.touched?.isos ? true : false,
                  helpers: { ...formik.getFieldProps('isos') },
                  label: 'ISO Email',
                  required: true,
                  formik,
                })}
                <FormHelperText
                  error={false}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Use comma separation for multiple emails
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            type={'submit'}
            disabled={!formik.isValid || formik.isSubmitting}
            sx={{ borderRadius: Number(theme.shape.borderRadius) * 2 }}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Send
          </Button>
          <Button
            variant={'outlined'}
            onClick={onClose}
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderRadius: Number(theme.shape.borderRadius) * 2,
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};

export default SendToCustomerDialog;
