import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material';
import { ACHVerificationStatusLabel } from '../../ACHVerificationStatus';

import { useSignedLinkAuth } from 'src/hooks';
import { useDispatch, useSelector } from 'src/redux/hooks';
import { getContract } from 'src/redux/slices/isoPortal';
import * as dealsApi from 'src/services/APIs/dealsApi';

const validationSchema = Yup.object().shape({
  bankName: Yup.string().max(250, 'Bank name too long').required('Required'),
  bankAccountName: Yup.string().max(250, 'Account name too long').required('Required'),
  bankAccountNumber: Yup.string().max(20, 'Account number too long').required('Required'),
  bankRountingNumber: Yup.string()
    .matches(/^\d{9}$/, 'Routing number is not valid')
    .max(9, 'Routing number too long')
    .required('Required'),
});

const ACHControl = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { dealId, epk, et } = useSignedLinkAuth();

  const contract = useSelector((state) => state.isoPortal.contract);

  const [showContent, setShowContent] = useState<boolean>(false);

  const handleClickShowContent = () => setShowContent(!showContent);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await dealsApi.postGeneratePreContractData({ ...values, dealId, epk, et });

        enqueueSnackbar('ACH Information was updated.');

        dispatch(getContract({ dealId, epk, et }));
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.message ?? 'Error generating pre contract data. Please try again!';

        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
    [dealId, epk, et, dispatch, enqueueSnackbar]
  );

  const formik = useFormik({
    initialValues: {
      bankName: contract?.bank?.name ?? '',
      bankAccountName: contract?.bank?.account_name ?? '',
      bankAccountNumber: contract?.bank?.account_number ?? '',
      bankRountingNumber: contract?.bank?.routing_number ?? '',
    },
    validationSchema,
    isInitialValid: false,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} id="ach-panel-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="ach-panel"
        id="ach-panel-header"
        sx={{ backgroundColor: theme.palette.background.neutral }}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography sx={{ fontWeight: 'bold' }}>Bank Account Information</Typography>
          <ACHVerificationStatusLabel />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        ACH Information
        <FormikProvider value={formik}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={(Boolean(formik?.errors?.bankName) && formik.touched.bankName) as boolean}
                helperText={formik.touched.bankName && formik.errors.bankName}
                disabled={contract?.bank?.ach_saved_locked_info}
                required
                fullWidth
                id="bankName"
                name="bankName"
                value={formik.values.bankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Bank Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={
                  (Boolean(formik?.errors?.bankAccountName) &&
                    formik.touched.bankAccountName) as boolean
                }
                helperText={formik.touched.bankAccountName && formik.errors.bankAccountName}
                disabled={contract?.bank?.ach_saved_locked_info}
                required
                fullWidth
                id="bankAccountName"
                name="bankAccountName"
                value={formik.values.bankAccountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Account Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={
                  (Boolean(formik?.errors?.bankAccountNumber) &&
                    formik.touched.bankAccountNumber) as boolean
                }
                helperText={formik.touched.bankAccountNumber && formik.errors.bankAccountNumber}
                disabled={contract?.bank?.ach_saved_locked_info}
                required
                fullWidth
                id="bankAccountNumber"
                name="bankAccountNumber"
                value={formik.values.bankAccountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Account Number"
                type={showContent ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowContent}
                        edge="end"
                        aria-label="Show/Hide"
                      >
                        {showContent ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={
                  (Boolean(formik?.errors?.bankRountingNumber) &&
                    formik.touched.bankRountingNumber) as boolean
                }
                helperText={formik.touched.bankRountingNumber && formik.errors.bankRountingNumber}
                disabled={contract?.bank?.ach_saved_locked_info}
                required
                fullWidth
                id="bankRountingNumber"
                name="bankRountingNumber"
                value={formik.values.bankRountingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Routing Number"
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginBottom: theme.spacing(2) }}
                disabled={contract?.bank?.ach_saved_locked_info || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormikProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default ACHControl;
