import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import MainFooter from './MainFooter';
import OffersHeader from './OffersHeader';

export default function OffersLayout() {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <OffersHeader />
      <Outlet />
      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </Stack>
  );
}
