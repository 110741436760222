import { ReactNode, useEffect } from 'react';
import moment from 'moment';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSignedLinkAuth } from 'src/hooks/useAuth';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

type SignedLinkGuardProps = {
  children: ReactNode;
};

// This is simply to enforce that we have an EPK. We will do the
// comparison in the service.
const VerifyEPK = (epk: string) => epk !== '';

// Test Link:
// http://localhost:8080/offers/customer?dealId=1000&selectedOfferIndex=0&remittanceSchedule=daily&disclosuresAccepted=0&epk=<GENERATE_THIS>&et=333322222222
export default function SignedLinkGuard({ children }: SignedLinkGuardProps) {
  const { isAuthenticated, isInitialized, login } = useSignedLinkAuth();
  const [searchParams] = useSearchParams();

  const expirationTime = searchParams.get('et') ?? localStorage.getItem('et') ?? 0;
  const epk = searchParams.get('epk') ?? localStorage.getItem('epk') ?? '';

  useEffect(() => {
    if (isAuthenticated) return;

    login();
  }, [isAuthenticated, login]);

  if (!isAuthenticated && !isInitialized) {
    return <LoadingScreen />;
  }

  // Otherwise, verify that the link is still active
  const current_time = moment().unix();

  if (current_time > expirationTime) {
    return <Navigate to={PATH_PAGE.page403Offers} />;
  }

  if (!VerifyEPK(epk) || !isAuthenticated) {
    return <Navigate to={PATH_PAGE.page403} />;
  }

  return <>{children}</>;
}
