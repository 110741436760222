import axios from '../merchantPortalService';
import { Application, OwnerInfo } from 'src/@types/application-types';

const getSignedLinkQueryParams = (et: string, epk: string) =>
  epk.length > 0 && et.length > 0 ? `?epk=${epk}&et=${et}` : '';

export const fetchApplications = async () => {
  try {
    const response = await axios.get('/api/v1/application');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const fetchApplication = async (appId: string) => {
  try {
    const response = await axios.get(`/api/v1/application/${appId}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const postApplication = async (application: Application) => {
  await axios.post('/api/v1/applicant', { application });
};

export const putApplicationApplicant = async (application: Application) => {
  await axios.put('/api/v1/applicant', application);
};

export const putApplication = async (application: Application) => {
  await axios.put(`/api/v1/application/${application.appId}`, application);
};

export const deleteApplication = async (application: Application) => {
  await axios.delete(`/api/v1/application/${application.appId}`);
};

export const submitApplication = async (
  application: Application,
  sendForESIGN: boolean = false,
  includeStips: boolean = false
) => {
  // @ts-ignore
  delete application?.stips;
  return axios.put(
    `/api/v1/application/${application.appId}/submit?sendForESIGN=${sendForESIGN}&includeStips=${includeStips}`,
    application
  );
};

export const submitApplicationStips = async (application: Application) => {
  await axios.put(`/api/v1/application/${application.appId}/submit-stipulations`, application);
};

export const postOwner = async (ownerInfo: OwnerInfo) => {
  await axios.post('/api/v1/owner', ownerInfo);
};

export const submitStips = async (payload: any) =>
  axios.post(`/api/v1/${payload.appId}/stips`, {
    stips: payload.stips,
    eventName: payload.eventName,
  });

export const submitBankStatementStips = async (payload: any) =>
  axios.post(`/api/v1/${payload.appId}/stips/bankStatements`, {
    stips: payload.stips,
  });

export const deleteStip = async (payload: any) => {
  await axios.delete(`/api/v1/stips?stipId=${payload.stipId}`);
};

export const getStips = async (payload: any) => axios.get(`/api/v1/${payload.appId}/stips`);

export const getStipulationsFromCodat = async (appId: string) => {
  try {
    const response = await axios.get(`/api/v1/application/${appId}/stipulations/codat`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

export const postStipulationsToCodat = async (appId: string, stips: any) => {
  try {
    const response = await axios.post(`/api/v1/application/${appId}/stipulations/codat`, stips);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

type SignedLinkProps = {
  codatId: string;
  dealId?: string;
  epk?: string;
  et?: string;
};

export const getCodatLinkStatus = async (props: SignedLinkProps) => {
  const { codatId, dealId, epk = '', et = '' } = props;

  try {
    const { data } = await axios.get<{ linked: boolean }>(
      `/api/v1/application/${dealId ?? 'deal'}/codat/${codatId}/isLinked${getSignedLinkQueryParams(
        et,
        epk
      )}`
    );

    return data.linked;
  } catch (error) {
    console.error(error);
  }

  return false;
};
