import { Theme } from '@mui/material/styles';
//

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          borderRadius: '0px',
          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.neutral,
            // paddingLeft: theme.spacing(1),
          },
        },
      },
    },
  };
}
