// ----------------------------------------------------------------------

export type Profile = {
  id: string;
  position: string;
  email: string;
  company: string;
  role: string;
};

export type UserManager = {
  clientId: string;
  id: string;
  authID: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  createdAt: Date;
  updatedAt: Date;
  avatarUrl: string;
  role: string;
};

export type AuthUserData = {
  clientId: string;
  userId: string;
  authID?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  roles?: string[];
};

export type UserData = {
  clientId: string;
  id: string;
  authID: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  createdAt: Date;
  updatedAt: Date;
  avatarUrl: string;
  name: string;
  position: string;
  isLibertasUser?: boolean;
};

export const defaultUserData: UserData = {
  clientId: '',
  id: '',
  authID: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  avatarUrl: '',
  name: '',
  position: '',
};

export type NotificationSettings = {
  activityComments: boolean;
  activityAnswers: boolean;
  activityFollows: boolean;
  applicationNews: boolean;
  applicationProduct: boolean;
  applicationBlog: boolean;
};
