function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';
const ROOTS_PARTNER = '/partner';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  register: path(ROOTS_AUTH, '/register'),
  registerSuccess: path(ROOTS_AUTH, '/register-success'),
  registerISO: path(ROOTS_AUTH, '/register-iso'),
  registerISOSuccess: path(ROOTS_AUTH, '/register-iso-success'),
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  mcaApplicationLanding: '/apply-mca',
  mcaLeads: '/mca-leads',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  page403: '/403',
  page403Offers: '/403Offers',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    applicationWizard: path(ROOTS_DASHBOARD, '/application/wizard'),
    applicationForm: path(ROOTS_DASHBOARD, '/application/form'),
  },
  merchantPortal: {
    root: path(ROOTS_DASHBOARD, '/mpapp'),
    dashboard: path(ROOTS_DASHBOARD, '/mpapp/dashboard'),
    apply: path(ROOTS_DASHBOARD, '/mpapp/apply'),
    applications: path(ROOTS_DASHBOARD, '/mpapp/applications'),
    applicationWizard: path(ROOTS_DASHBOARD, '/mpapp/application/wizard'),
    applicationForm: path(ROOTS_DASHBOARD, '/mpapp/application/form'),
    offerCalculator: path(ROOTS_DASHBOARD, '/mpapp/application/offer-calc'),
    fundedDeals: path(ROOTS_DASHBOARD, '/mpapp/funded-deals'),
  },
  isoPortal: {
    root: path(ROOTS_DASHBOARD, '/isoapp'),
    dashboard: path(ROOTS_DASHBOARD, '/isoapp/dashboard'),
    applications: path(ROOTS_DASHBOARD, '/isoapp/applications'),
    applicationForm: path(ROOTS_DASHBOARD, '/isoapp/application/form'),
    offerCalculator: path(ROOTS_DASHBOARD, '/isoapp/application/offer-calc'),
  },
  offers: {
    root: path(ROOTS_DASHBOARD, '/offers'),
    offersCustomer: path(ROOTS_DASHBOARD, '/customer'),
    offersISO: path(ROOTS_DASHBOARD, '/iso'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  customerService: {
    root: path(ROOTS_DASHBOARD, 'support'),
    dashboard: path(ROOTS_DASHBOARD, '/support/dashboard'),
    administration: path(ROOTS_DASHBOARD, '/support/administration'),
    applications: path(ROOTS_DASHBOARD, '/support/applications'),
    clients: path(ROOTS_DASHBOARD, '/support/clients'),
    fundedClients: path(ROOTS_DASHBOARD, '/support/fundedClients'),
    users: path(ROOTS_DASHBOARD, '/support/users'),
    userNew: path(ROOTS_DASHBOARD, '/support/user/new'),
    userList: path(ROOTS_DASHBOARD, '/support/user/list'),
  },
  partner: {
    root: path(ROOTS_PARTNER, ''),
    status: path(ROOTS_PARTNER, '/status'),
    application: path(ROOTS_PARTNER, '/application'),
    stipulations: path(ROOTS_PARTNER, '/stipulations'),
    documents: path(ROOTS_PARTNER, '/documents'),
  },
};
