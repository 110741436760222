import { useSnackbar } from 'notistack';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Avatar,
  Divider,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  FormHelperText,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { fCurrencyShort } from 'src/utils/formatNumber';
import { Iconify } from 'src/components';
import { formatDateShort } from 'src/utils/formatting';

const OverlayStyle = styled('div')(() => ({
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

interface DisclosureCardProps {
  documents: any;
  onClose: () => void;
  onDownload: any;
  setDocumentSelected: any;
}
export const DisclosureCard = ({
  documents,
  setDocumentSelected,
  onDownload,
}: DisclosureCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        textAlign: 'center',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.neutral,
        p: 1,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Avatar
          alt={'Disclosure'}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
            color: theme.palette.primary.main,
          }}
        >
          <AssignmentIcon />
        </Avatar>
        <OverlayStyle />
      </Box>

      <Stack direction="row" justifyContent="center" pt={1}>
        <Typography variant="h5" sx={{ mt: 3 }}>
          Disclosure
        </Typography>
      </Stack>

      <Box
        sx={{
          py: 3,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          textAlign: 'center',
        }}
      >
        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Amount
          </Typography>
          <Typography variant="subtitle1">
            {documents?.contract?.fundedAmount
              ? fCurrencyShort(documents.contract.fundedAmount)
              : '-'}
          </Typography>
        </div>

        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            State
          </Typography>
          <Typography variant="subtitle1">{documents?.disclosure?.state ?? '-'}</Typography>
        </div>
      </Box>

      <Divider />

      <Stack direction="row" justifyContent="center" pt={1}>
        <Tooltip title={`View Disclosure`}>
          <IconButton
            aria-label="Show"
            onClick={() => {
              if (!documents.disclosure?.fileUrl) {
                enqueueSnackbar(`Disclosure not currently available to view`, {
                  variant: 'warning',
                });
                return;
              }

              setDocumentSelected({
                id: 'disclosure',
                fileUrl: documents.disclosure?.fileUrl ?? '',
              });
            }}
          >
            <Iconify icon={'eva:eye-fill'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download Disclosure">
          <IconButton
            onClick={() => {
              if (!documents.disclosure?.fileUrl) {
                enqueueSnackbar(`Disclosure not currently available for download`, {
                  variant: 'warning',
                });
                return;
              }

              onDownload(documents.disclosure?.fileUrl ?? '');
            }}
            aria-label="Download"
          >
            <Iconify icon={'eva:download-fill'} />
          </IconButton>
        </Tooltip>
      </Stack>
      {!documents.disclosure?.fileUrl && (
        <Stack direction="row" justifyContent="center" pb={1}>
          <FormHelperText>Disclosure not currently available</FormHelperText>
        </Stack>
      )}

      {documents.disclosure?.createdAt && (
        <Stack direction="row" justifyContent="flex-end">
          <FormHelperText>
            Generated at: {formatDateShort(documents.disclosure.createdAt)}
          </FormHelperText>
        </Stack>
      )}
    </Stack>
  );
};
