import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserData } from 'src/@types/user';
import { ClientData } from 'src/@types/client';
import * as adminAPI from 'src/services/APIs/adminAPI';

// ----------------------------------------------------------------------

export type AdminState = {
  isLoading: boolean;
  error: Error | string | null;
  users: UserData[];
  client: ClientData | null;
};

export const initialState: AdminState = {
  isLoading: false,
  error: null,
  users: [] as UserData[],
  client: null,
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Data
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    updateUsersData(state, action) {
      state.users = action.payload;
    },

    resetUsersData(state) {
      state.users = [];
    },

    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    updateClientData(state, action) {
      state.client = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetUsersData, updateUsersData } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const getClientData = createAsyncThunk(
  'getClientData',
  async (clientId: string, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    try {
      const client: ClientData = await adminAPI.fetchClient(clientId);
      if (client) {
        dispatch(slice.actions.getClientSuccess(client));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const updateClientData = createAsyncThunk(
  'updateClientData',
  async (payload: any, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    try {
      const client: ClientData = await adminAPI.putClient(payload.clientData);
      if (client) {
        dispatch(slice.actions.getClientSuccess(client));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getUsers = createAsyncThunk('getUsers', async (_, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  try {
    const users: UserData[] = await adminAPI.fetchUsers();
    if (users) {
      dispatch(slice.actions.getUsersSuccess(users));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
});
