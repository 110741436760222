import { ReactNode } from 'react';
import { enUS } from '@mui/material/locale';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';

type Props = {
  children: ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  const defaultTheme = useTheme();

  const theme = createTheme(defaultTheme, enUS);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
