import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type PayoffLetterRequestSubmittedDialogProps = {
  onClose: () => void;
};

export default function PayoffLetterRequestSubmittedDialog(
  props: PayoffLetterRequestSubmittedDialogProps
) {
  const { onClose } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="request-submitted-title"
      aria-describedby="request-submitted-description"
      data-testid={'payoff-letter-dialog'}
    >
      <DialogTitle id="request-submitted-title">Request Submitted!</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="request-submitted-description">
          Your Payoff letter request has been successfully submitted. A member of the Customer
          Service team will email you within 48 hours.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={handleClose}
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            borderRadius: Number(theme.shape.borderRadius) * 2,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
