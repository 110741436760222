import React, { useState } from 'react';
import cuid from 'cuid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Alert,
  Link,
  Typography,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { PATH_AUTH } from 'src/routes/paths';
import { AuthOptions, UserRoleTypes } from 'src/@types/auth';
import {
  TERMS_OF_USE,
  CA_PRIVACY_NOTICE,
  PRIVACY_POLICY,
} from 'src/portals/components/compliance/config';
import { ESignConsentAgreement } from 'src/portals/components/dialogs';
import queryString from 'query-string';

export type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  legalBusinessName: string;
  entityId: string;
  referralCode?: string;
  afterSubmit?: string;
};

export type RegisterFormProps = {
  role: UserRoleTypes;
  showLegalAgreements?: boolean;
  isExistingClient?: boolean;
  onRegistrationComplete?: () => void;
};

export type MarketingQueryParamsProps = {
  referralCode?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  gclid?: string;
};

export const parseMarketingQueryParams = (search: string) => {
  const marketingParams = queryString.parse(search, {
    decode: false,
  }) as MarketingQueryParamsProps;

  return {
    referralCode: marketingParams?.referralCode ?? '',
    utmSource: marketingParams?.utm_source ?? '',
    utmMedium: marketingParams?.utm_medium ?? '',
    utmCampaign: marketingParams?.utm_campaign ?? '',
    utmTerm: marketingParams?.utm_term ?? '',
    marketingId: marketingParams?.gclid ?? '',
  } as AuthOptions;
};

export default function RegisterForm({
  role,
  showLegalAgreements = false,
  isExistingClient = false,
  onRegistrationComplete,
}: RegisterFormProps) {
  const { register } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [acceptedESignConsentAgreement, setAcceptedESignConsentAgreement] = useState(
    !showLegalAgreements
  );
  const [showESignConsentAgreement, setShowESignConsentAgreement] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .min(8)
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      )
      .required('Password is required'),
    legalBusinessName: Yup.string().required('Business name is required'),
    entityId: Yup.string(),
  });

  const options = parseMarketingQueryParams(search);
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: isExistingClient ? `L1${cuid()}!` : '',
    legalBusinessName: '',
    referralCode: options.referralCode ? options.referralCode : '',
    role,
    entityId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const handleShowESignConsentAgreementDialog = () => {
    setShowESignConsentAgreement(true);
  };

  const handleAcceptedESignConsentAgreement = () => {
    setAcceptedESignConsentAgreement(true);
    setShowESignConsentAgreement(false);
  };

  const handleDeclinedESignConsentAgreement = () => {
    setAcceptedESignConsentAgreement(false);
    setShowESignConsentAgreement(false);
  };

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const options = parseMarketingQueryParams(search);
      await register(
        data.legalBusinessName,
        data.email,
        data.password,
        data.firstName,
        data.lastName,
        data.entityId,
        role,
        {
          ...options,
          referralCode: data?.referralCode || '',
        }
      );

      enqueueSnackbar('Successfully Registered');
      if (!isExistingClient) {
        navigate(`${PATH_AUTH.registerSuccess}${search}`);
      } else {
        onRegistrationComplete?.();
      }
    } catch (error) {
      if (isMountedRef.current) {
        let message = 'Registration Error: Please try again or contact support';
        let errorSummary = '';
        if (error.status === 400) {
          if (error.errorCauses.length > 0) {
            errorSummary = error.errorCauses[0];
          }
        }
        if (error.status === 889) {
          message = message + ' Code: 889';
        }
        console.error(errorSummary, error);
        setError('afterSubmit', { ...error, message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField
          name="legalBusinessName"
          label="Legal Business Name"
          required={true}
          InputLabelProps={{
            sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
            shrink: true,
          }}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            name="firstName"
            label="First name"
            required={true}
            InputLabelProps={{
              sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
              shrink: true,
            }}
          />
          <RHFTextField
            name="lastName"
            label="Last name"
            required={true}
            InputLabelProps={{
              sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
              shrink: true,
            }}
          />
        </Stack>

        <RHFTextField
          name="email"
          label="Email address"
          required={true}
          InputLabelProps={{
            sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
            shrink: true,
          }}
        />

        <RHFTextField
          name="password"
          label="Password"
          id="registration-password"
          required={true}
          disabled={isExistingClient}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" aria-labelledby="registration-password">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Show/Hide"
                >
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
            shrink: true,
          }}
        />

        {!isExistingClient && (
          <RHFTextField
            name="referralCode"
            label="Referral Code"
            required={false}
            InputLabelProps={{
              sx: { color: 'white', '&.Mui-focused': { color: 'white' } },
              shrink: true,
            }}
          />
        )}

        {isExistingClient && (
          <RHFTextField
            name="entityId"
            label="Company Id"
            InputLabelProps={{
              sx: { color: 'red', '&.Mui-focused': { color: 'green' } },
            }}
            required={false}
          />
        )}

        {showLegalAgreements && (
          <Grid container>
            <Grid item xs={1}>
              <Checkbox
                onChange={handleShowESignConsentAgreementDialog}
                checked={acceptedESignConsentAgreement}
                value={acceptedESignConsentAgreement}
                id={'esign-disclosure-consent'}
                aria-label={'esign-disclosure-consent'}
                inputProps={{
                  'aria-label': 'esign-disclosure-consent',
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography
                align={'left'}
                variant="body2"
                id="esign-disclosure-consent-info"
                sx={{ color: '#fff' }}
              >
                By checking here you agree that you have read the Libertas ESIGN Disclosure and
                Consent and agree to do business electronically
              </Typography>
            </Grid>
          </Grid>
        )}
        {showESignConsentAgreement && (
          <ESignConsentAgreement
            onAccept={handleAcceptedESignConsentAgreement}
            onDecline={handleDeclinedESignConsentAgreement}
          />
        )}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!acceptedESignConsentAgreement}
        >
          Register
        </LoadingButton>
        {showLegalAgreements && (
          <>
            <Typography variant="body2" align="center" sx={{ mt: 4, color: '#fff' }}>
              By registering, I agree to Libertas Funding&nbsp;
              <Link
                underline="always"
                href={TERMS_OF_USE}
                sx={{ color: '#35e9ac' }}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Use
              </Link>
              {', '}
              <Link
                underline="always"
                href={PRIVACY_POLICY}
                sx={{ color: '#35e9ac' }}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </Link>
              , and{' '}
              <Link
                underline="always"
                href={CA_PRIVACY_NOTICE}
                sx={{ color: '#35e9ac' }}
                rel="noreferrer"
                target="_blank"
              >
                CA Privacy Notice
              </Link>
              .
            </Typography>
          </>
        )}
      </Stack>
    </FormProvider>
  );
}
