import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/redux/hooks';
import { LoadingButton } from '@mui/lab';
import { useSignedLinkAuth } from 'src/hooks';
import * as dealsApi from 'src/services/APIs/dealsApi';
import * as applicationsAPI from 'src/services/APIs/applicationsAPI';
import { getDealSelector } from 'src/redux/selectors/isoPortal';

export const LinkAccountsRequestButton = () => {
  const deal = useSelector(getDealSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { epk, et } = useSignedLinkAuth();

  const [accountLinked, setAccountLinked] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const codatID = useMemo(() => deal?.company?.codatCompanyId, [deal?.company?.codatCompanyId]);

  const handleCreateAndSendLinkRequest = useCallback(async () => {
    if (!deal?.dealId) return;

    setSending(true);

    try {
      console.log(`Sending link request`);

      await dealsApi.postSendCodatLinkRequest({ dealId: deal.dealId, epk, et });

      enqueueSnackbar('Link request has been sent');
    } catch (error) {
      enqueueSnackbar('Error while send link request. Please try again!', { variant: 'error' });
    } finally {
      setSending(false);
    }
  }, [deal?.dealId, epk, et, enqueueSnackbar]);

  useEffect(() => {
    if (!codatID) return;

    console.log(`Checking for account link CodatID: ${codatID}`);

    const getAccountLinkStatus = async (codatId: string) => {
      const linked = await applicationsAPI.getCodatLinkStatus({
        codatId,
        dealId: deal?.dealId,
        epk,
        et,
      });

      setAccountLinked(linked);
    };

    getAccountLinkStatus(codatID);
  }, [deal?.dealId, codatID, epk, et]);

  return (
    <LoadingButton
      fullWidth
      variant="contained"
      loading={sending}
      disabled={accountLinked}
      onClick={handleCreateAndSendLinkRequest}
      sx={{ minWidth: '200px', mb: 1 }}
    >
      {accountLinked ? 'Plaid Linked' : 'Send link request'}
    </LoadingButton>
  );
};
