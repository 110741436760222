import React, { useEffect, useState, useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import { useAuth0 } from '@auth0/auth0-react';
import packageJson from '../package.json';
import Router from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import LoadingScreen from './components/LoadingScreen';
import useAuth from './hooks/useAuth';
import { getApplications } from './redux/slices/applications';
import { getFundedDealsByCompanyId } from './redux/slices/fundedDeals';
import { getReferenceData } from './redux/slices/referenceData';
// import { selectCapitalPurposes } from './redux/selectors/referenceData';
import { useDispatch, useSelector } from './redux/hooks';
import { getClientData } from './redux/slices/admin';
import VerifyEmail from './pages/auth/VerifyEmail';
import { NotificationCenter } from './NotificationCenter';

export default function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  const dispatch = useDispatch();
  const { isLoading, error, isAuthenticated } = useAuth0();
  const { isInitialized } = useAuth();
  //  const capitalPurposes = useSelector(selectCapitalPurposes);
  const { isLoading: gettingUserData } = useSelector((state) => state.mainApp);
  const [forcedReloadCount, setForcedReloadCount] = useState(0);

  const loading = useMemo(
    () => isLoading || gettingUserData || (isAuthenticated && !isInitialized),
    [isLoading, gettingUserData, isAuthenticated, isInitialized]
  );

  // Note: We're using this section as the immediate after login
  // loading of fundamental bootstrap data for the application.
  useEffect(() => {
    // We should always have basic reference data. It not, reload the store.
    let forceReload = false; // capitalPurposes.length === 0;

    // if (forceReload) {
    //   setForcedReloadCount(forcedReloadCount + 1);
    //   if (forcedReloadCount > 3) {
    //     forceReload = false;
    //   }
    // }

    // isLoading - is Auth0 done loading
    // isAuthenticated - (here) is Auth0 Authenticated
    // isInitialized - Has our application initialized from authentication data
    //   - we have a clientId
    //   - we've loaded the profile based on it
    //   - we've set up our needed tokens for securing API calls
    if ((!isLoading && isAuthenticated && isInitialized) || forceReload) {
      console.log(`Reloading Store: Forced: ${forceReload} ${forcedReloadCount} times`);
      dispatch(getClientData(localStorage.getItem('clientId') as string));
      dispatch(getApplications());
      dispatch(getFundedDealsByCompanyId());
      dispatch(getReferenceData());
    }
  }, [
    isLoading,
    isAuthenticated,
    isInitialized,
    dispatch,
    setForcedReloadCount,
    // capitalPurposes.length,
    forcedReloadCount,
  ]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <VerifyEmail />;
  }

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isProduction}
      isVerboseMode={true}
      loadingComponent={<LoadingScreen />}
    >
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
              <NotificationCenter />
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </CacheBuster>
  );
}
