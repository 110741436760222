import * as React from 'react';
import { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionContainerProps {
  title: string;
  children: ReactNode;
}

export default function AccordionContainer({ title, children }: AccordionContainerProps) {
  return (
    <div>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        id={`accordion-container-control-${title}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="content-panel"
          id="content-panel-header"
        >
          <Typography fontWeight={500}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
