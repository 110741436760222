import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fCurrencyShort } from 'src/utils/formatNumber';
import { Approval } from 'src/@types/approval';
import { OfferProperties } from 'src/@types/offer-properties';
import { PartnerOfferRow } from './PartnerOfferRow';

interface PreapprovalRowItemProps {
  index: number;
  approval: Approval;
  offerProperties: OfferProperties;
  //eslint-disable-next-line
  onOfferClick: (index: number) => void;
}

const PreapprovalRowItem = React.memo((props: PreapprovalRowItemProps) => {
  const theme = useTheme();

  const selectable = useMemo(() => {
    if (props.offerProperties.hasLockedOffer)
      return props.offerProperties.selectedOfferIndex === props.index;

    return (
      Number(props.offerProperties.requestedAmount) <= props.offerProperties.maximumFunding &&
      props.offerProperties.commission <= Number(props.offerProperties.maxCommission)
    );
  }, [
    props.offerProperties.requestedAmount,
    props.offerProperties.maximumFunding,
    props.offerProperties.commission,
    props.offerProperties.maxCommission,
    props.index,
    props.offerProperties.hasLockedOffer,
    props.offerProperties.selectedOfferIndex,
  ]);

  const totalRepayment = useMemo(
    () => fCurrencyShort(props.offerProperties.requestedAmount! * props.approval.factorRate),
    [props.offerProperties.requestedAmount, props.approval.factorRate]
  );

  const totalCommission = useMemo(
    () =>
      fCurrencyShort(props.offerProperties.requestedAmount! * Number(props.approval.commission)),
    [props.offerProperties.requestedAmount, props.approval.commission]
  );

  const handleClick = () => {
    if (props.offerProperties.hasLockedOffer) return;

    props.onOfferClick(props.index);
  };

  return (
    <Grid
      container
      pl={2}
      pb={1}
      pt={1}
      mb={1}
      sx={{
        border: !selectable
          ? `${theme.palette.grey[100]} 1px solid`
          : `${theme.palette.primary.main} 1px solid`,
        borderRadius: '4px',
        color: !selectable ? `${theme.palette.grey[300]}` : `${theme.palette.grey[900]}`,
        boxShadow:
          props.offerProperties.selectedOfferIndex === props.index
            ? !props.offerProperties.hasLockedOffer
              ? theme.customShadows.primary
              : ''
            : '',
        borderLeft:
          props.offerProperties.selectedOfferIndex === props.index
            ? `${theme.palette.primary.main} 5px solid`
            : '',
        cursor: selectable ? 'pointer' : '',
      }}
      aria-disabled={!selectable}
      onClick={selectable ? handleClick : () => {}}
    >
      <PartnerOfferRow
        approval={props.approval}
        amountSeeking={props.offerProperties.requestedAmount}
        totalRepayment={totalRepayment}
        totalCommission={totalCommission}
      />
    </Grid>
  );
});

export default PreapprovalRowItem;
