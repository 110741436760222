import { createSelector } from '@reduxjs/toolkit';
import { MerchantPortalState } from '../initialState';

const getDeal = (state: MerchantPortalState) => state.isoPortal.deal;
const getDealRequiredStips = (state: MerchantPortalState) => state.isoPortal.requiredStips;
const getDealCalc = (state: MerchantPortalState) => state.isoPortal.dealCalc;
const getOfferProperties = (state: MerchantPortalState) => state.isoPortal.offerProperties;
const getApprovals = (state: MerchantPortalState) => state.isoPortal.approvals;
const getDealDocuments = (state: MerchantPortalState) => state.isoPortal.documents;
const getRequestContractProperties = (state: MerchantPortalState) =>
  state.isoPortal.requestContractProperties;
const getContract = (state: MerchantPortalState) => state.isoPortal.contract;

export const getDealSelector = createSelector(getDeal, (deal) => deal);
export const getDealRequiredStipsSelector = createSelector(
  getDealRequiredStips,
  (dealRequiredStips) => dealRequiredStips
);
export const getDealCalcSelector = createSelector(getDealCalc, (dealCalc) => dealCalc);
export const getOfferPropertiesSelector = createSelector(
  getOfferProperties,
  (offerProperties) => offerProperties
);
export const getApprovalsSelector = createSelector(getApprovals, (approvals) => approvals || []);
export const getDocumentsCountSelector = createSelector(
  getDealDocuments,
  // @ts-ignore
  (documents) => Object.keys(documents)?.filter((x) => !!documents[x])?.length ?? 0
);
export const getDealDocumentsSelector = createSelector(
  getDealDocuments,
  // @ts-ignore
  (dealDocuments) => dealDocuments
);
export const getRequestContractPropertiesSelector = createSelector(
  getRequestContractProperties,
  (requestContractProperties) => requestContractProperties
);

export const getContractSelector = createSelector(getContract, (contract) => contract);
