const CA_AMOUNT_REQUIRED = 500_000;

const NY_AMOUNT_REQUIRED = 2_500_000;

export const isRegulatedOffer = (state: string, fundedAmount: number) => {
  const locationState = state?.toLowerCase()?.trim() ?? '';

  const isCA = locationState === 'california' || locationState === 'ca';
  if (isCA && fundedAmount <= CA_AMOUNT_REQUIRED) {
    return true;
  }

  const isVA = locationState === 'virginia' || locationState === 'va';
  if (isVA) {
    return true;
  }

  const isUT = locationState === 'utah' || locationState === 'ut';
  if (isUT) {
    return true;
  }

  const isNY = locationState === 'new york' || locationState === 'ny';

  if (isNY && fundedAmount <= NY_AMOUNT_REQUIRED) {
    return true;
  }

  return false;
};
