export enum RemittanceScheduleTypes {
  // eslint-disable-next-line no-unused-vars
  Daily = 'daily',
  // eslint-disable-next-line no-unused-vars
  Weekly = 'weekly',
  // eslint-disable-next-line no-unused-vars
  BiWeekly = `bi_weekly`,
  // eslint-disable-next-line no-unused-vars
  Monthly = 'monthly',
}

export const RemittanceScheduleTypeDisplay = (type: RemittanceScheduleTypes) => {
  switch (type) {
    case RemittanceScheduleTypes.Monthly:
      return 'Monthly';
    case RemittanceScheduleTypes.BiWeekly:
      return 'Bi-Weekly';
    case RemittanceScheduleTypes.Weekly:
      return 'Weekly';
    case RemittanceScheduleTypes.Daily:
    default:
      return 'Daily';
  }
};
