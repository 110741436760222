import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
  useNew?: boolean;
  isWide?: boolean;
}

export default function Logo({ disabledLink = false, useNew = true, isWide = false, sx }: Props) {
  sx = isWide ? { width: 220, height: 50, ...sx } : { width: 40, height: 40, ...sx };
  const imgHeight = isWide ? 50 : 40;
  const logoUrl = !useNew
    ? '/logo/libertas-small-logo.png'
    : '/logo/LibertasFundingLogoSmall2023.png';
  const logo = (
    <Box sx={sx}>
      <img src={logoUrl} alt="Libertas" height={imgHeight} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export function LogoWithName({ disabledLink = false, sx }: Props) {
  sx = { width: 220, height: 50, ...sx };
  const imgHeight = 50;
  const logoUrl = '/logo/libertas-logo-horizontal.png';
  const logo = (
    <Box sx={sx}>
      <img src={logoUrl} alt="Libertas" height={imgHeight} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export function LogoWithName2({ disabledLink = false, sx }: Props) {
  sx = { width: 220, height: 50, ml: -3, ...sx };
  const imgHeight = 75;
  const logoUrl = '/logo/libertas-logo-horizontal.png';
  const logo = (
    <Box sx={sx}>
      <img src={logoUrl} alt="Libertas" height={imgHeight} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
