import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import cssStyles from 'src/utils/cssStyles';
import {
  GetNewPassingApplication,
  GetNewRejectedApplication,
  GetNewRouteToManualApplication,
} from 'src/_mock/application';
import { createNewApplication } from 'src/redux/slices/editApplication';
import { useDispatch } from 'src/redux/hooks';
import { GetDefaultNewApplication } from 'src/utils/applicationUtils';

const RootStyle = styled('span')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.64 }),
  right: '20px',
  top: '98%',
  position: 'fixed',
  marginTop: theme.spacing(0),
  padding: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 2,
  borderRadius: '24px 0 20px 24px',
  boxShadow: `-12px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
    0.36
  )}`,
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
}));

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PROD' || false;

export default function AdminControlsButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddNewEmptyApplicationForm = () => {
    const application = GetDefaultNewApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/form?appId=${application.appId}`);
  };

  const handleAddNewEmptyApplication = () => {
    const application = GetDefaultNewApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/wizard?appId=${application.appId}`);
  };

  const handleAddNewPassingApplication = () => {
    const application = GetNewPassingApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/wizard?appId=${application.appId}`);
  };

  const handleAddNewPassingApplicationForm = () => {
    const application = GetNewPassingApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/form?appId=${application.appId}`);
  };

  // Dormant: WIP
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleAddNewPassingApplicationFormWithBankPDFs = () => {
    const application = GetNewPassingApplication(true);
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/form?appId=${application.appId}`);
  };

  const handleAddNewRouteToManualApplicationForm = () => {
    const application = GetNewRouteToManualApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/form?appId=${application.appId}`);
  };

  const handleAddNewRejectedApplicationForm = () => {
    const application = GetNewRejectedApplication();
    dispatch(createNewApplication({ application }));
    navigate(`/app/application/form?appId=${application.appId}`);
  };

  return (
    <RootStyle>
      <StyledSpeedDial
        ariaLabel="Admin Controls"
        hidden={false}
        icon={<AdminPanelSettingsIcon />}
        direction={'up'}
      >
        {!isProd && [
          <SpeedDialAction
            key={'New-Passing-Application in Wizard'}
            icon={<DomainAddIcon color={'info'} />}
            tooltipTitle={'Create a new passing application (Wizard)'}
            onClick={handleAddNewPassingApplication}
          />,
          <SpeedDialAction
            key={'New-ISO-Passing-Application'}
            icon={<DomainAddIcon color={'success'} />}
            tooltipTitle={'Create a new passing application (Form)'}
            onClick={handleAddNewPassingApplicationForm}
          />,
          // Dormant: WIP
          // <SpeedDialAction
          //   key={'New-ISO-Passing-Application-With-Bank-PDF'}
          //   icon={<DomainAddIcon color={'success'} />}
          //   tooltipTitle={'Create a new passing application with Bank PDFs (Form)'}
          //   onClick={handleAddNewPassingApplicationFormWithBankPDFs}
          // />,
          <SpeedDialAction
            key={'New-ISO-Application-Manuel-Merchant'}
            icon={<DomainAddIcon color={'warning'} />}
            tooltipTitle={'Create a new "manual" application (Form)'}
            onClick={handleAddNewRouteToManualApplicationForm}
          />,
          <SpeedDialAction
            key={'New-ISO-Application-Rejected-Merchant'}
            icon={<DomainAddIcon color={'error'} />}
            tooltipTitle={'Create a new "rejected" application (Form)'}
            onClick={handleAddNewRejectedApplicationForm}
          />,
        ]}
        <SpeedDialAction
          key={'New-Empty-Application in Wizard'}
          icon={<PostAddIcon color={'info'} />}
          tooltipTitle={'Create a new empty application (Wizard)'}
          onClick={handleAddNewEmptyApplication}
        />
        <SpeedDialAction
          key={'New-Empty-Application in Form'}
          icon={<PostAddIcon color={'info'} />}
          tooltipTitle={'Create a new empty application (Form)'}
          onClick={handleAddNewEmptyApplicationForm}
        />
      </StyledSpeedDial>
    </RootStyle>
  );
}
