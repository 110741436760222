import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { maxBy, orderBy } from 'lodash';
import {
  convertMCAFDealApplicationToDealApplication,
  DealApplication,
  DealDocument,
  DealRequiredStip,
} from 'src/@types/mcafunds/deal-application';
import { Approval } from 'src/@types/approval';
import * as dealsApi from 'src/services/APIs/dealsApi';
import { OfferProperties } from 'src/@types/offer-properties';
import { RequestContractProperties } from 'src/@types/request-contract-properties';
import {
  createApprovalDataFromDealCalc,
  defaultRemittanceSchedules,
  getAllowedRemittanceSchedules,
} from 'src/portals/components/offers/deal-calc/utils';
import { RemittanceScheduleTypes } from 'src/@types/remittance-schedule-types';
import { getEstimatedRemittances, getRemittancesPayment } from 'src/utils/paymentUtils';

export type ISOPortalState = {
  loadingDeal: boolean;
  isLoading: boolean;
  error: Error | string | null;
  deal: DealApplication | null;
  offerProperties: OfferProperties;
  dealCalc: any | null;
  requiredStips: DealRequiredStip[];
  documents: DealDocument;
  approvals: Approval[];
  contract: any | null;
  requestContractProperties: RequestContractProperties;
};

export const initialState: ISOPortalState = {
  loadingDeal: true,
  isLoading: false,
  error: null,
  deal: null,
  offerProperties: {
    dealId: '',
    companyId: '',
    isoId: '',
    requestedAmount: 0,
    maximumFunding: 0,
    maximumTerm: 0,
    commission: 0,
    maxCommission: 0,
    remittanceSchedule: RemittanceScheduleTypes.Weekly,
    selectedOfferIndex: 0,
    selectedOfferTerm: 0,
    selectedOfferFactor: 0,
    selectedOfferBuyRate: 0,
    selectedOfferFee: 0,
    selectedOfferCommission: 0,
    selectedOfferCommissionAmount: 0,
    selectedOfferTotalRemittanceAmount: 0,
    selectedOfferRemittanceAmount: 0,
    selectedOfferTotalRemittances: 0,
    allowedRemittanceSchedules: defaultRemittanceSchedules,
    hasLockedOffer: false,
    stackPosition: 0,
    creditTier: '',
  },
  dealCalc: null,
  approvals: [],
  requiredStips: [],
  documents: {},
  contract: null,
  requestContractProperties: {
    dealId: '',
    note: '',
  },
};

const slice = createSlice({
  name: 'isoPortal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    startLoadingDeal(state) {
      state.loadingDeal = true;
    },

    getDealSuccess(state, action) {
      state.isLoading = false;
      state.deal = action.payload;
    },

    stopLoadingDeal(state) {
      state.loadingDeal = false;
    },

    getDealCalcSuccess(state, action) {
      state.isLoading = false;
      state.dealCalc = action.payload;
    },

    getDealRequiredStipsSuccess(state, action) {
      state.isLoading = false;
      state.requiredStips = action.payload;
    },

    getDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },

    getContractSuccess(state, action) {
      state.isLoading = false;
      state.contract = action.payload;
    },

    editDeal(state, action) {
      state.deal = action.payload;
      return state;
    },

    editOfferProperties(state, action) {
      state.offerProperties = action.payload;
      return state;
    },

    editDealCalc(state, action) {
      state.dealCalc = action.payload;
      return state;
    },

    editApprovals(state, action) {
      state.approvals = action.payload;
      return state;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
// Define functions that fetch/mutate data and update the Redux state

export const editOfferProperties = createAsyncThunk(
  'isoPortal/editOfferProperties',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      const {
        // @ts-ignore
        isoPortal: { offerProperties, approvals },
      } = getState();

      const updatedOfferProperties = {
        ...offerProperties,
        ...payload,
      };

      const index =
        payload.selectedOfferIndex >= 0
          ? payload.selectedOfferIndex
          : offerProperties.selectedOfferIndex;

      if (index > -1 && approvals.length > 0) {
        const selectedApproval = {
          ...approvals[index],
        };

        updatedOfferProperties.selectedOfferTerm = selectedApproval.term;
        updatedOfferProperties.selectedOfferFactor = selectedApproval.factorRate;
        updatedOfferProperties.selectedOfferBuyRate = selectedApproval.buyRate;
        updatedOfferProperties.selectedOfferFee = selectedApproval.fee;
        updatedOfferProperties.selectedOfferCommission = selectedApproval.commission;
        updatedOfferProperties.selectedOfferCommissionAmount =
          updatedOfferProperties.requestedAmount! * Number(selectedApproval.commission);
        updatedOfferProperties.selectedOfferRemittanceAmount = getRemittancesPayment(
          selectedApproval.remittanceSchedule,
          updatedOfferProperties.requestedAmount * selectedApproval.factorRate,
          +selectedApproval.term
        );
        updatedOfferProperties.selectedOfferTotalRemittances = getEstimatedRemittances(
          selectedApproval.remittanceSchedule,
          +selectedApproval.term
        );
        updatedOfferProperties.selectedOfferTotalRemittanceAmount =
          updatedOfferProperties.requestedAmount! * selectedApproval.factorRate;
      } else {
        updatedOfferProperties.selectedOfferTerm = 0;
        updatedOfferProperties.selectedOfferCommissionAmount = 0;
        updatedOfferProperties.selectedOfferRemittanceAmount = 0;
        updatedOfferProperties.selectedOfferTotalRemittances = 0;
        updatedOfferProperties.selectedOfferTotalRemittanceAmount = 0;
      }

      dispatch(slice.actions.editOfferProperties(updatedOfferProperties));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const editDealCalc = createAsyncThunk(
  'isoPortal/editDealCalc',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(slice.actions.editDealCalc(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const editApprovals = createAsyncThunk(
  'isoPortal/editApprovals',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(slice.actions.editApprovals(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getDealCalc = createAsyncThunk(
  'getDealCalc',
  async (payload: any, { dispatch, getState }) => {
    const { dealId, epk, et } = payload;
    dispatch(slice.actions.startLoading());
    try {
      const {
        // @ts-ignore
        isoPortal: { offerProperties },
      } = getState();

      const response: any = await dealsApi.getDealCalcByDealId(dealId, epk, et);
      const dealCalc = response.data;
      if (dealCalc) {
        console.log({ dealCalc });
        dispatch(slice.actions.getDealCalcSuccess(dealCalc));

        const approvalData = createApprovalDataFromDealCalc(dealCalc.saved_deal_data);
        dispatch(slice.actions.editApprovals(approvalData));

        const maxApprovalData = maxBy(approvalData, 'commission') as { commission?: number };
        const maxCommission =
          maxApprovalData?.commission ?? dealCalc?.iso_adjustments?.data?.commission ?? 0;
        const selectedOfferIndex = dealCalc?.saved_locked_data?.locked_deal?.approval
          ? dealCalc?.saved_locked_data?.locked_deal?.approval - 1
          : 0;
        const fundingAmounts = approvalData.map((a: any) => a.maximumAdvance);
        const maximumFunding = Math.max(...fundingAmounts);
        const remittanceSchedule =
          dealCalc?.saved_locked_data?.locked_deal?.payment_type ??
          dealCalc?.saved_deal_data?.override?.payment_type;

        const fundingTerms = approvalData.map((a: Approval) => a.term);
        const maximumTerm = Math.max(...fundingTerms);
        const requestedAmount =
          dealCalc?.saved_deal_data?.locked_deal?.funded_amount_raw ??
          dealCalc?.saved_deal_data?.selected_approvals?.rows?.at(0)?.funded_amount_raw ??
          0;

        const remittanceScheduleFromDeal =
          dealCalc?.saved_locked_data?.locked_deal?.payment_type ??
          dealCalc?.saved_deal_data?.override?.payment_type;

        const isProd = process.env.REACT_APP_ENVIRONMENT === 'PROD' || false;

        const allowedRemittanceSchedules = getAllowedRemittanceSchedules(
          requestedAmount,
          dealCalc?.location_state,
          remittanceScheduleFromDeal,
          approvalData,
          isProd
        );
        const hasLockedOffer = !!dealCalc.saved_locked_data?.locked_deal;
        const stackPosition = dealCalc.stacked_postion;
        const creditTier = dealCalc.credit_tier_override
          ? dealCalc.credit_tier_override
          : dealCalc.credit_tier;

        const updatedOfferProperties = {
          ...offerProperties,
          dealId,
          requestedAmount,
          maxCommission,
          commission: maxCommission,
          maximumFunding,
          remittanceSchedule,
          maximumTerm,
          selectedOfferIndex,
          allowedRemittanceSchedules,
          hasLockedOffer,
          stackPosition,
          creditTier,
        };
        dispatch(editOfferProperties(updatedOfferProperties));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getDeal = createAsyncThunk('getDeal', async (payload: any, { dispatch }) => {
  const { dealId, epk, et } = payload;
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startLoadingDeal());
  try {
    const deal: any = await dealsApi.getDealByDealId({ dealId, epk, et });
    if (deal) {
      const dealApplication: DealApplication = convertMCAFDealApplicationToDealApplication(
        deal,
        dealId
      );
      dispatch(slice.actions.getDealSuccess(dealApplication));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  } finally {
    dispatch(slice.actions.stopLoadingDeal());
  }
});

export const getContract = createAsyncThunk(
  'getContract',
  async (payload: any, { dispatch, getState }) => {
    const { dealId, epk, et } = payload;

    try {
      const {
        // @ts-ignore
        isoPortal: { offerProperties },
      } = getState();

      if (!offerProperties?.hasLockedOffer) return;

      dispatch(slice.actions.startLoading());
      const contract = await dealsApi.getDealContract(dealId, epk, et);

      if (contract) {
        dispatch(slice.actions.getContractSuccess(contract));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getRequiredStips = createAsyncThunk(
  'getRequiredStips',
  async (payload: any, { dispatch }) => {
    const { dealId, epk, et } = payload;

    dispatch(slice.actions.startLoading());
    try {
      const requiredStips = await dealsApi.getDealRequiredStips({ dealId, epk, et });

      if (requiredStips) {
        const data = orderBy(requiredStips, [
          'stipulation_id',
          'file_uploads_for_deal_application',
        ]).reduce((accumulator: any[], requiredStip: any) => {
          const name =
            requiredStip.stipulation?.display_text ??
            `Stipulation ID: ${requiredStip.stipulation_id}`;

          const alreadyExists = accumulator.some(
            (c) =>
              c.id === requiredStip.id &&
              c.stipulationId === requiredStip.stipulation_id &&
              c.name === name
          );

          if (!alreadyExists) {
            accumulator.push({
              id: requiredStip.id,
              stipulationId: requiredStip.stipulation_id,
              name,
              status: requiredStip.status,
              uploadName: requiredStip.file_uploads_for_deal_application?.upload_name,
            });
          }

          return accumulator;
        }, []);

        dispatch(slice.actions.getDealRequiredStipsSuccess(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const getDocuments = createAsyncThunk(
  'getDocuments',
  async (payload: any, { dispatch, getState }) => {
    const { dealId, epk, et } = payload;

    dispatch(slice.actions.startLoading());

    let contract;
    let disclosure;
    let itemization;

    try {
      const {
        // @ts-ignore
        isoPortal: { deal, contract: contractData },
      } = getState();

      try {
        const dealContract = contractData
          ? contractData
          : await dealsApi.getDealContract(dealId, epk, et);

        const [lastContract] = orderBy(dealContract?.contract_history ?? [], 'create_ts', 'desc');

        contract = {
          fileUrl: lastContract?.contract_url,
          createdAt: lastContract?.create_ts,
          fundedAmount: dealContract?.locked_deal?.funded_amount,
          buyRate: dealContract?.locked_deal?.buy_rate,
          sellRate: dealContract?.locked_deal?.sell_rate,
        };
      } catch (error) {
        if (error.response?.status !== 404) {
          dispatch(slice.actions.hasError(error));
          return;
        }
      }

      const state = deal?.company?.locationState ?? '';

      try {
        const disclosureUrl = await dealsApi.getDealDocument(
          dealId,
          'disclosure',
          'preapproval',
          epk,
          et
        );

        disclosure = {
          fileUrl: disclosureUrl,
          state,
        };
      } catch (error) {
        if (error.response?.status !== 404) {
          dispatch(slice.actions.hasError(error));
          return;
        }
      }

      try {
        const itemizationUrl = await dealsApi.getDealDocument(
          dealId,
          'itemization',
          'preapproval',
          epk,
          et
        );

        itemization = {
          fileUrl: itemizationUrl,
          state,
        };
      } catch (error) {
        if (error.response?.status !== 404) {
          dispatch(slice.actions.hasError(error));
          return;
        }
      }

      dispatch(slice.actions.getDocumentsSuccess({ contract, disclosure, itemization }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);

export const uploadDealStip = createAsyncThunk(
  'uploadDealStip',
  async (payload: any, { dispatch }) => {
    const { dealId, epk, et } = payload;
    try {
      const uploaded = await dealsApi.uploadDealStip(payload);
      if (!uploaded) {
        dispatch(
          slice.actions.hasError({
            message: 'Error while trying to upload stipulation, please retry or contact support',
          })
        );
        return;
      }

      dispatch(getRequiredStips({ dealId, epk, et }));
    } catch (error) {
      console.error(`Failed to upload stipulation for Deal: ${dealId}`);
      dispatch(
        slice.actions.hasError({
          message: 'Error while trying to upload stipulation, please retry or contact support',
        })
      );
    }
  }
);

export const editDeal = createAsyncThunk(
  'isoPortal/editDeal',
  // eslint-disable-next-line no-unused-vars
  async (payload: any, { dispatch, getState }) => {
    try {
      dispatch(slice.actions.editDeal(payload.deal));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
);
