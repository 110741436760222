import { useEffect, useMemo } from 'react';

import { useSelector } from 'src/redux/hooks';

import useAuth from './useAuth';

const useCurrentUser = () => {
  const { isLoading: gettingUserData, userData } = useSelector((state) => state.mainApp);
  const { user, isInitialized, isAuthenticated, logout } = useAuth();

  const isLibertasUser = useMemo(
    () =>
      user?.email?.endsWith('@libertasfunding.com') || user?.email?.endsWith('@whiteprompt.com'),
    [user?.email]
  );

  const roles = useMemo(() => user?.roles ?? ['customer'], [user?.roles]);

  useEffect(() => {
    if (!gettingUserData && isInitialized && isAuthenticated && !userData?.firstName) logout();
  }, [gettingUserData, isInitialized, isAuthenticated, userData?.firstName, logout]);

  return {
    ...userData,
    isLibertasUser,
    roles,
  };
};

export default useCurrentUser;
