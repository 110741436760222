import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/redux/hooks';

export const NotificationCenter = () => {
  const { enqueueSnackbar } = useSnackbar();

  const adminError = useSelector((state) => state.admin.error);
  const isoPortalError = useSelector((state) => state.isoPortal.error);
  const editApplicationError = useSelector((state) => state.editApplication.error);
  const applicationsError = useSelector((state) => state.applications.error);
  const supportError = useSelector((state) => state.support.error);
  const fundedDealsError = useSelector((state) => state.fundedDeals.error);

  useEffect(() => {
    if (adminError) {
      // @ts-ignore
      enqueueSnackbar(adminError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
    if (isoPortalError) {
      // @ts-ignore
      enqueueSnackbar(isoPortalError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
    if (applicationsError) {
      // @ts-ignore
      enqueueSnackbar(applicationsError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
    if (editApplicationError) {
      // @ts-ignore
      enqueueSnackbar(editApplicationError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
    if (supportError) {
      // @ts-ignore
      enqueueSnackbar(supportError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
    if (fundedDealsError) {
      // @ts-ignore
      enqueueSnackbar(fundedDealsError?.message ?? 'An error has occurred. Please try again.', {
        variant: 'error',
      });
    }
  }, [
    enqueueSnackbar,
    adminError,
    isoPortalError,
    applicationsError,
    editApplicationError,
    supportError,
    fundedDealsError,
  ]);

  return null;
};
