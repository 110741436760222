import { Stack, Typography, Skeleton } from '@mui/material';
import { useSelector } from 'src/redux/hooks';
import { getOfferPropertiesSelector } from 'src/redux/selectors/isoPortal';
import { fCurrencyShort } from 'src/utils/formatNumber';

export const MaximumApprovalWidget = () => {
  const offerProperties = useSelector(getOfferPropertiesSelector);

  return (
    <>
      <Typography variant="h6">Maximum Approval:</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Max Funding:</Typography>
        {offerProperties?.maximumFunding ? (
          <Typography variant="body1">
            {fCurrencyShort(offerProperties.maximumFunding || 0)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Max Estimated Term:</Typography>
        {offerProperties?.maximumTerm ? (
          <Typography variant="body1">{offerProperties.maximumTerm}</Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
      {offerProperties?.stackPosition && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">Stack Position:</Typography>
          <Typography variant="body1">{offerProperties.stackPosition}</Typography>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Credit Tier:</Typography>
        {offerProperties?.creditTier ? (
          <Typography variant="body1">{offerProperties.creditTier}</Typography>
        ) : (
          <Skeleton variant="rectangular" width={60} />
        )}
      </Stack>
    </>
  );
};
